const avatar = '/images/Avatar.svg';
const logo = '/images/logo.png';
const sun = '/images/sun.png';
const moon = '/images/moon.png';
const flagFr = '/images/flag-french.png';
const flagUk = '/images/flag-uk.png';
const homeSolid = '/images/home-solid.png';
const homeOutline = '/images/home-outline.png';
const profileSolid = '/images/profile.svg';
const profileOutline = '/images/profile.svg';
const loginLogo = '/images/logo.png';
const user = '/images/user.png';
const whiteLogo= '/images/white-logo.svg';
const dashboard = '/images/dashboard.svg';
const schoolSolid = '/images/school-solid.svg';
const schoolAdmin = '/images/school-admin.svg';
const sort = '/images/sort.svg';
const settings = "/images/settings.svg";
const settingsSolid = "/images/settings-solid.svg";
const circleInfo = "/images/infoCircle.svg"
const students=' images/people.svg';
const document ='images/document-text.svg'
const announce = 'images/announce.png';
const management = 'images/management.png';


export default {
  avatar,
  logo,
  sun,
  moon,
  flagFr,
  flagUk,
  homeSolid,
  homeOutline,
  profileSolid,
  profileOutline,
  loginLogo,
  user,
  whiteLogo,
  dashboard,
  schoolSolid,
  schoolAdmin,
  sort,
  settings,
  settingsSolid,
  circleInfo,
  students,
  document,
  announce,
  management,
};
