import * as Yup from 'yup';
import {NAME_REGEX, URL_REGEX} from '../core/validation';

export const addNewArticleFieldNames = {
  ARTICLE_TITLE: 'article_title',
  ARTICLE_LINK: 'article_link',
  MEDIA_ID: 'media_id',
};

export const addNewArticleFormInitialValues = {
  [addNewArticleFieldNames.ARTICLE_TITLE]: '',
  [addNewArticleFieldNames.ARTICLE_LINK]: '',
  [addNewArticleFieldNames.MEDIA_ID]: '',
};

export const validationSchema = intl => {
  return Yup.object().shape({
    [addNewArticleFieldNames.ARTICLE_TITLE]: Yup.string()
      .required(
        intl.formatMessage({id: 'addArticle.fieldError.title.required'}),
      )
      .min(2, intl.formatMessage({id: 'addArticle.fieldError.title.minLength'}))
      .max(
        80,
        intl.formatMessage({id: 'addArticle.fieldError.title.maxLength'}),
      )
      .matches(
        NAME_REGEX,
        intl.formatMessage({id: 'addArticle.fieldError.title.invalid'}),
      ),

    [addNewArticleFieldNames.ARTICLE_LINK]: Yup.string()
      .required(intl.formatMessage({id: 'addArticle.fieldError.link.required'}))
      .matches(
        URL_REGEX,
        intl.formatMessage({id: 'addArticle.fieldError.link.invalid'}),
      ),
    [addNewArticleFieldNames.MEDIA_ID]: Yup.string().required(
      intl.formatMessage({id: 'addArticle.fieldError.image.required'}),
    ),
  });
};
