import React from 'react';
import './IslandProgressBar.scss';

export default function IslandProgressBar({
  islandName,
  value,
  total,
  barColor,
  classNameWrapper,
}) {
  const progress = total > 0 ? Math.floor((value / total) * 100) : 0;

  return (
    <>
      <div className={`progressTab ${classNameWrapper}`}>
        {islandName && (
          <div className="progress-topSection">
            <div className="nameIsland">{islandName}</div>
            <div className="progressIsland">{progress}%</div>
          </div>
        )}

        <div className="position-relative token-progress-wrapper">
          <div className="dashboard-islandProgress-inner">
            <div
              className="dashboard-progress-bar"
              style={{width: `${progress}%`, backgroundColor: barColor}}>
              <div className="progress-bar" />
            </div>
          </div>
          {value > 0 && (
            <div
              className="dashboard-progress-bar-s"
              style={{left: `${progress}%`}}>
              <img src="/images/coin.svg" />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
