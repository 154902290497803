import React, {useState} from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import {styled} from '@mui/material/styles';
import {linearProgressClasses} from '@mui/material/LinearProgress';
import {Typography} from '@mui/material';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import './ProgressStatusView.scss';

const BorderLinearProgress = styled(LinearProgress)(({theme, barColor}) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: barColor,
  },
}));

const islandColors = {
  'All Islands': '#1F40E3',
  Tropical: '#02AE85',
  Urban: '#1CB0F6',
  Rural: '#FFAB0B',
  Fantasy: '#CE82FF',
};

export default function ProgressStatusView({studentData}) {
  const [selectedIsland, setSelectedIsland] = useState('All Islands');

  const getIslandOptionsList = studentData => {
    const defaultIsland = {
      id: 'All Islands',
      name: 'All Islands',
      completed_sections: studentData?.total_completed_sections,
      total_sections: studentData?.total_sections,
    };

    const islandOptions =
      studentData?.module_data?.map(island => ({
        id: island.module_name,
        name: island.module_name,
        completed_sections: island.completed_sections,
        total_sections: island.total_sections,
      })) || [];

    return [defaultIsland, ...islandOptions];
  };

  const onIslandSelect = e => {
    setSelectedIsland(e.target.value);
  };

  const islandProgress = studentData => {
    const island = getIslandOptionsList(studentData)?.find(
      option => option.name === selectedIsland,
    );
    if (island && island.total_sections > 0) {
      return (island.completed_sections / island.total_sections) * 100;
    } else {
      return 0;
    }
  };

  const barColor = () => {
    return islandColors[selectedIsland] || islandColors['All Islands'];
  };

  return (
    <div className="progress-statusView-wrapper">
      <div className="progressStatus-topSectionWrapper">
        <SelectDropdown
          options={getIslandOptionsList(studentData)}
          onChange={onIslandSelect}
        />

        <Typography
          variant="subtitle"
          style={{
            color: barColor(),
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '21px',
          }}>
          {`${Math.floor(islandProgress(studentData))}%`}
        </Typography>
      </div>

      <BorderLinearProgress
        variant="determinate"
        value={islandProgress(studentData)}
        barColor={barColor()}
      />
    </div>
  );
}
