import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {useNavigate} from '../../routes';
import {verifyEmail} from '../../store/actions/authActions';
import {LOGIN_PATH, RESET_PASSWORD_PATH} from '../../constants/routePaths';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';
import './VerifyEmail.scss';

export default function VerifyEmail() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {search} = useLocation();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const searchParams = new URLSearchParams(search);
  const id = searchParams.get('id');
  const hash = searchParams.get('hash');
  const [error, setError] = useState({isError: false, errorMessage: null});

  useEffect(() => {
    const executeVerifyEmail = async () => {
      if (id && hash) {
        const response = await dispatch(
          verifyEmail({id, hash, intl, setError, loaderDispatch}),
        );
        if (verifyEmail.fulfilled.match(response)) {
          navigate(RESET_PASSWORD_PATH);
        } else{
          navigate(LOGIN_PATH);
        }
      }
    };
    executeVerifyEmail();
  }, []);

  return (
    <>
      {error && (
        <div>
          <div className="forgot-password-wrapper login-wrapper verify-link">
            <></>
          </div>
        </div>
      )}
    </>
  );
}
