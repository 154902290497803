import React, {useEffect} from 'react';
import HelpCard from '../../components/HelpCard/HelpCard';
import {useDispatch, useSelector} from 'react-redux';
import {fetchBookmark} from '../../store/actions/bookmarkActions';
import {useNavigate} from 'react-router-dom';
import {MANAGE_STUDENT_LESSON__PATH} from '../../constants/routePaths';

function LessonsTab({is_content, closeModal}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bookmarkList = useSelector(state => state?.bookmark?.bookmarkList);

  useEffect(() => {
    fetchData();
  }, [is_content]);

  const fetchData = () => {
    dispatch(fetchBookmark({is_content: is_content}));
  };

  function redirectHandler(bookmarkItem) {
    return () => {
      
      const bookmarkLesson = {
        id: bookmarkItem?.lesson?.id,
        status: 'published',
      };
      closeModal();
      const cleanedSlug =
        bookmarkItem?.lesson?.section?.module_element?.slug.replace(/_/g, '');
      
      navigate(
        `${MANAGE_STUDENT_LESSON__PATH.replace(
          ':id',
          bookmarkItem?.lesson?.section?.id,
        ).replace(':section', cleanedSlug)}`,
        {
          state: {
            slug: bookmarkItem?.module_slug,
            isNotificationRedirect: true,
            notificationLesson: bookmarkLesson,
          },
        },
      );
    };
  }

  return (
    <div>
      <div className='help-modal-wrapper'>
        {bookmarkList?.map((bookmarkItem, index) => (
          <HelpCard
            key={index}
            subHeading={bookmarkItem?.content ? '' : bookmarkItem?.lesson?.section?.module}
            heading={
              bookmarkItem?.content
                ? bookmarkItem.content.article_title ||
                  bookmarkItem.content.media.name.replace(/^\d+_/, '')
                : bookmarkItem.lesson?.name
            }
            // description={
            //   bookmarkItem?.content
            //     ? bookmarkItem.content.description
            //     : bookmarkItem.lesson?.short_description
            // }
            timeTake={
              bookmarkItem?.content ? '' : bookmarkItem.lesson.minimum_read_time
            }
            imgPath="/images/back.svg"
            onClick={redirectHandler(bookmarkItem)}
          />
        ))}
      </div>
    </div>
  );
}

export default LessonsTab;
