import React, {useContext, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {useFormik} from 'formik';
import ThreeDButton from '../../../../components/ThreeDButton/ThreeDButton';
import ContentWrapper from '../../StudentViewLessonContent/ContentWrapper';
import {addQuestionInLesson} from '../../../../store/actions/questionAnswerActions';
import {
  addLessonQuesFormFieldNames,
  addLessonQuesFormInitialValues,
  validationSchema,
} from '../../../../validationSchema/addLessonQuesSchema';
import {GlobalLoaderContext} from '../../../../globalContext/globalLoader/globalLoaderProvider';
import './AddQuestionByStudentView.scss';

export default function AddQuestionByStudentView({
  open,
  setOpen,
  lessonId,
  setSearchTerm,
}) {
  const charLimit = 120;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const [remainingChars, setRemainingChars] = useState(charLimit);
  const [isTyping, setIsTyping] = useState(false);

  const {values, handleChange, errors, handleSubmit, handleBlur} = useFormik({
    initialValues: addLessonQuesFormInitialValues,
    onSubmit: async values => {
      const payload = {
        ...values,
        lesson_id: lessonId,
      };
      await dispatch(addQuestionInLesson({payload, intl, loaderDispatch})).then(
        () => {
          goBackHandler();
        },
      );
    },
    validationSchema: validationSchema(intl),
  });

  function goBackHandler() {
    setOpen(false);
    setSearchTerm('');
  }

  function handleCharCount(e) {
    handleChange(e);
    const chars = e.target.value.length;
    setRemainingChars(charLimit - chars);
    setIsTyping(e.target.value.length > 0);
  }

  const isSubmitDisabled =
    Object.keys(errors).length > 0 ||
    !values[addLessonQuesFormFieldNames.QUESTION];

  return (
    <>
      {open && (
        <div className="addQuesView-wrapper">
          <img
            src="/images/buttonLeftArrow.svg"
            onClick={goBackHandler}
            className="goBack-addQues"
          />

          <div className="addQuesAns-wrapper">
            <div className="quesTitle-wrapper">
              <div className="quesTitleField">
                {intl.formatMessage({id: 'label.writeQuesText'})}
              </div>

              <div className="quesWordLimit">
                {isTyping
                  ? intl.formatMessage(
                      {id: 'label.charactersLeft'},
                      {count: remainingChars},
                    )
                  : intl.formatMessage({id: 'label.wordsLimit'})}
              </div>
            </div>

            <ContentWrapper
              outerClassName="outer-addQues-container"
              innerClassName="inner-addQues-container">
              <div className="fullWidthQues">
                <input
                  className="addQues-input"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'addQuestion.field.placeholder',
                  })}
                  name={addLessonQuesFormFieldNames.QUESTION}
                  value={values[addLessonQuesFormFieldNames.QUESTION]}
                  onChange={handleCharCount}
                  onBlur={handleBlur}
                  maxLength={charLimit}
                />
              </div>
            </ContentWrapper>
          </div>

          <div className="submitQues-wrapper">
            <ThreeDButton
              className="border-0"
              buttonColorClass={isSubmitDisabled ? 'greyBtn' : 'blueBtn'}
              innerColorClass={isSubmitDisabled ? 'greyInner' : 'blueInner'}
              labelText={intl.formatMessage({id: 'label.submitQuestion'})}
              handleClick={handleSubmit}
              disabled={isSubmitDisabled}
            />
          </div>
        </div>
      )}
    </>
  );
}
