import {createContext, useReducer} from 'react';
import * as types from './types';

const initialState = {
  loading: false,
};

const globalLoaderReducer = (state, action) => {
  if (action.type === types.SET_LOADING) {
    return {
      ...state,
      loading: action.payload,
    };
  }

  return state;
};

export const GlobalLoaderContext = createContext(initialState);

const GlobalLoaderProvider = ({children}) => {
  const [globalLoaderState, globalLoaderDispatch] = useReducer(
    globalLoaderReducer,
    initialState,
  );

  return (
    <GlobalLoaderContext.Provider
      value={[globalLoaderState, globalLoaderDispatch]}>
      {children}
    </GlobalLoaderContext.Provider>
  );
};

export default GlobalLoaderProvider;
