import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useIntl} from 'react-intl';
import NotificationsRightColLayout from '../../../components/NotificationsRightColLayout/NotificationsRightColLayout';
import Notification from '../../../components/Notification/Notification';
import {MANAGE_STUDENT_LESSON__PATH} from '../../../constants/routePaths';
import './StudentNotification.scss';

function StudentNotification({setShowDashboard, isPagination}) {
  const intl = useIntl();
  const navigate = useNavigate();
  const [openNotificationPopover, setOpenNotificationPopover] = useState(false);

  function redirectHandler(notification) {
    const notificationLesson = {
      id: notification?.data?.lesson_id,
      status: 'published',
    };
    if (!isPagination) setShowDashboard(false);
    const cleanedSlug = notification?.data?.module_element_slug.replace(
      /_/g,
      '',
    );
    navigate(
      `${MANAGE_STUDENT_LESSON__PATH.replace(
        ':id',
        notification?.data?.section_id,
      ).replace(':section', cleanedSlug)}`,
      {
        state: {
          slug: notification?.data?.module_slug,
          isNotificationRedirect: true,
          notificationLesson: notificationLesson,
        },
      },
    );
  }

  function onViewAllNotificationsAndAnnoumcementsHandler() {
    if (!isPagination) setOpenNotificationPopover(true);
  }

  return (
    <div className="student-notification-wrapper">
      <NotificationsRightColLayout
        NotifyClassName="student-notification"
        desc={false}
        btnText={intl.formatMessage({id: 'label.go_to_lesson'})}
        notifyIcon={'/images/school.svg'}
        redirectHandler={redirectHandler}
        viewAll={onViewAllNotificationsAndAnnoumcementsHandler}
        isPagination={isPagination}
        tabsClassname="student-tabs"
        annoucementIcon={'/images/school.svg'}
      />
      {openNotificationPopover && (
        <Notification
          notificationOpen={openNotificationPopover}
          setNotificationOpen={setOpenNotificationPopover}
          isViewAllCase={true}
          setShowDashboard={setShowDashboard}
        />
      )}
    </div>
  );
}

export default StudentNotification;
