import React from 'react';
import {View} from '@unthinkable/react-core-components';
import './ThreeDButton.scss';

const ThreeDButton = ({
  labelText,
  disabled,
  handleClick,
  align,
  className,
  labelClassName,
  buttonColorClass = 'yellowBtn',
  innerColorClass = 'yellowInner',
}) => {
  return (
    <View>
      <button
        type="button"
        className={`three-d-button ${className} ${buttonColorClass}`}
        onClick={handleClick}
        disabled={disabled}>
        <span className={`btn-inner ${innerColorClass}`}>
          <span className={`btn-text ${labelClassName}`}>{labelText}</span>
        </span>
      </button>
    </View>
  );
};

export default ThreeDButton;
