import React from 'react';
import {useTheme} from '@unthinkable/react-theme';
import {useIntl} from 'react-intl';
import CustomDialogue from '../../components/CustomDialogue/CustomDialogue';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {commonStyle} from '../../Style/commonStyle';

export default function DeactivateModal({
  open,
  setOpen,
  handleDeactivate,
  modalContentText,
  dialogueTitle,
  confirmText,
  hideActionsButtons
}) {
  const intl = useIntl();
  const icons = useTheme('icons');
  const {circleInfo} = icons;

  const closePopupHandler = () => {
    setOpen(false);
  };

  return (
    <CustomDialogue
      open={open}
      onCancel={closePopupHandler}
      cancelText={getLocalizedMessage(intl, 'label.cancel')}
      confirmText={confirmText}
      dialogueTitle={dialogueTitle}
      className={'center-btn'}
      isDisableModal
      hideActionsButtons={hideActionsButtons}
      onSubmit={handleDeactivate}>
      <div style={commonStyle.textAlignCenter}>
        <img src={circleInfo} alt="warning-icon" />
        <div style={commonStyle.modalContentTextStyle}>{modalContentText}</div>
      </div>
    </CustomDialogue>
  );
}
