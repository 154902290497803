import * as Yup from 'yup';
import {LESSON_NAME_REGEX} from '../core/validation';
export const addHelpFieldName = {
  TITLE: 'title',
  MEDIA: 'media_id',
};
export const addHelpInitialValues = {
  [addHelpFieldName.TITLE]: '',
  [addHelpFieldName.MEDIA]: '',
};
export const validationSchema = intl => {
  return Yup.object().shape({
    [addHelpFieldName.MEDIA]: Yup.number().required(
      intl.formatMessage({id: 'label.pdf_required'}),
    ),
    [addHelpFieldName.TITLE]: Yup.string()
      .required(intl.formatMessage({id: 'label.title_required'}))
      .matches(
        LESSON_NAME_REGEX,
        intl.formatMessage({id: 'label.fieldError.invalid.title'}),
      ),
  });
};
