import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {useNavigate} from '../../routes';
import {useTheme} from '@unthinkable/react-theme';
import {Button} from '@unthinkable/react-button';
import {View} from '@unthinkable/react-core-components';
import {studentPasswordReset} from '../../store/actions/studentActions';
import {LOGIN_PATH, STUDENT_WELCOME_PATH} from '../../constants/routePaths';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import styles from './TemporaryLogin.style';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from '../../services/local-storage';

export default function TemporaryLogin() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const icons = useTheme('icons');
  const {search} = useLocation();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('token');
  const email = searchParams.get('email');
  const {logo} = icons;
  const [error, setError] = useState({isError: false, errorMessage: null});
  const userRoleData = getLocalStorageItem('userRole');
  const accessTokenData = getLocalStorageItem('accessToken');

  useEffect(() => {
    const executeResetPassword = async () => {
      if (token && email) {
        const payload = {
          email: email,
          token: token,
        };
        if (accessTokenData && userRoleData) {
          removeLocalStorageItem('userRole');
          removeLocalStorageItem('accessToken');
        }
        const response = await dispatch(
          studentPasswordReset({payload, intl, setError, loaderDispatch}),
        );
        if (studentPasswordReset.fulfilled.match(response)) {
          navigate(STUDENT_WELCOME_PATH);
        }
      }
    };
    executeResetPassword();
  }, [token, email]);

  const loginHandler = () => {
    navigate(LOGIN_PATH);
  };

  return (
    <>
      {error?.isError && (
        <View className="forgot-password-wrapper login-wrapper">
          <View className="forgot-password-container login-container">
            <img style={styles.img} src={logo} alt="Gradrock Logo"/>
            <p className="subtitle">
              {getLocalizedMessage(intl, 'link.expired')}
            </p>

            <Button
              text={intl.formatMessage({id: 'label.back-to-login'})}
              onPress={loginHandler}
              disabledContainerStyle={{opacity: 0.5}}
              containerStyle={styles.button}
              className="button-text"
            />
          </View>
        </View>
      )}
    </>
  );
}
