import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { View } from '@unthinkable/react-core-components';
import style from './DropdownMenu.style.scss';

const DropdownMenu = ({ children, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <View>
      <div className={`cus-dropdown ${className}`} onClick={handleToggle}>
        {React.Children.map(children, child => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              className: `dropdown-item ${child.props.className || ''}`,
            });
          }
          return child;
        })}
      </div>
    </View>
  );
};

DropdownMenu.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default DropdownMenu;
