import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import GlobalProvider from './globalContext';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux';
import store, {persistor} from './store'; 
import { PersistGate } from 'redux-persist/integration/react';
import {persistStore} from "redux-persist";
const rootElement = document.getElementById('root');
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


// let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalProvider>
        <PersistGate persistor={persistor}>
        <App />
        </PersistGate>
      </GlobalProvider>
    </Provider>
  </React.StrictMode>,
  rootElement,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
