import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {
  chunkItems,
  getBorderColorByBgColor,
  getLocalizedMessage,
} from '../../utilityFunction/helper';
import Popper from '@mui/material/Popper';
import {COLOR_ITEMS} from '../../utilityFunction/constants';
import {commonStyle} from '../../Style/commonStyle';
import './ColorPickerMenu.scss';

export default function ColorPickerMenu({selectedBgColor, handleColorSelect}) {
  const intl = useIntl();
  const rows = chunkItems(COLOR_ITEMS, 4);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <div className="colorPicker-container">
      <div className="text-backgroundColor">
        {getLocalizedMessage(intl, 'label.background')}
      </div>

      <div className="dropdown-container">
        <div className="text-hex">
          {getLocalizedMessage(intl, 'label.hex')}
          <span>{selectedBgColor}</span>
          <span>
            <div
              style={{
                borderStyle: 'solid',
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                backgroundColor: selectedBgColor,
                borderColor: getBorderColorByBgColor(
                  COLOR_ITEMS,
                  selectedBgColor,
                ),
                borderWidth: '1px',
              }}></div>
          </span>
          <span>
            <img
              className='color-picker-icon'
              src="images/arrow-down.svg"
              alt="Toggle Dropdown"
              onClick={handleClick}
            />
          </span>
        </div>

        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          style={commonStyle.colorPickerMenu}>
          <div>
            {rows.map((row, index) => (
              <div key={index} className="grid-colorContainer">
                {row.map((item, idx) => (
                  <div
                    key={idx}
                    className="grid-colorItem"
                    onClick={() => {
                      handleColorSelect(item?.bgColor);
                      handleClick();
                    }}>
                    <div
                      style={{
                        backgroundColor: item.bgColor,
                        borderColor: item.borderColor,
                        borderWidth: item.borderWidth,
                        borderStyle: 'solid',
                        width: '24px',
                        height: '24px',
                        borderRadius: '50%',
                      }}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </Popper>
      </div>
    </div>
  );
}
