import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AvatarCardFrame from '../../../components/AvatarCardFrame/AvatarCardFrame';
import {
  fetchAvatars,
  AddStudentAvatars,
} from '../../../store/actions/studentModuleActions';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import ThreeDButton from '../../../components/ThreeDButton/ThreeDButton';
import './AvatarList.scss';

function AvatarList({className,onAvatarSelect,buttonText}) {
  const intl = useIntl();
  const navigate = useNavigate();
  const avatarList = useSelector(state => state?.studentModule?.avatarList);
  const dispatch = useDispatch();
  const [selectedAvatarId, setSelectedAvatarId] = useState(null);

  useEffect(() => {
    fetchAvatarsList();
  }, []);

  const fetchAvatarsList = async () => {
    await dispatch(fetchAvatars());
  };

  const handleAvatarClick = (media_id, avatar_name) => {
    setSelectedAvatarId(media_id);
  };

  const handleContinueClick = () => {
    if (selectedAvatarId) {
      dispatch(AddStudentAvatars({ media_id: selectedAvatarId, intl, navigate }));
      // setSelectedAvatarId(null);
      onAvatarSelect();
    } else {
      console.error('No avatar is selected!');
    }
  };

  return (
    <div className={`student-wrapper text-center d-flex student-avatar-wrapper ${className}`}>
      <div className="m-auto">
        <p className="montserrat-bold welcome-text">
          {intl.formatMessage({ id: 'label.choose_avatar' })}
        </p>
        <AvatarCardFrame
          avatarOptions={avatarList}
          onAvatarClick={(media_id, avatar_name) => handleAvatarClick(media_id, avatar_name)}
          selectedAvatarId={selectedAvatarId}
        />
        <ThreeDButton
          labelClassName="montserrat-bold"
          className="mt-3 text-uppercase"
          handleClick={handleContinueClick}
          labelText={buttonText || intl.formatMessage({ id: 'label.continue' })} // Use prop if available, else use default
        />
      </div>
    </div>
  );
}

export default AvatarList;
