import React from 'react';
import './HelpCard.scss';

const HelpCard = ({subHeading, heading, description, timeTake, imgPath, onClick}) => {
  return (
    <div className="help-card-wrapper col-md-4">
      <div className="help-card-inner" onClick={onClick}>
        {subHeading && <h3 className="sub-heading">{subHeading}</h3>}
        <div className="heading">{heading}</div>
        {description && <p className="description">{description}</p>}
        {timeTake && <div className="time-taken"> {timeTake} Minutes</div>}
        <img src={imgPath} alt="icon"/>
      </div>
    </div>
  );
};

export default HelpCard;
