import React from 'react';
import {
  View,
  Image,
  Text,
  TouchableOpacity,
} from '@unthinkable/react-core-components';

const Button = props => {
  const {
    gap,
    onPress,
    text,
    icon,
    children,
    containerStyle,
    iconStyle,
    textStyle,
    disabledContainerStyle,
    disabledTextStyle,
    disabled,
  } = props;

  const iconComponent = icon ? (
    <Image source={icon} style={iconStyle} />
  ) : (
    void 0
  );
  const textComponent = text ? (
    <Text style={[textStyle, disabled ? disabledTextStyle : void 0]}>
      {text}
    </Text>
  ) : (
    void 0
  );
  let renderComponent = children;
  if (!renderComponent) {
    if (icon && text) {
      renderComponent = (
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          {iconComponent}
          <View style={{width: gap}} />
          {textComponent}
        </View>
      );
    } else if (icon) {
      renderComponent = iconComponent;
    } else if (text) {
      renderComponent = textComponent;
    }
  }

  return (
    <TouchableOpacity
      disabled={disabled}
      style={[containerStyle, disabled ? disabledContainerStyle : void 0]}
      onPress={onPress}>
      {renderComponent}
    </TouchableOpacity>
  );
};

export default Button;
