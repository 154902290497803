import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {setLoading} from '../../globalContext/globalLoader/globalLoaderAction';
import {showToast} from '../../components/Toast/Toast';
import {getLocalizedMessage} from '../../utilityFunction/helper';

export const getUserAnnouncements = createAsyncThunk(
  'user/announcement',
  async ({loaderDispatch, currentPage, isPaginated = false}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const params = {
        ...(isPaginated && {per_page: 10, page: currentPage}),
      };
      const response = await axiosPrivateInstance.get('/announcements',{params});
      loaderDispatch(setLoading(false));
      return response?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      throw error.response.data.message;
    }
  },
);

export const addUserAnnouncements = createAsyncThunk(
  'user/announcements',
  async ({payload, intl}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.post(
        '/announcements',
        payload,
      );
      showToast(getLocalizedMessage(intl, 'label.success_announcement'), 'success');

      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        showToast(error.response.data.message);
        throw error;
      }
    }
  },
);
