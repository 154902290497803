import React, {useContext, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import InputLabel from '../../../components/InputLabel/InputLabel';
import IslandProgressBar from '../../../components/IslandProgressBar/IslandProgressBar';
import {GlobalLoaderContext} from '../../../globalContext/globalLoader/globalLoaderProvider';
import {getStudentDashboardDetails} from '../../../store/actions/dashboardActions';
import {getLocalizedMessage} from '../../../utilityFunction/helper';
import './StudentDetailsDashboardView.scss';

const ISLAND_COLORS = {
  Tropical: '#02AE85',
  Urban: '#1CB0F6',
  Rural: '#FFAB0B',
  Fantasy: '#CE82FF',
};

export default function StudentDetailsDashboardView() {
  const intl = useIntl();
  const {id} = useParams();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const {studentDashboardAllDetails} = useSelector(state => state?.dashboard);

  useEffect(() => {
    if (id) fetchStudentDashboardDetails();
  }, [id]);

  function fetchStudentDashboardDetails() {
    dispatch(getStudentDashboardDetails({loaderDispatch, studentId: id}));
  }

  const getIslandsCompletedCount = moduleData => {
    return moduleData?.reduce((completedCount, module) => {
      return module.completed_sections === module.total_sections
        ? completedCount + 1
        : completedCount;
    }, 0);
  };

  return (
    <div className="studentDashboard-container">
      <div className="sectionTitle">
        {intl.formatMessage({id: 'label.section.detailsStudent'})}
      </div>
      <ProfileDetails studentDetails={studentDashboardAllDetails} />
      <div className="basicDetailsContainer">
        <div className="row">
          <div className="mb-3 col-md-6">
            <InputLabel
              label={getLocalizedMessage(intl, 'label.email_address')}
              value={studentDashboardAllDetails?.email}
              readOnly
            />
          </div>
          <div className="mb-3 col-md-6">
            <InputLabel
              readOnly
              label={getLocalizedMessage(intl, 'label.assigned_counsellor')}
              value={`${studentDashboardAllDetails?.counsellor?.first_name} ${studentDashboardAllDetails?.counsellor?.last_name}`}
            />
          </div>
          <div className="mb-3 col-md-6">
            <InputLabel
              readOnly
              label={getLocalizedMessage(intl, 'label.assigned_teacher')}
              value={`${studentDashboardAllDetails?.teacher?.first_name} ${studentDashboardAllDetails?.teacher?.last_name}`}
            />
          </div>
        </div>
      </div>
      <div className="sectionTitle">
        {intl.formatMessage({id: 'heading.studentDashboard'})}
      </div>
      <DashboardDetails
        studentDetails={studentDashboardAllDetails}
        getIslandsCompletedCount={getIslandsCompletedCount}
        intl={intl}
      />
    </div>
  );
}

const ProfileDetails = ({studentDetails}) => {
  return (
    <div className="profileDetailsContainer">
      <div className="studentAvatarNameContainer">
        <img
          src={studentDetails?.media?.path || '/images/studentPlaceholder.png'}
          alt="Student avatar"
          className="studentAvatar"
        />
        <NameAndUsername studentDetails={studentDetails} />
      </div>
    </div>
  );
};

const NameAndUsername = ({studentDetails}) => {
  return (
    <div className="nameUsernameWrapper">
      <div className="nameContainer">{`${studentDetails?.first_name} ${studentDetails?.last_name}`}</div>
      <div className="usernameContainer">{studentDetails?.email}</div>
    </div>
  );
};

const DashboardDetails = ({
  studentDetails,
  getIslandProgress,
  getIslandsCompletedCount,
  intl,
}) => {
  return (
    <div className="bottomSectionContainer">
      <IslandProgressList
        studentDetails={studentDetails}
        getIslandProgress={getIslandProgress}
      />
      <StudentDashboardStats
        studentDetails={studentDetails}
        getIslandsCompletedCount={getIslandsCompletedCount}
        intl={intl}
      />
    </div>
  );
};

const IslandProgressList = ({studentDetails}) => {
  return (
    <div className="bottomleftColumn">
      {studentDetails?.module_data?.map((island, index) => {
        return (
          <IslandProgressBar
            islandName={island?.module_name}
            value={island?.completed_sections}
            total={island?.total_sections}
            barColor={ISLAND_COLORS[island?.module_name]}
          />
        );
      })}
    </div>
  );
};

const StudentDashboardStats = ({
  studentDetails,
  getIslandsCompletedCount,
  intl,
}) => {
  return (
    <div className="bottomRightColumn">
      <div className="statsRow">
        <DashboardCard
          icon="/images/earnedTokens.svg"
          title={intl.formatMessage({id: 'label.tokensEarned'})}
          value={studentDetails?.earned_tokens}
        />
        <DashboardCard
          icon="/images/modulesCompleted.svg"
          title={intl.formatMessage({id: 'label.completedModules'})}
          value={`${studentDetails?.total_completed_lessons_count ?? 0}/${
            studentDetails?.total_lesson_count ?? 0
          }`}
        />
      </div>
      <div className="statsRow">
        <DashboardCard
          icon="/images/sectionsCompleted.svg"
          title={intl.formatMessage({id: 'label.completedSections'})}
          value={`${studentDetails?.total_completed_sections ?? 0}/${
            studentDetails?.total_sections ?? 0
          }`}
        />
        <DashboardCard
          icon="/images/islandsCompleted.svg"
          title={intl.formatMessage({id: 'label.islandsCompleted'})}
          value={`${
            getIslandsCompletedCount(studentDetails?.module_data) ?? 0
          }/${studentDetails?.module_data?.length ?? 0}`}
        />
      </div>
    </div>
  );
};

const DashboardCard = ({icon, title, value}) => {
  return (
    <div className="studentDashboardCardView">
      <img className="iconWrapper" src={icon} />
      <div className="studentDashboardCardView-detailsWrapper">
        <div className="headingTitleWrapper">{title}</div>
        <div className="valueWrapper">{value}</div>
      </div>
    </div>
  );
};
