import React, {useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import {useFormik} from 'formik';
import InputLabel from '../../components/InputLabel/InputLabel';
import SelectDropdown from '../../components/SelectDropdown/SelectDropdown';
import CustomDialogue from '../../components/CustomDialogue/CustomDialogue';
import {
  addDistrictFormFieldNames,
  addDistrictFormInitialValues,
  validationSchema,
} from '../../validationSchema/addDistrictFormSchema';
import {fetchCountriesData, fetchStatesData} from '../../services/auth-service';
import {
  addDistrictData,
  editDistrictData,
} from '../../store/actions/districtActions';
import {fetchDistricts} from '../../store/actions/schoolActions';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';

export default function AddDistrictModal({
  open,
  setOpen,
  editRowData,
  setEditRowData,
  currentPage,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [countryId, setCountryId] = useState(null);
  const [stateId, setStateId] = useState(null);

  const {
    values,
    handleChange,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues: addDistrictFormInitialValues,
    onSubmit: async values => {
      const districtData = {
        name: values?.name,
        state_id: values?.state?.toString(),
      };
      if (editRowData) {
        dispatch(
          editDistrictData({
            districtData,
            districtId: editRowData?.district_id,
            intl,
          }),
        ).then(() => {
          dispatch(
            fetchDistricts({
              isPaginate: true,
              currentPage: currentPage,
              loaderDispatch,
            }),
          );
        });
      } else {
        dispatch(addDistrictData({districtData, intl})).then(() => {
          dispatch(
            fetchDistricts({
              isPaginate: true,
              currentPage: currentPage,
              loaderDispatch,
            }),
          );
        });
      }
      setEditRowData(null);
      setOpen(false);
    },
    validationSchema: validationSchema(intl),
  });

  useEffect(() => {
    if (editRowData) {
      setCountryId(editRowData?.country?.id);
      setStateId(editRowData.state?.id);
      setValues(prev => ({
        ...prev,
        [addDistrictFormFieldNames.DISTRICT_NAME]: editRowData?.district_name,
        [addDistrictFormFieldNames.COUNTRY_ID]: editRowData?.country?.id,
        [addDistrictFormFieldNames.STATE_ID]: editRowData.state?.id,
      }));
    }
  }, [editRowData]);

  useEffect(() => {
    fetchCountriesData(dispatch, setCountries);
  }, []);

  useEffect(() => {
    const getStatesList = async countryId => {
      await fetchStatesData(dispatch, countryId, setStates);
    };
    if (countryId) getStatesList(countryId);
  }, [countryId]);

  const countryChangeHandler = e => {
    setFieldValue([addDistrictFormFieldNames.COUNTRY_ID], e.target.value, {
      shouldValidate: true,
    });
    setCountryId(e.target.value);
    setFieldValue(addDistrictFormFieldNames.STATE_ID, '', false);
    setStateId(null);
  };

  const stateChangeHandler = e => {
    setFieldValue([addDistrictFormFieldNames.STATE_ID], e.target.value, {
      shouldValidate: true,
    });
    setStateId(e.target.value);
  };

  const closeDialogue = () => {
    setOpen(false);
    setEditRowData(null);
  };

  return (
    <CustomDialogue
      open={open}
      onCancel={closeDialogue}
      cancelText={getLocalizedMessage(intl, 'label.cancel')}
      confirmText={
        editRowData ? 'Save' : getLocalizedMessage(intl, 'label.addDistrict')
      }
      dialogueTitle={
        editRowData
          ? getLocalizedMessage(intl, 'label.updateDistrict')
          : getLocalizedMessage(intl, 'label.title.createDistrict')
      }
      onSubmit={handleSubmit}
      className={'center-btn'}>
      <div className="container">
        <div className="row mt-4">
          <div className="mb-3 col-md-6">
            <SelectDropdown
              options={countries}
              label={getLocalizedMessage(intl, 'addDistrict.addCountryName')}
              placeholder={getLocalizedMessage(
                intl,
                'addDistrict.addCountryName.placeholder',
              )}
              required
              name={addDistrictFormFieldNames.COUNTRY_ID}
              value={values[addDistrictFormFieldNames.COUNTRY_ID]}
              onChange={e => countryChangeHandler(e)}
              error={
                touched[addDistrictFormFieldNames.COUNTRY_ID] &&
                errors[addDistrictFormFieldNames.COUNTRY_ID]
              }
            />
          </div>

          <div className="mb-3 col-md-6">
            <SelectDropdown
              options={states}
              label={getLocalizedMessage(intl, 'addDistrict.addStateName')}
              placeholder={getLocalizedMessage(
                intl,
                'addDistrict.addStateName.placeholder',
              )}
              required
              name={addDistrictFormFieldNames.STATE_ID}
              value={values[addDistrictFormFieldNames.STATE_ID]}
              onChange={e => stateChangeHandler(e)}
              error={
                touched[addDistrictFormFieldNames.STATE_ID] &&
                errors[addDistrictFormFieldNames.STATE_ID]
              }
              disabled={!countryId}
            />
          </div>

          <div className="mb-3 col-md-12">
            <InputLabel
              name={addDistrictFormFieldNames.DISTRICT_NAME}
              label={getLocalizedMessage(intl, 'addDistrict.addDistrictName')}
              placeholder={getLocalizedMessage(
                intl,
                'addDistrict.addDistrictName.placeholder',
              )}
              required
              value={values[addDistrictFormFieldNames.DISTRICT_NAME]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[addDistrictFormFieldNames.DISTRICT_NAME] &&
                errors[addDistrictFormFieldNames.DISTRICT_NAME]
              }
            />
          </div>
        </div>
      </div>
    </CustomDialogue>
  );
}
