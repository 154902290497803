import React from 'react';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import SearchBar from '../SearchBar/SearchBar';
import style from './SearchFilterSection.scss';
import {CgSortAz} from 'react-icons/cg';
import {CgSortZa} from 'react-icons/cg';

function SearchFilterSection({
  optionsOne,
  valueOne,
  handleOptionOneChange,
  optionsTwo,
  valueTwo,
  handleOptionTwoChange,
  setSearchTerm,
  handleSort,
  sortOrder,
  handleViewAll,
  onSearchChange,
  filterOnePlaceholder,
  filterTwoPlaceholder,
  searchPlaceholder,
  showSelect,
  dropdownOptions,
  dropdownOptionsTwo,
  dropdownPlaceholder,
  dropdownPlaceholderTwo,
  handleSelectChange,
  handleSelectChangeTwo,
}) {
  return (
    <div className="d-flex search-filter-wrapper">
      <div className="d-flex filter-wrapper">
        <div
          className={`view-all cursor ${
            valueOne === '' && valueTwo === ''
              ? 'selected'
              : ''
          }`}
          onClick={handleViewAll}>
          View all
        </div>
          <SelectDropdown
            options={optionsOne || []}
            value={valueOne}
            onChange={handleOptionOneChange}
            placeholder={filterOnePlaceholder}
            disabled={optionsOne?.length === 0}
            selectClassName="c-filter"
            className="w-90"
          />
          <SelectDropdown
            options={optionsTwo || []}
            value={valueTwo}
            onChange={handleOptionTwoChange}
            placeholder={filterTwoPlaceholder}
            disabled={optionsTwo?.length === 0}
            selectClassName="c-filter"
            className="w-90"
          />
      </div>
      <div className="searchbar-inner">
        {showSelect && (
          <div className='searchbar-inner-wrapper'>
            <SelectDropdown
              options={dropdownOptions}
              placeholder={dropdownPlaceholder}
              onChange={e => handleSelectChange(e.target.value)}
              selectClassName={'form-control cus-select tab-select-bar'}
            />
            <SelectDropdown
              options={dropdownOptionsTwo}
              placeholder={dropdownPlaceholderTwo}
              onChange={e => handleSelectChangeTwo(e.target.value)}
              selectClassName={'form-control cus-select tab-select-bar'}
            />
          </div>
        )}
        <SearchBar
          placeholder={searchPlaceholder}
          // onChange={setSearchTerm}
          onChange={onSearchChange}
        />
        <div className="sorting">
          <button onClick={handleSort} className="">
            {sortOrder === 'asc' ? <CgSortAz /> : <CgSortZa />} Sort
          </button>
        </div>
      </div>
    </div>
  );
}

export default SearchFilterSection;
