import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import Notification from '../../../components/Notification/Notification';
import TokenProgressBar from '../../../components/TokenProgressBar/TokenProgressBar';
import './StudentHeader.scss';
import StudentMenu from '../StudentMenu/StudentMenu';
import CustomModalStudent from '../../../components/CustomModalStudent/CustomModalStudent.js';
import StudentDocViewer from '../../../components/StudentDocViewer/StudentDocViewer.js';
import HelpCard from '../../../components/HelpCard/HelpCard.js';
import {getLocalStorageItem} from '../../../services/local-storage';
import {useNavigate, useLocation} from 'react-router-dom';

function StudentHeader() {
  const earnedTokens = getLocalStorageItem('earned_tokens');
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();
  const helpList = useSelector(state => state?.help?.StaticContentList?.data);

  const handleNotificationIconClick = () => {
    setNotificationOpen(true);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const dialogHeaderContent = (
    <div className="help-header-wrapper">
      <div onClick={closeModal} className="back-icon">
        <img src="/images/back.svg" alt="back" />
      </div>
      <h2 className="help-header text-shadow">
        {intl.formatMessage({id: 'label.help'})}
      </h2>
    </div>
  );

  return (
    <div className="student-header-wrapper">
      <div className="student-left-header">
        <div>
          <StudentMenu />
        </div>

        {helpList ? (
          <div onClick={openModal} className="cursor">
            <img
              src="/images/helpIcon.svg"
              style={{width: '48px', height: '48px'}}
            />
          </div>
        ) : null}

        <div onClick={handleNotificationIconClick}>
          <img
            src="/images/s-notification.svg"
            className="cursor"
            alt="notification"
          />
        </div>
        {location.state ? (
          <div onClick={() => navigate(-1)}>
            <button class="close-button">
              <div>
                <span>&times;</span>
              </div>
            </button>
          </div>
        ) : null}
        {notificationOpen && (
          <Notification
            setNotificationOpen={setNotificationOpen}
            notificationOpen={notificationOpen}
          />
        )}
      </div>
      <TokenProgressBar value={earnedTokens} total={100} />

      {isModalOpen && (
        <CustomModalStudent
          open={isModalOpen || isPdfModalOpen}
          handleClose={closeModal}
          backgroundBlue={selectedMedia?.media ? false : true}
          modalWidth={'60%'}
          showHeader
          dialogHeader={
            selectedMedia?.media ? (
              <HelpDocViewerHeader
                onBackDocs={closeDocViewerModal}
                media={selectedMedia}
                onAttachmentsDownload={onAttachmentsDownload}
                title={selectedMedia.title}
              />
            ) : (
              dialogHeaderContent
            )
          }>
          {selectedMedia?.media ? (
            <StudentDocViewer
              docViewerClasssName="section-help-pdf"
              mediaPath={selectedMedia?.media?.path}
            />
          ) : (
            <div className="help-modal-wrapper">
              {helpList?.map((helpItem, index) => (
                <HelpCard
                  key={index}
                  heading={helpItem?.title}
                  imgPath="/images/back.svg"
                  onClick={() => handleHelpCardClick(helpItem)}
                />
              ))}
            </div>
          )}
        </CustomModalStudent>
      )}
    </div>
  );
}

export default StudentHeader;
