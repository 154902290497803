import {combineReducers} from '@reduxjs/toolkit';
import authReducer from '../store/reducers/authReducers';
import schoolReducer from '../store/reducers/schoolReducers';
import studentReducer from '../store/reducers/studentReducers';
import staffReducer from './reducers/staffReducers';
import islandReducer from './reducers/islandReducers';
import sectionReducer from './reducers/sectionReducers';
import studentModuleReducer from './reducers/studentModuleReducers';
import lessonReducer from './reducers/lessonReducers';
import helpReducer from './reducers/helpReducers';
import questionAnswerReducer from './reducers/questionAnswerReducer';
import dashboardReducer from './reducers/dashboardReducers';
import notificationReducer from './reducers/notificationReducers';
import studentDashboardReducers from './reducers/studentDashboardReducers';
import announcementReducer from './reducers/announcementReducers';
import superAdminReducers from './reducers/superAdminReducers';
import bookmarkReducers from './reducers/bookmarkReducers';
import progressReportReducer from './reducers/progressReportReducers';
import notesReducers from './reducers/notesReducers';

const initialState = {};

const appReducer = combineReducers({
  auth: authReducer,
  school: schoolReducer,
  student: studentReducer,
  staff: staffReducer,
  island: islandReducer,
  section: sectionReducer,
  studentModule: studentModuleReducer,
  lesson: lessonReducer,
  help: helpReducer,
  questionAnswer: questionAnswerReducer,
  dashboard: dashboardReducer,
  notifications: notificationReducer,
  studentDashboard: studentDashboardReducers,
  announcements: announcementReducer,
  superAdmin: superAdminReducers,
  bookmark: bookmarkReducers,
  progressReport: progressReportReducer,
  notes: notesReducers,
});

export default function rootReducer(state, action) {
  let appState = state;
  if (action.type === 'logout') {
    appState = initialState;
  }
  return appReducer(appState, action);
}
