import React, {useRef, useState, useContext} from 'react';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {handleImageUpload} from '../../services/auth-service';
import {MAX_FILE_SIZE} from '../../utilityFunction/constants';
import DeactivateModal from '../DeactivateModal/DeactivateModal';
import './UploadFileImage.scss';

export default function UploadFileImage({
  setFileId,
  previewUrl,
  setPreviewUrl,
  isDisabled,
  hideImageTag = false,
  imageName,
  setImageName,
  error,
  userProfile = false,
  userProfileClass,
  profileTitle,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFileSizeModalVisible, setIsFileSizeModalVisible] = useState(false);

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (file?.size > MAX_FILE_SIZE) {
      setIsFileSizeModalVisible(true);
      event.target.value = '';
      return;
    }
    if (file && file.type.substr(0, 5) === 'image') {
      setSelectedFile(file);
      if (file) {
        onFileUpload(file);
      }
    } else {
      setSelectedFile(null);
      setPreviewUrl(null);
      setImageName('');
    }
  };

  const onFileUpload = async file => {
    await dispatch(
      handleImageUpload({file, setFileId, setPreviewUrl, setImageName, intl}),
    );
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const closeFileSizeModalHandler = () => {
    setIsFileSizeModalVisible(false);
  };

  return (
    <>
      {userProfile && previewUrl && (
        <img src={previewUrl} alt="Preview" className="preview-image" />
      )}
      <div className={userProfileClass}>
        {userProfile && <div className="pb-2">{profileTitle}</div>}
        <div className="upload-file-wrapper">
          <div className="img-actions-wrapper">
            {!hideImageTag && (
              <div className="image-text">
                {getLocalizedMessage(intl, 'label.uploadImage')}
              </div>
            )}

            <div style={{display: 'flex', alignItems: 'center'}}>
              <button
                className="upload-file-btn"
                onClick={handleButtonClick}
                disabled={isDisabled}>
                {getLocalizedMessage(intl, 'label.uploadFile')}
              </button>
              <input
                ref={fileInputRef}
                id="upload-button"
                type="file"
                accept=".jpg,.jpeg,.png"
                onChange={handleFileChange}
                style={{display: 'none'}}
              />
            </div>
          </div>

          {previewUrl && (
            <div className="preview-container">
              {!userProfile && (
                <img src={previewUrl} alt="Preview" className="preview-image" />
              )}

              <div className="image-name">
                {getLocalizedMessage(intl, 'label.text.uploadedImage')}
                <span>{imageName}</span>
              </div>
            </div>
          )}
        </div>
        {error && <p className="text-danger mt-1">{error}</p>}

        {isFileSizeModalVisible && (
          <DeactivateModal
            open={isFileSizeModalVisible}
            setOpen={closeFileSizeModalHandler}
            modalContentText={getLocalizedMessage(intl, 'label.maxFileSize')}
            hideActionsButtons
          />
        )}
      </div>
    </>
  );
}
