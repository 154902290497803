import React, {useEffect, useState, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {useParams, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import InputLabel from '../../components/InputLabel/InputLabel';
import SelectDropdown from '../../components/SelectDropdown/SelectDropdown';
import {
  addStaffFormFieldNames,
  addStaffFormInitialValues,
  validationSchema,
} from '../../validationSchema/AddStaffSchema';
import CustomButton from '../../components/CustomButton/CustomButton';
import {
  addStaff,
  updateStaff,
  fetchStaffById,
  fetchRole,
} from '../../store/actions/staffActions';
import UploadFileImage from '../../components/UploadFileImage/UploadFileImage.js';
import useUserRole from '../../hooks/useUserRole.js';
import {checkIsSuperAdmin} from '../../utilityFunction/helper.js';

// @TODO - for fetching school data
import {fetchStudentList} from '../../store/actions/studentActions.js';
import {
  fetchSchoolList,
  fetchSchoolById,
} from '../../store/actions/schoolActions';
import {fetchStaffList} from '../../store/actions/staffActions';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';

export default function AddStaffForm() {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams();
  // const [mediaId, setMediaId] = useState(null);
  const [fileData, setFileData] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [showEditButton, setShowEditButton] = useState(true);
  const roleList = useSelector(state => state?.staff?.roleList);
  const isEditMode = editMode && id;
  const [fileId, setFileId] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [imageName, setImageName] = useState('');
  const userRole = useUserRole();
  const isSuperAdminRole = checkIsSuperAdmin(userRole);
  const {staffsList} = useSelector(state => state?.staff);

  // @TODO - For fetching school data
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const studentList = useSelector(state => state.student.studentList?.data);
  const [schoolId, setSchoolId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [schoolData, setSchoolData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState('asc');
  const [selectedRole, setSelectedRole] = useState('');
  const [teacherLimit, setTeacherLimit] = useState(null);
  const [counselorLimit, setCounselorLimit] = useState(null);
  const [schoolAdminLimit, setSchoolAdminLimit] = useState(null);
  // @TODO - for fetching school data
  const fetchStaffData = async () => {
    await dispatch(
      fetchStaffList({
        searchTerm,
        currentPage,
        sortOrder,
        selectedRole,
        schoolId,
        loaderDispatch,
      }),
    );
  };
  useEffect(() => {
    const rolePermissions = userRole;
    if (rolePermissions?.includes(userRole)) {
      setLoading(true);
      const schoolId = studentList?.data[0]?.school.id;
      setSchoolId(schoolId);
    }
  }, [studentList]);
  useEffect(() => {
    const rolePermissions = userRole;
    if (rolePermissions?.includes(userRole) && schoolId) {
      dispatch(fetchSchoolById(schoolId))
        .then(response => {
          const schoolData = response.payload.data;
          setSchoolData(schoolData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [schoolId]);
  useEffect(() => {
    fetchStaffData();
  }, []);
  useEffect(() => {
    let teacherCount = 0;
    let counsellorCount = 0;
    let schoolAdminCount = 0;

    staffsList.data.forEach(staff => {
      if (staff.staff_types === 'teacher') {
        teacherCount++;
      } else if (staff.staff_types === 'counsellor') {
        counsellorCount++;
      } else if (staff.staff_types === 'school_admin') {
        schoolAdminCount++;
      }
    });

    setTeacherLimit(teacherCount);
    setCounselorLimit(counsellorCount);
    setSchoolAdminLimit(schoolAdminCount);
  }, [staffsList]);

  const {
    values,
    handleChange,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues: addStaffFormInitialValues,
    onSubmit: async values => {
      const payload = {
        [addStaffFormFieldNames.ID]: parseInt(values.employee_id),
        [addStaffFormFieldNames.FIRST_NAME]: values.first_name,
        [addStaffFormFieldNames.LAST_NAME]: values.last_name,
        [addStaffFormFieldNames.EMAIL]: values.email_address,
        [addStaffFormFieldNames.DESIGNATION]: parseInt(values.role),
        [addStaffFormFieldNames.MEDIA_ID]: fileId,
      };

      if (id) {
        await dispatch(updateStaff({id, staffData: payload, intl, navigate}));
      } else {
        // @TODO - Add conditional statement to check if limit is reached
        await dispatch(addStaff({staffData: payload, intl, navigate}));
      }
    },
    validationSchema: validationSchema(intl),
  });
  const fetchData = async () => {
    if (id) {
      try {
        const response = await dispatch(fetchStaffById(id));
        const staffData = response.payload.data.data;
        setPreviewUrl(staffData?.profile_image?.path);
        setImageName(staffData?.profile_image?.name);
        setFileId(staffData.profile_image?.id);
        setValues(prev => ({
          ...prev,
          [addStaffFormFieldNames.ID]: staffData.employee_id,
          [addStaffFormFieldNames.FIRST_NAME]: staffData.first_name,
          [addStaffFormFieldNames.LAST_NAME]: staffData.last_name,
          [addStaffFormFieldNames.EMAIL]: staffData.email,
          [addStaffFormFieldNames.DESIGNATION]: parseInt(staffData.role.id),
        }));
      } catch (error) {
        console.log('Error fetching student data:', error);
      }
    }
  };

  useEffect(() => {
    if (id) {
      setEditMode(true);
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    dispatch(fetchRole());
  }, []);

  const onDesignationChange = e => {
    const selectedDesignationId = e.target.value;
    setFieldValue(addStaffFormFieldNames.DESIGNATION, selectedDesignationId);
  };

  const toggleEditMode = () => {
    setEditMode(prev => !prev);
    setShowEditButton(false);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const getDisplayName = roleName => {
    const roleNamesMap = {
      school_admin: 'School admin',
      counsellor: 'Counsellor',
      teacher: 'Teacher',
    };
    return roleNamesMap[roleName] || roleName;
  };

  const getRolesListOptions = roleList => {
    return roleList?.map(roleOpt => ({
      ...roleOpt,
      name: getDisplayName(roleOpt?.name),
    }));
  };

  return (
    <div className="container">
      <div className="add-student-wrapper">
        <div className="row">
          {!isSuperAdminRole && showEditButton && id && (
            <CustomButton
              labelText={intl.formatMessage({id: 'label.edit'})}
              handleClick={toggleEditMode}
              variant="blue"
              align="right"
            />
          )}

          <div className="cus-form-header">
            <h4>{intl.formatMessage({id: 'label.add_teacher_counsellor'})}</h4>
            <span>
              {intl.formatMessage({id: 'label.registration_instructions'})}
            </span>
          </div>
          <div className="mb-3 col-md-6">
            <InputLabel
              name={addStaffFormFieldNames.ID}
              label={intl.formatMessage({id: 'label.id_number'})}
              placeholder={intl.formatMessage({id: 'label.enter_id_number'})}
              value={values[addStaffFormFieldNames.ID]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[addStaffFormFieldNames.ID] &&
                errors[addStaffFormFieldNames.ID]
              }
              disabled={isEditMode}
            />
          </div>
          <div className="mb-3 col-md-6">
            <label htmlFor="file" className="form-label cus-form-label">
              Profile image
            </label>
            <UploadFileImage
              setFileId={setFileId}
              previewUrl={previewUrl}
              setPreviewUrl={setPreviewUrl}
              acceptType={'image/*'}
              isDisabled={isEditMode}
              hideImageTag
              imageName={imageName}
              setImageName={setImageName}
            />
          </div>
          <div className="mb-3 col-md-6">
            <InputLabel
              name={addStaffFormFieldNames.FIRST_NAME}
              label={intl.formatMessage({id: 'label.first_name'})}
              placeholder={intl.formatMessage({id: 'label.enter_first_name'})}
              value={values[addStaffFormFieldNames.FIRST_NAME]}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[addStaffFormFieldNames.FIRST_NAME] &&
                errors[addStaffFormFieldNames.FIRST_NAME]
              }
              disabled={isEditMode}
            />
          </div>
          <div className="mb-3 col-md-6">
            <InputLabel
              name={addStaffFormFieldNames.LAST_NAME}
              label={intl.formatMessage({id: 'label.last_name'})}
              placeholder={intl.formatMessage({id: 'label.enter_last_name'})}
              value={values[addStaffFormFieldNames.LAST_NAME]}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[addStaffFormFieldNames.LAST_NAME] &&
                errors[addStaffFormFieldNames.LAST_NAME]
              }
              disabled={isEditMode}
            />
          </div>

          <div className="mb-3 col-md-6">
            <InputLabel
              name={addStaffFormFieldNames.EMAIL}
              label={intl.formatMessage({id: 'label.email_address'})}
              placeholder={intl.formatMessage({
                id: 'label.enter_email_address',
              })}
              value={values[addStaffFormFieldNames.EMAIL]}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[addStaffFormFieldNames.EMAIL] &&
                errors[addStaffFormFieldNames.EMAIL]
              }
              disabled={isEditMode || id}
            />
          </div>

          <div className="mb-3 col-md-6">
            <SelectDropdown
              options={getRolesListOptions(roleList)}
              label={intl.formatMessage({id: 'label.role'})}
              placeholder={intl.formatMessage({
                id: 'label.select_role',
              })}
              required
              value={values[addStaffFormFieldNames.DESIGNATION]}
              onChange={e => onDesignationChange(e)}
              onBlur={handleBlur}
              error={
                touched[addStaffFormFieldNames.DESIGNATION] &&
                errors[addStaffFormFieldNames.DESIGNATION]
              }
              disabled={isEditMode}
            />
          </div>
        </div>
        {!isEditMode && (
          <div className="schoolFormBtn">
            <CustomButton
              labelText={intl.formatMessage({id: 'label.cancel'})}
              handleClick={handleCancel}
              variant="white"
              align="right"
            />
            <CustomButton
              labelText={
                id
                  ? intl.formatMessage({id: 'label.save_changes'})
                  : intl.formatMessage({id: 'label.register'})
              }
              handleClick={handleSubmit}
              variant="blue"
              align="right"
              disabled={
                isEditMode ||
                (teacherLimit >= schoolData?.teacher_limit &&
                  values[addStaffFormFieldNames.DESIGNATION] === '3') ||
                (counselorLimit >= schoolData?.counselor_limit &&
                  values[addStaffFormFieldNames.DESIGNATION] === '4') ||
                (schoolAdminLimit >= schoolData?.school_admin_limit &&
                  values[addStaffFormFieldNames.DESIGNATION] === '2')
              }
              type="submit"
            />
          </div>
        )}
      </div>
    </div>
  );
}
