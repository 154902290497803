import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {useFormik} from 'formik';
import CustomDialogue from '../CustomDialogue/CustomDialogue';
import InputLabel from '../InputLabel/InputLabel';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {
  addNewArticleFieldNames,
  addNewArticleFormInitialValues,
  validationSchema,
} from '../../validationSchema/addNewArticleSchema';
import UploadFileImage from '../UploadFileImage/UploadFileImage';

export default function AddArticleCard({open, setOpen, handleAddNewArticle}) {
  const intl = useIntl();
  const [fileId, setFileId] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [imageName, setImageName] = useState('');

  const {
    values,
    handleChange,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    handleBlur,
  } = useFormik({
    initialValues: addNewArticleFormInitialValues,
    onSubmit: async values => {
      const articleDataValues = {
        ...values,
        media_id: fileId,
        preview_url: previewUrl,
        file_name: imageName,
      };
      handleAddNewArticle(articleDataValues);
    },
    validationSchema: validationSchema(intl),
  });

  const closeAddArticleModal = () => {
    setOpen(false);
  };

  return (
    <CustomDialogue
      open={open}
      onCancel={closeAddArticleModal}
      cancelText={getLocalizedMessage(intl, 'label.cancel')}
      confirmText={getLocalizedMessage(intl, 'label.save')}
      dialogueTitle={getLocalizedMessage(intl, 'label.title.articleDetails')}
      onSubmit={handleSubmit}
      className={'center-btn'}>
      <div className="container">
        <div className="row mt-4">
          <div className="mb-3 col-md-12">
            <InputLabel
              name={addNewArticleFieldNames.ARTICLE_TITLE}
              label={getLocalizedMessage(intl, 'label.text.articleTitle')}
              placeholder={getLocalizedMessage(
                intl,
                'label.text.articleTitle.placeholder',
              )}
              required
              value={values[addNewArticleFieldNames.ARTICLE_TITLE]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[addNewArticleFieldNames.ARTICLE_TITLE] &&
                errors[addNewArticleFieldNames.ARTICLE_TITLE]
              }
            />
          </div>

          <div className="mb-3 col-md-12">
            <InputLabel
              name={addNewArticleFieldNames.ARTICLE_LINK}
              label={getLocalizedMessage(intl, 'label.text.articleLink')}
              placeholder={getLocalizedMessage(
                intl,
                'label.text.articleLink.placeholder',
              )}
              required
              value={values[addNewArticleFieldNames.ARTICLE_LINK]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[addNewArticleFieldNames.ARTICLE_LINK] &&
                errors[addNewArticleFieldNames.ARTICLE_LINK]
              }
            />
          </div>

          <div className="mb-3 mt-3 col-md-12">
            <UploadFileImage
              setFileId={idMedia => {
                setFieldValue('media_id', idMedia);
                setFileId(idMedia);
              }}
              previewUrl={previewUrl}
              setPreviewUrl={setPreviewUrl}
              acceptType={'image/*'}
              imageName={imageName}
              setImageName={setImageName}
              error={
                touched[addNewArticleFieldNames.MEDIA_ID] &&
                errors[addNewArticleFieldNames.MEDIA_ID]
              }
            />
          </div>
        </div>
      </div>
    </CustomDialogue>
  );
}
