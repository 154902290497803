import * as Yup from 'yup';

export const addLessonQuesFormFieldNames = {
  QUESTION: 'question',
};

export const addLessonQuesFormInitialValues = {
  [addLessonQuesFormFieldNames.QUESTION]: '',
};

export const validationSchema = intl => {
  return Yup.object().shape({
    [addLessonQuesFormFieldNames.QUESTION]: Yup.string()
      .required(
        intl.formatMessage({id: 'addQuestion.fieldError.name.required'}),
      )
      .min(2, intl.formatMessage({id: 'addQuestion.fieldError.name.minLength'}))
      .max(
        120,
        intl.formatMessage({id: 'addQuestion.fieldError.name.maxLength'}),
      ),
  });
};

export const addQuesAnswerFormFieldName = {
  ANSWER: 'answer',
};

export const addQuesAnswerFormInitialValues = {
  [addQuesAnswerFormFieldName.ANSWER]: '',
};

export const answerValidationSchema = intl => {
  return Yup.object().shape({
    [addQuesAnswerFormFieldName.ANSWER]: Yup.string()
      .required(
        intl.formatMessage({id: 'addAnswer.fieldError.answer.required'}),
      )
      .min(
        2,
        intl.formatMessage({id: 'addAnswer.fieldError.answer.minLength'}),
      ),
  });
};
