import {
  fetchCountries,
  fetchStates,
  fetchIntermediateUnits,
  fetchDistricts,
  fetchDesignation,
  getDistricts,
  uploadMedia,
} from '../store/actions/schoolActions';
import {
  fetchRole,
} from '../store/actions/staffActions';
import axiosPrivateInstance from '../api/axiosPrivateInstance';
import {createAsyncThunk} from '@reduxjs/toolkit';
import {showToast} from '../components/Toast/Toast';
import {getLocalizedMessage} from '../utilityFunction/helper';
import { setLoading } from '../globalContext/globalLoader/globalLoaderAction';

export const fetchCountriesData = async (dispatch, setCountries) => {
  try {
    const response = await dispatch(fetchCountries());
    const countriesArray = response.payload.data;
    setCountries(countriesArray);
  } catch (error) {
    console.log('Error fetching countries:', error);
  }
};

export const fetchStatesData = async (dispatch, countryId, setStates) => {
  try {
    const response = await dispatch(fetchStates(countryId));
    const statesArray = response.payload.data;
    setStates(statesArray);
  } catch (error) {
    console.log('Error fetching states:', error);
  }
};

export const fetchIntermediateUnitData = async (
  dispatch,
  stateId,
  setIntermediateUnit,
) => {
  try {
    const response = await dispatch(fetchIntermediateUnits(stateId));
    const intermediateUnitArray = response?.payload?.data?.data;
    setIntermediateUnit(intermediateUnitArray);
  } catch (error) {
    console.log('Error fetching intermediateUnit:', error);
  }
};

export const fetchDistrictData = async (
  {dispatch,
  parsedValue,
  setDistricts,
  loaderDispatch}
) => {
  try {
    const response = await dispatch(
      getDistricts({stateId: parsedValue, loaderDispatch}),
    );
    const districtArray = response.payload.data;
    setDistricts(districtArray);
  } catch (error) {
    console.log("Error",error)
    console.log('Error fetching district:', error);
  }
};

export const fetchDesignationsData = async (dispatch, setDesignations) => {
  try {
    const response = await dispatch(fetchDesignation());
    const designationsArray = response.payload.data;
    setDesignations(designationsArray);
  } catch (error) {
    console.log('Error fetching designations:', error);
  }
};

export const fetchRoleData = async (dispatch, setRole) => {
  try {
    const response = await dispatch(fetchRole());
    const roleArray = response.payload.data;
    setRole(roleArray);
  } catch (error) {
    console.log('Error fetching role:', error);
  }
};


export const handleFileUpload = async (
  dispatch,
  formData,
  setSchoolDetails,
) => {
  try {
    const response = await dispatch(uploadMedia(formData));
    const media_id = response.payload.data.media_id;
    setSchoolDetails(prevSchoolDetails => ({
      ...prevSchoolDetails,
      media_id,
    }));
  } catch (error) {
    console.log('Error uploading media:', error);
  }
};

export const handleFileUploads = async (dispatch, file, setFileId) => {
  try {
    const formData = new FormData();
    formData.append('file', file);
    const response = await axiosPrivateInstance.post('/media', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    const media_id = response?.data?.data?.media_id;
    setFileId(media_id);
  } catch (error) {
    console.log('Error uploading media:', error);
  }
};

export const handleImageUpload = createAsyncThunk(
  'upload/image',
  async ({file, setFileId, setPreviewUrl,setImageName, intl}, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await axiosPrivateInstance.post('/media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const media_id = response?.data?.data?.media_id;
      setFileId(media_id);
      setPreviewUrl(URL.createObjectURL(file));
      setImageName(file.name);
      return response?.data?.data?.media_id;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);
