import {createSlice} from '@reduxjs/toolkit';
import {
  getLessonContentData,
  getSectionsLesson,
} from '../actions/lessonActions';

const initialState = {
  sectionLessonsList: [],
  lessonContentData: [],
};

const islandSlice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {
    resetLessonContentData(state) {
      state.lessonContentData = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getSectionsLesson.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSectionsLesson.fulfilled, (state, action) => {
        state.loading = false;
        state.sectionLessonsList = action.payload;
        state.error = null;
      })
      .addCase(getSectionsLesson.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getLessonContentData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLessonContentData.fulfilled, (state, action) => {
        state.loading = false;
        state.lessonContentData = action.payload;
        state.error = null;
      })
      .addCase(getLessonContentData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {resetLessonContentData} = islandSlice.actions;
export default islandSlice.reducer;
