import * as Yup from 'yup';

export const addNotesFormFieldNames = {
  NOTE: 'note',
};

export const addNotesFormInitialValues = {
  [addNotesFormFieldNames.NOTE]: '',
};

export const validationSchema = intl => {
  return Yup.object().shape({
    [addNotesFormFieldNames.NOTE]: Yup.string()
      .required(intl.formatMessage({id: 'addNotes.fieldError.notes.required'}))
      .min(3, intl.formatMessage({id: 'addNotes.fieldError.notes.minLength'}))
      .max(
        500,
        intl.formatMessage({id: 'addNotes.fieldError.notes.maxLength'}),
      ),
  });
};
