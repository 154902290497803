import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {useIntl} from 'react-intl';
import {MANAGE_SECTION_PATH, SHOW_HELP} from '../../../constants/routePaths';
import {
  getIslandsList,
  getLessonStyle,
  updateIslandStatus,
} from '../../../store/actions/islandActions';
import IslandCardDetails from '../../../components/IslandCardDetails/IslandCardDetails';
import DeactivateModal from '../../../components/DeactivateModal/DeactivateModal';
import EditIslandDetails from './EditIslandDetails';
import {
  editContentButtonsAvailable,
  getIslandStatusText,
  getLocalizedMessage,
} from '../../../utilityFunction/helper';
import {LESSON_STYLE_PATH} from '../../../constants/routePaths';
import {GlobalLoaderContext} from '../../../globalContext/globalLoader/globalLoaderProvider';
import useUserRole from '../../../hooks/useUserRole';
import {commonStyle} from '../../../Style/commonStyle';
import {resetSectionsList} from '../../../store/reducers/sectionReducers';

export default function IslandsListing() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {lessonStyleData} = useSelector(state => state?.island);
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const {isAuthenticated} = useSelector(state => state?.auth);
  const {islandsList} = useSelector(state => state?.island);
  const [selectedIsland, setSelectedIsland] = useState(null);
  const [openEditIslandModal, setOpenEditIslandModal] = useState(false);
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const userRole = useUserRole();
  const isEditAvailable = editContentButtonsAvailable(userRole);

  useEffect(() => {
    if (isAuthenticated) fetchIslandDetails();
  }, [isAuthenticated]);

  useEffect(() => {
    dispatch(getLessonStyle({loaderDispatch}));
  }, []);

  const fetchIslandDetails = async () => {
    dispatch(resetSectionsList());
    await dispatch(getIslandsList({loaderDispatch}));
  };

  const handleSeeSection = id => {
    navigate(`${MANAGE_SECTION_PATH.replace(':id', id)}`);
  };

  const handleEditIsland = island => {
    setOpenEditIslandModal(true);
  };

  const handleUpdateIslandStatus = island => {
    setOpenDeactivateModal(true);
  };

  const onEditLessonStyle = async () => {
    navigate(LESSON_STYLE_PATH, {
      state: {
        lessonStyleData: lessonStyleData,
      },
    });
  };

  const handleShowHelp = () => {
    navigate(SHOW_HELP);
  };

  const onDeactivateConfirm = async () => {
    if (selectedIsland?.id) {
      setOpenDeactivateModal(false);
      dispatch(updateIslandStatus({id: selectedIsland?.id, intl})).then(() => {
        setSelectedIsland(null);
        fetchIslandDetails();
      });
    }
  };

  return (
    <div>
      <div className="d-flex student-detail-wrapper">
        <div className="school-label">
          {intl.formatMessage({id: 'label.details_of_islands'})}
        </div>

        <div className="add-import-btn">
          <div className="add-student-btn">
            {isEditAvailable && (
              <button
                style={commonStyle.editLessonStyleBtn}
                onClick={handleShowHelp}
                className="me-3">
                <span>{intl.formatMessage({id: 'label.help'})}</span>
              </button>
            )}
            {isEditAvailable && (
              <button
                style={commonStyle.editLessonStyleBtn}
                onClick={onEditLessonStyle}>
                <img src={'/images/magicPen.svg'} alt={'Edit lesson'} />
                <span className="ms-3">
                  {intl.formatMessage({id: 'label.editLessonStyle'})}
                </span>
              </button>
            )}
          </div>
        </div>
      </div>

      <IslandCardDetails
        islandOptions={islandsList}
        onSeeSection={id => handleSeeSection(id)}
        onEditIsland={handleEditIsland}
        onUpdateStatus={handleUpdateIslandStatus}
        selectedIsland={selectedIsland}
        setSelectedIsland={setSelectedIsland}
        isEditAvailable={isEditAvailable}
      />

      {openEditIslandModal && (
        <EditIslandDetails
          open={openEditIslandModal}
          setOpen={setOpenEditIslandModal}
          islandData={selectedIsland}
          setSelectedIsland={setSelectedIsland}
        />
      )}

      {openDeactivateModal && (
        <DeactivateModal
          open={openDeactivateModal}
          setOpen={setOpenDeactivateModal}
          handleDeactivate={onDeactivateConfirm}
          confirmText={getIslandStatusText(selectedIsland?.is_active, intl)}
          modalContentText={
            !selectedIsland?.is_active
              ? getLocalizedMessage(intl, 'island.statusModal.enable')
              : getLocalizedMessage(intl, 'island.statusModal.disable')
          }
        />
      )}
    </div>
  );
}
