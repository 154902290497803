import React from 'react';
import './WeeklyProgressCard.scss';

function WeeklyProgressCard({
  progressIcon,
  progressTitle,
  progressValue,
  IncDec,
  className
}) {
  
  const arrowDirection = IncDec > 0 ? <span>▲</span> : IncDec < 0 ? <span>▼</span> : '';
  const arrowColor = IncDec > 0 ? 'green' : IncDec < 0 ? 'red' : '';

  return (
    <div className={`weekly-progress-card ${className}`}>
      <div className="progress-icon">
        <img src={progressIcon} alt="Progress Icon" />
      </div>
      <div className="progress-data">
        <div className="weekly-title">{progressTitle}</div>
        <div className="weekly-numbers">
          <div className="value">{progressValue}</div>
          <div className={`inc-dec ${arrowColor}`}>{arrowDirection} {Math.abs(IncDec)}%</div>
        </div>
      </div>
    </div>
  );
}

export default WeeklyProgressCard;
