import {createSlice} from '@reduxjs/toolkit';
import {fetchAvatars} from '../actions/studentModuleActions';

const initialState = {
    avatarList: [],
};

const studentModuleSlice = createSlice({
  name: 'studentModule',
  initialState,
  reducers: {
    // for reducers
  },
  extraReducers: builder => {
    builder
      .addCase(fetchAvatars.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAvatars.fulfilled, (state, action) => {
        state.loading = false;
        state.avatarList = action.payload;
        state.error = null;
      })
      .addCase(fetchAvatars.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default studentModuleSlice.reducer;
