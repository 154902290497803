import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {Typography} from '@mui/material';
import moment from 'moment';
import {useNavigate} from '../../routes/index.js';
import CustomTable from '../../components/CustomTable/CustomTable';
import SearchBar from '../../components/SearchBar/SearchBar';
import {getQuesAnsList} from '../../store/actions/questionAnswerActions';
import {VIEW_LESSON_CONTENTS} from '../../constants/routePaths.js';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';
import {commonStyle} from '../../Style/commonStyle';
import './ManageQuestionListView.scss';

export default function ManageQuestionListView() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const {allQuesAnsData} = useSelector(state => state?.questionAnswer);
  const [expandedRows, setExpandedRows] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchQuestions();
  }, [currentPage, searchTerm]);

  function fetchQuestions() {
    dispatch(
      getQuesAnsList({
        currentPage: currentPage,
        search: searchTerm,
        loaderDispatch,
      }),
    );
  }

  const onPageChange = page => {
    setCurrentPage(page);
  };

  const toggleRowExpansion = id => {
    setExpandedRows(prevExpandedRows => ({
      ...prevExpandedRows,
      [id]: !prevExpandedRows[id],
    }));
  };

  const onProvideAnswer = question => {
    const lesson = question?.lesson;
    navigate(VIEW_LESSON_CONTENTS, {
      state: {
        lessonInfo: lesson,
        isStaffOrSchoolAdmin: true,
        chosenQuestion: question,
      },
    });
  };

  const seeLessonContentsAndQuestions = question => {
    const lesson = question?.lesson;
    navigate(VIEW_LESSON_CONTENTS, {
      state: {lessonInfo: lesson, isStaffOrSchoolAdmin: true},
    });
  };

  const renderAnswer = (ansData, id) => {
    const isExpanded = expandedRows[id];
    const text = isExpanded
      ? ansData?.answer
      : ansData?.answer.substring(0, 40) + '...';

    return (
      <div className="quesAnsInfo-listWrapper">
        <div>
          <Typography variant="subtitle">{text}</Typography>
          <Typography
            variant="subtitle"
            onClick={() => toggleRowExpansion(id)}
            style={commonStyle.toggleText}>
            {isExpanded ? ' See Less' : ' See More'}
          </Typography>
        </div>

        <div className="askAndPinQuesWrapper">
          <div className="askWrapper">
            <div className="quesPinAndAskLabel">
              {intl.formatMessage({id: 'label.ansBy'})}
            </div>
            <div className="quesPinAndAskLabel">{':'}</div>
            <div className="quesPinAndAskText">{`${ansData?.created_by?.first_name} ${ansData?.created_by?.last_name}`}</div>
          </div>

          <div className="askWrapper">
            <div className="quesPinAndAskLabel">
              {intl.formatMessage({id: 'label.ansOn'})}
            </div>
            <div className="quesPinAndAskLabel">{':'}</div>
            <div className="quesPinAndAskText">{`${moment(ansData?.date).format(
              'DD/MM/YYYY',
            )}`}</div>
          </div>
        </div>
      </div>
    );
  };

  const TABLE_HEAD = [
    {
      id: 'moduleName',
      label: intl.formatMessage({id: 'label.moduleName'}),
      alignRight: false,
      width: '20%',
      renderColumn: question => {
        return (
          <div
            className="moduleName-quesList"
            onClick={() => seeLessonContentsAndQuestions(question)}>
            <Typography variant="subtitle">
              {question?.lesson?.name || '-'}
            </Typography>
          </div>
        );
      },
    },
    {
      id: 'question',
      label: intl.formatMessage({id: 'label.question'}),
      alignRight: false,
      width: '49%',
      renderColumn: question => {
        return <RenderQuestionInfo question={question} intl={intl} />;
      },
    },
    {
      id: 'answer',
      width: '40%',
      label: intl.formatMessage({id: 'label.answer'}),
      alignRight: false,
      renderColumn: question => {
        return question?.answers?.answer ? (
          renderAnswer(question.answers, question?.question_id)
        ) : (
          <button
            onClick={() => onProvideAnswer(question)}
            style={commonStyle.lessonEditButton}>
            {intl.formatMessage({id: 'label.provideAnswer'})}
          </button>
        );
      },
    },
  ];

  return (
    <div>
      <div className="d-flex questions-listDetail-wrapper">
        <div className="questions-headingLabel">
          {intl.formatMessage({id: 'label.details_of_questions'})}
        </div>

        <div>
          <SearchBar
            placeholder={intl.formatMessage({id: 'label.searchByQuesAndAns'})}
            onChange={e => {
              setSearchTerm(e.target.value);
              setCurrentPage(1);
            }}
          />
        </div>
      </div>

      <CustomTable
        tableHead={TABLE_HEAD}
        tableData={allQuesAnsData?.data}
        currentPage={currentPage}
        totalPages={allQuesAnsData?.last_page}
        totalCount={allQuesAnsData?.total}
        onPageChange={onPageChange}
      />
    </div>
  );
}

const RenderQuestionInfo = ({intl, question}) => {
  return (
    <div className="quesAnsInfo-listWrapper">
      <Typography variant="subtitle">{question?.question || '-'}</Typography>
      <div className="askAndPinQuesWrapper">
        <div className="askWrapper">
          <div className="quesPinAndAskLabel">
            {intl.formatMessage({id: 'label.askBy'})}
          </div>
          <div className="quesPinAndAskLabel">{':'}</div>
          <div className="quesPinAndAskText">{`${question?.first_name} ${question?.last_name}`}</div>
        </div>

        <div className="askWrapper">
          <div className="quesPinAndAskLabel">
            {intl.formatMessage({id: 'label.askOn'})}
          </div>
          <div className="quesPinAndAskLabel">{':'}</div>
          <div className="quesPinAndAskText">{`${moment(question?.date).format(
            'DD/MM/YYYY',
          )}`}</div>
        </div>

        {question?.answers?.answer_id && question?.pinned_by && (
          <div className="pinWrapper">
            <div className="quesPinAndAskLabel">
              {intl.formatMessage({id: 'label.pinBy'})}
            </div>
            <div className="quesPinAndAskLabel">{':'}</div>
            <div className="quesPinAndAskText">
              {`${question?.pinned_by?.first_name} ${question?.pinned_by?.last_name}`}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
