import React, {useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {MAX_FILE_SIZE} from '../../utilityFunction/constants';
import DeactivateModal from '../DeactivateModal/DeactivateModal';
import {showToast} from '../Toast/Toast';
import './AttachFileCard.scss';

export default function AttachFileCard({
  handleAttachmentDelete,
  onFileUpload,
  error,
  filePreviewName,
  name,
  isOnlyPdf,
}) {
  const intl = useIntl();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFileSizeModalVisible, setIsFileSizeModalVisible] = useState(false);

  let allowedFileTypes;

  if (isOnlyPdf) {
    allowedFileTypes = ['application/pdf'];
  } else {
    allowedFileTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/pdf',
      'application/vnd.ms-powerpoint',
    ];
  }

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = event => {
    const file = event.target.files[0];
    if (!allowedFileTypes.includes(file.type)) {
      let errorMessage = isOnlyPdf
        ? getLocalizedMessage(intl, 'label.onlyPdfAllowed')
        : getLocalizedMessage(intl, 'label.invalidAttachmentFileType');

      showToast(errorMessage, 'error');
      event.target.value = '';
      return;
    }

    if (file?.size > MAX_FILE_SIZE) {
      setIsFileSizeModalVisible(true);
      event.target.value = '';
      return;
    }
    if (file) {
      setSelectedFile(file);
      onFileUpload(file);
    } else {
      setSelectedFile(null);
    }
  };

  const closeFileSizeModalHandler = () => {
    setIsFileSizeModalVisible(false);
  };

  return (
    <>
      <div className="attach-file-wrapper">
        <div className="attachment-text">
          <div className="attachment-label">
            <span className="handle-icon mr-8">☰</span>
            {intl.formatMessage({id: 'label.attachment'})}
            <span className="attachment-subtext ms-1">
              {intl.formatMessage({id: 'label.maxFileSize'})}
            </span>
          </div>
          {!isOnlyPdf && (
            <img
              className="trash-icon"
              src="/images/trash.svg"
              onClick={handleAttachmentDelete}
            />
          )}
        </div>
        <div className="attach-file-content">
          <div style={{display: 'flex', alignItems: 'center'}}>
            <button className="upload-file-btn" onClick={handleButtonClick}>
              {getLocalizedMessage(intl, 'label.uploadFile')}
            </button>
            <input
              ref={fileInputRef}
              id="upload-button"
              type="file"
              accept="image/jpeg,image/png,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/pdf,application/vnd.ms-powerpoint"
              onChange={handleFileChange}
              style={{display: 'none'}}
              name={name}
            />
          </div>

          {filePreviewName && (
            <div className="file-preview-name-wrapper">
              {intl.formatMessage({id: 'label.uploadedFileName'})}
              <span className="ms-2">{filePreviewName}</span>
            </div>
          )}

          {isFileSizeModalVisible && (
            <DeactivateModal
              open={isFileSizeModalVisible}
              setOpen={closeFileSizeModalHandler}
              modalContentText={getLocalizedMessage(intl, 'label.maxFileSize')}
              hideActionsButtons
            />
          )}
        </div>
      </div>
      {error && <p className="text-danger mt-1">{error}</p>}
    </>
  );
}
