import React, {useEffect, useState} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import './CustomTable.scss';
import TableNotFound from './TableNotFound';

function CustomTable(props) {
  const {
    tableHead,
    tableData,
    totalCount,
    selected = [],
    setSelected = () => {},
    handleClick = () => {},
    showSelected = true,
    pageSize,
    currentPage,
    onPageChange,
    totalPages,
    showPagination = true,
    isDashboardTable = false,
    dashboardTableClass,
  } = props;

  const [rowData, setRowData] = useState([]);
  const [paginatedRows, setPaginatedRows] = useState([]);

  useEffect(() => {
    setPaginatedRows(rowData);
  }, [currentPage, pageSize, rowData]);

  const goToPage = page => {
    if (page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  };

  useEffect(() => {
    if (tableData?.length || totalCount === 0) {
      setRowData(tableData);
    }
  }, [tableData, totalCount]);

  const renderTable = () => {
    return rowData?.length > 0 ? (
      <TableBody>
        {rowData?.map((row, index) => {
          return (
            <TableRow hover key={row?.id || row?.email} tabIndex={-1}>
              {tableHead?.map(column => {
                return (
                  <TableCell
                    key={`${row?.id}${column?.id}`}
                    align={column.textAlign || 'left'}
                    sx={{
                      ...(column?.minWidth
                        ? {minWidth: column?.minWidth}
                        : {minWidth: '10%'}),
                      ...(column?.ellipsis && commonStyles.ellipsisStyle),
                    }}>
                    {column.renderColumn ? (
                      column.renderColumn(row, index)
                    ) : (
                      <Typography variant="subtitle" noWrap>
                        {row[column?.dataKey]}
                      </Typography>
                    )}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    ) : (
      <TableNotFound />
    );
  };

  return (
    <>
      <Table
        stickyHeader
        className={`custom-table ${
          isDashboardTable ? dashboardTableClass : ''
        }`}>
        <TableHead className="custom-table-header">
          <TableRow>
            {tableHead.map(headCell => (
              <TableCell
                key={headCell.id}
                width={headCell.width}
                align="center">
                <TableSortLabel hideSortIcon>{headCell.label}</TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        {renderTable()}
      </Table>

      {showPagination && totalPages && (
        <div className="pagination-wrapper">
          <div>
            <span>{`Page ${currentPage} of ${totalPages}`}</span>
          </div>
          <div className="pagination-btn">
            <button
              disabled={currentPage === 1}
              onClick={() => goToPage(currentPage - 1)}>
              Previous
            </button>

            <button
              disabled={currentPage === totalPages}
              onClick={() => goToPage(currentPage + 1)}>
              Next
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default CustomTable;
