import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {showToast} from '../../components/Toast/Toast';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {setLoading} from '../../globalContext/globalLoader/globalLoaderAction';

export const downloadTemplate = createAsyncThunk(
  'download/templateDownload',
  async ({templateType, intl, templateName, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.get(
        `/get-template?template_type=${templateType}`,
        {responseType: 'blob'},
      );
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      // Create an object URL for the Blob
      const downloadUrl = window.URL.createObjectURL(blob);
      const fileName = templateName;
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(downloadUrl);
      link.remove();
      loaderDispatch(setLoading(false));
      showToast(
        getLocalizedMessage(intl, 'success.templateDownloaded'),
        'success',
      );
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const uploadTemplate = createAsyncThunk(
  'upload/uploadBulkSchool',
  async ({file, url, intl}, thunkAPI) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await axiosPrivateInstance.post(url, formData, {
        headers: {'Content-Type': 'multipart/form-data'},
        responseType: 'blob', // Expect a binary response
      });

      // Attempt to read the Blob as text to check if it's JSON
      const reader = new FileReader();
      reader.readAsText(response.data);
      reader.onload = () => {
        try {
          // Try to parse the text as JSON
          const jsonResponse = JSON.parse(reader.result);
          if (jsonResponse?.message) {
            showToast(
              getLocalizedMessage(intl, 'success.schoolImported'),
              'success',
            );
          }
        } catch (e) {
          const blobUrl = URL.createObjectURL(response.data);
          const anchor = document.createElement('a');
          anchor.href = blobUrl;
          anchor.download = 'Error_Report.xlsx';
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          URL.revokeObjectURL(blobUrl);
          showToast(getLocalizedMessage(intl, 'info.errorReport'), 'warning');
        }
      };
    } catch (error) {
      const errorData = JSON.parse(await error?.response?.data?.text());
      showToast(`Something went wrong: ${errorData?.message}`, 'error');
      throw error.response.data.message;
    }
  },
);
