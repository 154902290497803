import React from 'react';
import {useIntl} from 'react-intl';
import ThreeDButton from '../../../../components/ThreeDButton/ThreeDButton';
import './LessonUncompleteModal.scss';

export default function LessonUncompleteModal({
  exitLessonHandler,
  keepLearningHandler,
}) {
  const intl = useIntl();

  return (
    <div className="holdon-wrapper">
      <div className="holdon-text-details">
        <img
          className="lesson-complete-icon"
          src={'/images/stopIcon.svg'}
          alt="Hold on right there"
        />

        <div className="holdit-text-wrapper">
          <div className="holdit-text text-shadow">
            {intl.formatMessage({id: 'hold.rightThere.text'})}
          </div>
          <div className="holdit-subtext">
            {intl.formatMessage({id: 'loose.progress.text'})}
          </div>
        </div>
      </div>

      <div className="holdon-buttons-wrapper">
        <ThreeDButton
          className="border-0"
          handleClick={exitLessonHandler}
          labelText={intl.formatMessage({id: 'exit.Lesson'})}
          buttonColorClass="blueBtn"
          innerColorClass="blueInner"
        />

        <ThreeDButton
          className="border-0"
          handleClick={keepLearningHandler}
          labelText={intl.formatMessage({id: 'keep.Learning'})}
        />
      </div>
    </div>
  );
}
