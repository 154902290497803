const UserRoles = {
  SUPER_ADMIN: 'super_admin',
  SCHOOL_ADMIN: 'school_admin',
  SCHOOL_TEACHER: 'teacher',
  COUNSELLOR: 'counsellor',
  STUDENT: 'student',
};
export const SCHOOL_NAME_HEAD_COL = {
  SCHOOL_NAME: 'School Name & Address',
  SCHOOL_EMAIL: 'School Email Address',
  SCHOOL_CONTACT: 'Contact No.',
  SCHOOL_LICENSE: 'License Use',
  SCHOOL_ACTIONS: 'Quick Actions',
};

export default UserRoles;