import React, {useContext} from 'react';
import {IntlProvider} from 'react-intl';
import { ToastContainer } from 'react-toastify';
import {MediaQueryProvider, ThemeProvider} from '@unthinkable/react-theme';
import 'react-toastify/dist/ReactToastify.css';

import intl from './locale';
import {LocaleContext} from './globalContext/locale/localeProviders';

import theme from './theme';
import {Router, Routes} from './routes';
import {GlobalLoaderContext} from './globalContext/globalLoader/globalLoaderProvider';
import BackdropGlobalLoader from './components/BackdropGlobalLoader/BackdropGlobalLoader';

function App() {
  const [localeState] = useContext(LocaleContext);
  const [globalLoader] = useContext(GlobalLoaderContext);
  const {
    base: {breakpoints},
  } = theme;

  return (
    <MediaQueryProvider breakpoints={breakpoints}>
      <ThemeProvider {...theme}>
        <IntlProvider
          messages={intl[localeState?.locale]}
          locale={localeState?.locale}
          defaultLocale="en">
          <Router>
            <Routes />
            {globalLoader?.loading && <BackdropGlobalLoader open={true} />}
          </Router>
          <ToastContainer />
        </IntlProvider>
      </ThemeProvider>
    </MediaQueryProvider>
  );
}

export default App;
