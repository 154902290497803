import React, {useEffect, useState, useContext} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import StaffQuestionAnswerListView from '../../../components/StaffQuestionAnswerListView/StaffQuestionAnswerListView';
import {showToast} from '../../../components/Toast/Toast';
import useUserRole from '../../../hooks/useUserRole';
import {
  getLocalizedMessage,
  getStaffLoginId,
} from '../../../utilityFunction/helper';
import {GlobalLoaderContext} from '../../../globalContext/globalLoader/globalLoaderProvider';
import {
  getStudentQuesAns,
  pinQuestion,
  submitAnswer,
  unPinQuestion,
  updateAnswer,
} from '../../../store/actions/questionAnswerActions';
import {
  addQuesAnswerFormFieldName,
  addQuesAnswerFormInitialValues,
  answerValidationSchema,
} from '../../../validationSchema/addLessonQuesSchema';
import CustomDialogue from '../../../components/CustomDialogue/CustomDialogue';
import {commonStyle} from '../../../Style/commonStyle';

export default function StaffQuestionAnswerTabView({lesson, chosenQuestion}) {
  const intl = useIntl();
  const userRole = useUserRole();
  const dispatch = useDispatch();
  const loginData = useSelector(state => state?.auth);
  const {quesAnsList} = useSelector(state => state?.questionAnswer);
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const [showAddAnsView, setShowAddAnsView] = useState(false);
  const [selectedQues, setSelectedQues] = useState(null);
  const [showPinModal, setShowPinModal] = useState(false);
  const [pinQues, setPinQues] = useState(null);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    setFieldValue,
    resetForm,
    dirty,
    isValid,
  } = useFormik({
    initialValues: addQuesAnswerFormInitialValues,
    validationSchema: answerValidationSchema(intl),
  });

  useEffect(() => {
    fetchQuestionAnswers();
  }, []);

  useEffect(() => {
    if (chosenQuestion) {
      setSelectedQues(chosenQuestion);
      setShowAddAnsView(true);
    }
  }, [chosenQuestion]);

  function fetchQuestionAnswers() {
    dispatch(
      getStudentQuesAns({
        lessonId: lesson?.id,
      }),
    );
  }

  function onAddAnswer(ques, isEdit) {
    if (!isValid) return;
    if (!dirty) {
      showToast(getLocalizedMessage(intl, 'error.requiredAns'), 'error');
      return;
    }
    if (isValid && dirty) {
      if (isEdit) {
        const ansId = ques?.answers?.answer_id;
        const ansData = {answer: values?.answer};
        dispatch(updateAnswer({ansId, ansData, intl, loaderDispatch})).then(
          () => {
            handleResponse();
          },
        );
      } else {
        const questionId = ques?.question_id;
        const ansData = {
          question_id: questionId,
          answer: values?.answer,
        };
        dispatch(submitAnswer({ansData, intl, loaderDispatch})).then(() => {
          handleResponse();
        });
      }
    }
  }

  function handleResponse() {
    fetchQuestionAnswers();
    setShowAddAnsView(false);
    setSelectedQues(null);
    resetForm();
  }

  function onQuesPinned(ques) {
    setPinQues(ques);
    setShowPinModal(true);
  }

  function onCancelPin() {
    setPinQues(null);
    setShowPinModal(false);
  }

  function onConfirmPin() {
    const quesId = pinQues?.question_id;
    if (!pinQues?.is_pinned) {
      dispatch(pinQuestion({quesId, intl, loaderDispatch})).then(() => {
        fetchQuestionAnswers();
      });
    } else {
      if (pinQues?.pinned_by?.id === staffLoginId) {
        dispatch(unPinQuestion({quesId, intl, loaderDispatch})).then(() => {
          fetchQuestionAnswers();
        });
      } else {
        showToast(intl.formatMessage({id: 'pinnedBy.anotherUser'}), 'error');
      }
    }
    setPinQues(null);
    setShowPinModal(false);
  }

  const staffLoginId = getStaffLoginId(loginData, userRole);

  return (
    <>
      {quesAnsList?.length > 0 ? (
        <div className="submit-quesAnsWrapperDetails">
          <StaffQuestionAnswerListView
            quesAnsData={quesAnsList}
            showAddAnsView={showAddAnsView}
            selectedQues={selectedQues}
            setShowAddAnsView={setShowAddAnsView}
            setSelectedQues={setSelectedQues}
            onAddAnswer={onAddAnswer}
            onPinQues={onQuesPinned}
            name={addQuesAnswerFormFieldName.ANSWER}
            value={values[addQuesAnswerFormFieldName.ANSWER]}
            setFieldValue={setFieldValue}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched[addQuesAnswerFormFieldName.ANSWER] &&
              errors[addQuesAnswerFormFieldName.ANSWER]
            }
            resetForm={resetForm}
          />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <img src="/images/NoDataFound.svg" alt="no_data" />
        </div>
      )}

      {showPinModal && (
        <CustomDialogue
          open={showPinModal}
          isDisableModal
          handleClose={onCancelPin}
          onSubmit={onConfirmPin}
          onCancel={onCancelPin}
          cancelText={intl.formatMessage({id: 'label.cancel'})}
          confirmText={intl.formatMessage({id: 'label.confirm'})}>
          <div style={commonStyle.modalContentTextStyle}>
            {getLocalizedMessage(intl, 'pin.quesText', {
              pinStatus: pinQues?.is_pinned ? 'unpinned' : 'pinned',
            })}
          </div>
        </CustomDialogue>
      )}
    </>
  );
}
