import {createSlice} from '@reduxjs/toolkit';
import {
  getDashboardStats,
  getDashboardStudentsList,
  getStudentDashboardDetails,
  getSuperAdminDashboardDistricts,
} from '../actions/dashboardActions';

const initialState = {
  dashboardStudentsList: [],
  studentDashboardAllDetails: [],
  dashboardStatsInfo: [],
  dashboardDistrictsData: [],
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getDashboardStudentsList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDashboardStudentsList.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardStudentsList = action.payload;
        state.error = null;
      })
      .addCase(getDashboardStudentsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getStudentDashboardDetails.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getStudentDashboardDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.studentDashboardAllDetails = action.payload;
        state.error = null;
      })
      .addCase(getStudentDashboardDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getDashboardStats.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDashboardStats.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardStatsInfo = action.payload;
        state.error = null;
      })
      .addCase(getDashboardStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getSuperAdminDashboardDistricts.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSuperAdminDashboardDistricts.fulfilled, (state, action) => {
        state.loading = false;
        state.dashboardDistrictsData = action.payload;
        state.error = null;
      })
      .addCase(getSuperAdminDashboardDistricts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default dashboardSlice.reducer;
