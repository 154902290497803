import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {setLoading} from '../../globalContext/globalLoader/globalLoaderAction';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {showToast} from '../../components/Toast/Toast';

export const getSuperAdminData = createAsyncThunk(
  'content/getSuperAdminData',
  async (
    {loaderDispatch, currentPage, isPaginated = true},
    thunkAPI,
  ) => {
    loaderDispatch(setLoading(true));
    try {
      const params = {
        ...(isPaginated && {per_page: 10, page: currentPage}),
      };
      const response = await axiosPrivateInstance.get('/admins', {params});
      loaderDispatch(setLoading(false));
      return response?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      throw error.response.data.message;
    }
  },
);
export const addSuperAdminData = createAsyncThunk(
  'content/addSuperAdminData',
  async ({payload, intl}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.post('/admins', payload);

      showToast(
        getLocalizedMessage(intl, 'success.super_admin_added_success'),
        'success',
      );
      return response?.data;
    } catch (error) {
      showToast(error.response.data.message);
      throw error.response.data.message;
    }
  },
);

export const updateSuperAdminData = createAsyncThunk(
  'content/updateSuperAdminData',
  async ({id, payload, intl}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.put(`/admins/${id}`, payload);
      showToast(getLocalizedMessage(intl, 'success.super_admin_updated'), 'success');
      return response.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response?.data?.message;
    }
  },
);

export const updateSuperAdminStatus = createAsyncThunk(
  'staff/updateSuperAdminStatus',
  async ({id, intl}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.patch(`/admins/status/${id}`);
      showToast(getLocalizedMessage(intl, 'module.super_admin_status.updated'), 'success');
      return response.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);
