import {createSlice} from '@reduxjs/toolkit';
import {getIslandsList, getLessonStyle} from '../actions/islandActions';

const initialState = {
  islandsList: [],
  lessonStyleData:[]
};

const islandSlice = createSlice({
  name: 'island',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getIslandsList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getIslandsList.fulfilled, (state, action) => {
        state.loading = false;
        state.islandsList = action.payload;
        state.error = null;
      })
      .addCase(getIslandsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getLessonStyle.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLessonStyle.fulfilled, (state, action) => {
        state.loading = false;
        state.lessonStyleData = action.payload;
        state.error = null;
      })
      .addCase(getLessonStyle.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default islandSlice.reducer;
