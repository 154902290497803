import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {showToast} from '../../components/Toast/Toast';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {setLoading} from '../../globalContext/globalLoader/globalLoaderAction';

export const fetchSchoolList = createAsyncThunk(
  'school/fetchSchoolList',
  async (
    {searchTerm, districtId, stateId, currentPage, sortOrder, loaderDispatch},
    thunkAPI,
  ) => {
    loaderDispatch(setLoading(true));
    try {
      const params = {
        per_page: 10,
        page: currentPage,
        sort_order: sortOrder,
        ...(searchTerm && {search: searchTerm}),
        ...(districtId && {district_id: districtId}),
        ...(stateId && {state_id: stateId}),
      };

      const response = await axiosPrivateInstance.get('/schools', {params});
      loaderDispatch(setLoading(false));
      return {
        currentPage: response.data.data.current_page,
        totalPages: response.data.data.last_page,
        data: response.data.data,
      };
    } catch (error) {
      loaderDispatch(setLoading(false));
      throw error.response.data.message;
    }
  },
);

export const fetchIntermediateUnits = createAsyncThunk(
  'school/fetchIntermediateUnits',
  async (stateId, thunkAPI) => {
    try {
      let url = '/intermediate-units';
      if (stateId) {
        url += `?state_id=${stateId}`;
      }
      const response = await axiosPrivateInstance.get(url);
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);

export const fetchDistricts = createAsyncThunk(
  'auth/fetchDistricts',
  async ({stateId, isPaginate, currentPage, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      let url = `/districts`;
      if (stateId) {
        url += `?state_id=${stateId}`;
      }
      let params = {
        is_paginate: isPaginate ? 1 : 0,
      };
      if (isPaginate) {
        params = {
          ...params,
          per_page: 10,
          page: currentPage,
        };
      }
      const response = await axiosPrivateInstance.get(url, {params});
      loaderDispatch(setLoading(false));
      return response.data.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      throw error.response.data.message;
    }
  },
);

export const addSchoolData = createAsyncThunk(
  'school/addSchool',
  async ({schoolData, intl, setStep}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.post('/schools', schoolData);
      showToast(getLocalizedMessage(intl, 'success.added_success'), 'success');
      setStep(step => step + 1);
      return response.data;
    } catch (error) {
      if (error.response.data.code === 'USER_ALREADY_EXIST')
        showToast(getLocalizedMessage(intl, 'label.email_exists'), error);
      else
        showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), error);
      throw error.response.data.message;
    }
  },
);

export const uploadMedia = createAsyncThunk(
  'media/uploadMedia',
  async (formData, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.post('/media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      throw error.message;
    }
  },
);

export const fetchDesignation = createAsyncThunk(
  'school/fetchDesignation',
  async () => {
    try {
      const response = await axiosPrivateInstance.get('/designations');

      return response.data.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);

export const fetchCountries = createAsyncThunk(
  'school/fetchCountries',
  async () => {
    try {
      const response = await axiosPrivateInstance.get('/countries');

      return response.data.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);
export const fetchStates = createAsyncThunk(
  'location/fetchStates',
  async (countryId, thunkAPI) => {
    try {
      let url = '/states';
      if (countryId) {
        url += `?country_id=${countryId}`;
      }
      const response = await axiosPrivateInstance.get(url);
      return response.data.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);

export const fetchSchoolById = createAsyncThunk(
  'school/fetchSchoolById',
  async (schoolId, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.get(`/schools/${schoolId}`);
      return response.data.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);

export const updateSchool = createAsyncThunk(
  'school/updateSchool',
  async ({id, payload, intl}, thunkAPI) => {
    try {
      await axiosPrivateInstance.patch(`/schools/${id}`, payload);
      showToast(getLocalizedMessage(intl, 'success.school_updated'), 'success');
      return payload;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), error);
      throw error.response.data.message;
    }
  },
);

export const updateSchoolStatus = createAsyncThunk(
  'school/updateSchool',
  async ({id, intl}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.patch(
        `/schools/status/${id}`,
      );
      showToast(getLocalizedMessage(intl, 'success.updated'), 'success');
      return response.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const getDistricts = createAsyncThunk(
  'auth/getDistricts',
  async ({stateId}, thunkAPI) => {
    try {
      let url = `/districts`;
      if (stateId) {
        url += `?state_id=${stateId}`;
      }
      const response = await axiosPrivateInstance.get(url);
      return response.data.data;
    } catch (error) {
      console.log('error', error);

      throw error.response.data.message;
    }
  },
);
