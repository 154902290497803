import React, {useState, useMemo, useCallback, useContext} from 'react';
import {useSystemColorMode} from './useSystemColorMode';

export const ColorModeContext = React.createContext();

/**
 * Provides context for the color mode based on config in `theme`
 * Returns the colorMode, colorModes and function to toggle the color mode
 */
export const ColorModeProvider = ({children}) => {
  // Retrieve systemColorModeValue like light or dark or undefined if system not support colorMode feature
  const systemColorModeValue = useSystemColorMode();

  const [colorMode, setColorMode] = useState(systemColorModeValue);

  const toggleColorMode = useCallback(
    value => {
      setColorMode(
        value && typeof value === 'string'
          ? value
          : colorMode === colorModes[0]
          ? colorModes[1]
          : colorModes[0],
      );
    },
    [colorMode, setColorMode],
  );

  const providerValues = useMemo(() => {
    return {
      colorMode,
      toggleColorMode,
      setColorMode,
    };
  }, [colorMode, toggleColorMode, setColorMode]);

  return (
    <ColorModeContext.Provider value={providerValues}>
      {typeof children === 'function' ? children() : children}
    </ColorModeContext.Provider>
  );
};

export const useColorMode = () => {
  const colorMode = useContext(ColorModeContext) || {};
  return colorMode;
};
