export const ROOT_PATH = '/';
export const MANAGE_SCHOOLS_PATH = '/manage-schools';
export const SCHOOL_ADMINS_PATH = '/school-admins';
export const SCHOOL_STAFF_PATH = '/school-staff';
export const STUDENTS_PATH = '/students';
export const NOTIFICATIONS_PATH = '/Notifications';
export const ISLANDS_CONTENT_PATH = '/islands-content';
export const BACKUP_UPDATES_PATH = '/backup-updates';
export const SYSTEM_SETTING_PATH = '/system-setting';
export const LOGIN_PATH = '/login';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const RESET_PASSWORD_PATH = '/reset-password';
export const PROFILE_PATH = '/profile';
export const ADD_SCHOOL_PATH = '/add-school';
export const UPDATE_SCHOOL_PATH = '/update-school/:id';
export const MANAGE_SCHOOL_DISTRICTS_PATH = '/manage-school-districts';
export const MANAGE_STUDENTS_PATH = '/student-list';
export const ADD_STUDENT_PATH = '/add-student';
export const UPDATE_STUDENT_PATH = '/update-student/:id';
export const MANAGE_STAFF_PATH = '/manage-staff';
export const ADD_STAFF_PATH = '/add-staff';
export const UPDATE_STAFF_PATH = '/update-staff/:id';
export const TEMPORARY_LOGIN_PATH = '/student-temp-login';
export const MANAGE_CONTENTS_PATH = '/manage-contents';
export const MANAGE_SECTION_PATH = '/section-list/:id';
export const MANAGE_AVATAR_PATH = '/student/avatar-list';
export const STUDENT_WELCOME_PATH = '/student/welcome';
export const MANAGE_ISLAND_LIST = '/student/island-list';
export const MANAGE_STUDENT_TROPICAL_SECTION__PATH =
  '/student/tropical/section/:id';
export const MANAGE_STUDENT_URBAN_SECTION__PATH = '/student/urban/section/:id';
export const MANAGE_STUDENT_RURAL_SECTION__PATH = '/student/rural/section/:id';
export const MANAGE_STUDENT_FANTASY_SECTION__PATH =
  '/student/fantasy/section/:id';
export const MANAGE_STUDENT_LESSON__PATH = '/student/:section/lesson/:id';
export const MANAGE_LESSONS_PATH = '/manage-lessons';
export const LESSON_STYLE_PATH = '/lesson-style';
export const ADD_LESSON_CONTENT_PATH = '/add-content';
export const VERIFY_EMAIL = '/verify-email';
export const SHOW_HELP = '/help-list';
export const VIEW_PROFILE = '/view-profile';
export const VIEW_LESSON_CONTENTS = '/view-lesson-contents';
export const MANAGE_QUESTIONS_PATH = '/manage-questions';
export const STUDENT_DETAILS_PATH = '/student-details/:id';
export const MANAGE_ANNOUNCEMENTS_PATH = '/announcements';
export const ALL_NOTIFICATIONS_ANNOUNCEMENTS_PATH =
  '/notifications-announcements';
export const MANAGE_SUPER_ADMIN = '/super-admin';
export const REPORTS_PATH = '/reports';
export const STUDENT_PROGRESS_REPORT_PATH = '/student-progress-report';
export const STAFF_STUDENT_ATTACHMENTS_PATH = '/student-attachments';
