import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {setLoading} from '../../globalContext/globalLoader/globalLoaderAction';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {showToast} from '../../components/Toast/Toast';

export const addNote = createAsyncThunk(
  'student/addNote',
  async ({payload, intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.post('/notes', payload);
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'success.noteAdded'), 'success');
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const updateNote = createAsyncThunk(
  'student/updateNote',
  async ({payload, intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.put(
        `/notes/${payload.note_id}`,
        payload,
      );
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'success.noteUpdated'), 'success');
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const getNotes = createAsyncThunk(
  'student/quesAns',
  async ({lessonId, search}, thunkAPI) => {
    try {
      const params = {
        lesson_id: lessonId,
        search: search,
      };
      const response = await axiosPrivateInstance.get('/notes', {
        params,
      });
      return response?.data?.data?.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);

export const deleteNote = createAsyncThunk(
  'student/deleteNote',
  async ({noteId, intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.delete(`/notes/${noteId}`);
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'success.noteDeleted'), 'success');
      return {noteId};
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);
