import React from 'react';
import {useIntl} from 'react-intl';
import InputLabel from '../../components/InputLabel/InputLabel';
import {getTimeAgo} from '../../utilityFunction/helper';
import {commonStyle} from '../../Style/commonStyle';
import './AddStaffAnswerView.scss';

export default function AddStaffAnswerView({
  ques,
  onBack,
  onChange,
  onBlur,
  name,
  value,
  error,
  onSubmitAnswer,
}) {
  const intl = useIntl();
  return (
    <div className="addAnswerStaffWrapper">
      <button onClick={onBack} style={commonStyle.backToAllQues}>
        {intl.formatMessage({id: 'label.backToQuestions'})}
      </button>
      <div className="addStaff-quesAnsWrapper">
        <div className="addAnsStaff-details">
          <div className="submitQues-AnsWrapper">
            <div className="addAns-quesText">{ques?.question}</div>

            <div className="quesAns-actionsWrapper">
              <div className="askBy-container">
                <div className="askByText">
                  {intl.formatMessage({id: 'label.askedBy'})}
                </div>
                <div className="askByPerson">{`${ques?.first_name} ${ques?.last_name}`}</div>
              </div>
              <div className="askBy-container">
                <img className="pinDot" src="/images/ellipsePinnedDot.svg" />
                <div className="askMonths">
                  <div className="askMonthsText">{getTimeAgo(ques?.date)}</div>
                </div>
              </div>
            </div>

            <div className="inputAnswer-wrapper">
              <div>
                <InputLabel
                  label={intl.formatMessage({id: 'label.writeAns'})}
                  onChange={onChange}
                  onBlur={onBlur}
                  name={name}
                  value={value}
                  error={error}
                />
              </div>
              <button
                onClick={onSubmitAnswer(ques)}
                style={commonStyle.submitAnsBtn}>
                {intl.formatMessage({id: 'label.submitAnswer'})}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
