import React from 'react';
import SelectDropdown from '../../../components/SelectDropdown/SelectDropdown';
import './DashboardTabsFilterSection.scss';

export default function DashboardTabsFilterSection({
  ddOptionsFirst,
  ddOptionsSecond,
  valueOne,
  valueTwo,
  onSelectFirst,
  onSelectSecond,
  firstPlaceholder,
  secondPlaceholder,
}) {
  return (
    <div className="d-flex dashboard-searchFilterWrapper">
      <div className="d-flex filter-wrapper">
        <div className="filter-firstTab">
          <SelectDropdown
            options={ddOptionsFirst || []}
            value={valueOne}
            onChange={onSelectFirst}
            placeholder={firstPlaceholder}
            disabled={ddOptionsFirst?.length === 0}
            selectClassName="c-filter"
            className="w-100"
          />
        </div>
        <div className="divider"></div>
        <div className="filter-lastTab">
          <SelectDropdown
            options={ddOptionsSecond || []}
            value={valueTwo}
            onChange={onSelectSecond}
            placeholder={secondPlaceholder}
            disabled={ddOptionsSecond?.length === 0}
            selectClassName="c-filter"
            className="w-100"
          />
        </div>
      </div>
    </div>
  );
}
