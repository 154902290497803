import React from 'react';
import './StudentIslandCard.scss';
import StarProgressButton from '../StarProgressButton/StarProgressButton';

export default function StudentIslandCard({
  islandOptions,
  onIslandClick,
  onIslandMouseEnter,
  onIslandMouseLeave,
  disabled,
}) {
  const calculateProgress = (completed, total) => {
    return total === 0 ? 0 : (completed / total) * 100;
  };

  return (
    <div className="container">
      {islandOptions?.length > 0 && (
        <div className="studentIslandContainer row">
          {islandOptions?.map(
            (island, index) =>
              island?.module_icon?.path && (
                <div
                  className={`studentIslandCardDetailsWrapper col-md-3 ${
                    index % 2 === 0 ? 'odd' : 'even'
                  } island${index + 1} ${
                    disabled[index] ? 'cursor' : 'disabled'
                  }`}
                  key={island?.id}
                  onMouseEnter={() => onIslandMouseEnter(island?.name)}
                  onMouseLeave={onIslandMouseLeave}
                  onClick={() => {
                    if (disabled[index]) {
                      onIslandClick(island?.id, island?.slug);
                    }
                  }}>
                  <div className="detailsContainer">
                    <div className="detailsWrapper">
                      <div className="clickableImageWrapper">
                        {island && (
                          <img
                            className="fix-frame"
                            src={island?.module_icon?.path}
                            alt="island icon"
                          />
                        )}
                        {island?.completed_sections_count === 0 ? (
                          <img
                            className="islandIcon w-20 active-play-button"
                            src="/images/playButon.svg"
                            alt="play button"
                          />
                        ) : (
                          <StarProgressButton
                            className="islandIcon"
                            progress={calculateProgress(
                              island?.completed_sections_count,
                              island?.no_of_sections,
                            )}
                            value={index + 1}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ),
          )}
        </div>
      )}
    </div>
  );
}
