import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import './BackdropGlobalLoader.scss';

export default function BackdropGlobalLoader({open}) {
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: '1403',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(3px)',
      }}
      open={open}>
      <div style={{textAlign: 'center'}}>
        <img
          src={'/images/logo.png'}
          alt="Gradrock Logo"
          style={{width: 100, height: 100, margin: '20px'}}
        />
        <Box className="dot-flashing-container">
          <Box className="dot-flashing" />
          <Box className="dot-flashing" />
          <Box className="dot-flashing" />
        </Box>
      </div>
    </Backdrop>
  );
}
