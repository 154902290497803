import React from 'react';
import SectionWrapper from '../SectionWrapper.js';
import "./TropicalSection.scss"

function TropicalSection() {
  return (
    <SectionWrapper
      backgroundImg="/images/sectionFrame1.svg"
      sectionWrapperClassName="student-tropical-section-wrapper"
      helpBackgroundImg="/images/islandHelp.svg"
      helpBackgroundImgClass="islandHelp"
    />
  );
}

export default TropicalSection;
