import React, {useEffect, useState} from 'react';
import './HorizontalScrollButton.scss';

const HorizontalScroll = ({children}) => {
  const [leftArrowDisabled, setLeftArrowDisabled] = useState(true);
  const [rightArrowDisabled, setRightArrowDisabled] = useState(true);

  const handleHorizontalScroll = (distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      document.body.scrollLeft += step;
      scrollAmount += Math.abs(step);
      const maxScrollLeft =
        document.body.scrollWidth - document.body.clientWidth;
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (document.body.scrollLeft === 0) {
        setLeftArrowDisabled(true);
      } else if (Math.ceil(document.body.scrollLeft) >= maxScrollLeft) {
        setRightArrowDisabled(true);
      } else {
        setLeftArrowDisabled(false);
        setRightArrowDisabled(false);
      }
    }, 10);
  };

  useEffect(() => {
    const checkHorizontalScroll = () => {
      if (document.body.scrollWidth > document.body.clientWidth) {
        console.log('scrollable');
        clearInterval(intervalId);
      }
      const isScrollable =
        document.body.scrollWidth > document.body.clientWidth;
      setRightArrowDisabled(isScrollable ? false : true);
    };

    const intervalId = setInterval(() => {
      checkHorizontalScroll();
    }, 1000);

    const handleWheel = event => {
      if (event.deltaY === 0 && event.deltaX !== 0) {
        handleHorizontalScroll(Math.abs(event.deltaX), event.deltaX);
      }
    };

    window.addEventListener('wheel', handleWheel);

    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, [document.body.scrollWidth, document.body.clientWidth]);

  return (
    <div className="horizontal-scroll-wrapper">
      <button
        onClick={() => handleHorizontalScroll(100, -100)}
        disabled={leftArrowDisabled}>
        <img
          className="cursor arrow-left"
          src="/images/arrow-left-alt.png"
          alt="Scroll Left Icon"
        />
      </button>
      <div className="horizontal-scroll-content">{children}</div>
      <button
        onClick={() => handleHorizontalScroll(100, 100)}
        disabled={rightArrowDisabled}>
        <img
          className="cursor arrow-right"
          src="/images/arrow-right-alt.png"
          alt="Scroll Right Icon"
        />
      </button>
    </div>
  );
};

export default HorizontalScroll;
