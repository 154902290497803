import React, {useContext, useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import './StaffStudentAttachmentsTabView.scss';
import {getAllAttachments} from '../../../store/actions/fileActions';
import {GlobalLoaderContext} from '../../../globalContext/globalLoader/globalLoaderProvider';

export default function StaffStudentAttachmentsTabView({lesson}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    const fetchAttachments = async () => {
      try {
        const attachments = await dispatch(
          getAllAttachments({
            intl,
            loaderDispatch,
          }),
        ).unwrap();
        setUploadedFiles(attachments);
      } catch (error) {
        console.error('Failed to fetch attachments', error);
      }
    };

    fetchAttachments();
  }, [dispatch, lesson, intl, loaderDispatch]);

  const extractAttachment = url => {
    const match = url.match(/\/attachment\/(.+)/);
    return match ? match[1] : null;
  };

  const filteredFiles = uploadedFiles.filter(
    file => file.lesson_id === lesson.id,
  );

  return (
    <div>
      <div className="fw-bold mb-2">
        {intl.formatMessage({id: 'label.attachmentsUploadedByStudents'})}
      </div>
      {filteredFiles.length > 0 ? (
        <ol className="ps-3 attachment-container">
          {filteredFiles.map(file => (
            <li key={file.attachment_id} className="mb-3">
              <div className="attachment-title mb-1">
                <a
                  href={file.attachment_path}
                  className="link-primary attachment-link fw-bold"
                  target="_blank"
                  rel="noopener noreferrer"
                  download={extractAttachment(file.attachment_path)}>
                  {extractAttachment(file.attachment_path)}
                </a>
              </div>
              <div className="attachment-footer">
                <span className="fw-bold">
                  {intl.formatMessage({id: 'label.uploadedBy'})}
                </span>
                : {file.student}
              </div>
            </li>
          ))}
        </ol>
      ) : (
        <div>{intl.formatMessage({id: 'label.noUploadedFiles'})}</div>
      )}
    </div>
  );
}
