import React from 'react';
import CustomModalStudent from '../../../components/CustomModalStudent/CustomModalStudent';
import {useIntl} from 'react-intl';
import CustomTabs from '../../../components/CustomTabs/CustomTabs';
import LessonTab from '../../Student/LessonTab';
import './StudentBookmark.scss';

function StudentBookmark({showBookmark, closeModal}) {
  const intl = useIntl();

  const handleCloseModal = () => {
    closeModal();
  };

  const dialogHeaderContent = (
    <div className="help-header-wrapper">
      <div onClick={handleCloseModal} className="back-icon">
        <img src="/images/back.svg" alt="back" />
      </div>
      <h2 className="help-header text-shadow">
        {intl.formatMessage({id: 'label.bookmarks'})}
      </h2>
    </div>
  );

  const tabLabels = ['Lessons', 'Resources'];

  const tabComponents = [
    {
      component: LessonTab,
      props: {
        is_content: false,
        showBookmark: showBookmark,
        closeModal: closeModal,
      },
    },
    {
      component: LessonTab,
      props: {
        is_content: true,
        showBookmark: showBookmark,
        closeModal: closeModal,
      },
    },
  ];

  return (
    <div className="student-setting-modal-outer">
      <CustomModalStudent
        open={showBookmark}
        handleClose={handleCloseModal}
        backgroundBlue={true}
        modalWidth={'90%'}
        modalHeight={'97%'}
        marginTop={'1%'}
        showHeader
        dialogHeader={dialogHeaderContent}>
        <div className="student-setting-modal-wrapper">
          <div className="container-fluid">
            <div className="row">
              <CustomTabs tabLabels={tabLabels} tabComponents={tabComponents} tabsClassname="bookmark-tabs"/>
            </div>
          </div>
        </div>
      </CustomModalStudent>
    </div>
  );
}

export default StudentBookmark;
