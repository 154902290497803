import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { commonStyle } from '../../Style/commonStyle';
import { DialogActions } from '@mui/material';
import './CustomModalStudent.scss';

export default function CustomModalStudent({
  open,
  fullWidth,
  handleClose,
  showHeader,
  dialogHeader,
  modalWidth,
  showActionButtons,
  dialogActionButtons,
  children,
  contentStyle,
  backgroundBlue = false,
  modalHeight,
  marginTop,
}) {
  return (
    <div className="dialogWrapper">
      <Dialog
        fullWidth={fullWidth}
        maxWidth={false}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: modalWidth || 'auto',
            minHeight: '200px',
            padding: '10px',
            border: '3px solid',
            borderRadius: '16px',
            borderImageSlice: 1,
            color: '#5C6DE0',
            backgroundColor: '#5C6DE0',
            position: 'relative',
            overflow: 'hidden',
            maxHeight: modalHeight || '85%',
            margin:'20px',
            marginTop: marginTop || '-3%',
            height: modalHeight || 'auto',
          },
        }}>
        {showHeader && <div>{dialogHeader}</div>}

        <DialogContent
          className={backgroundBlue ? 'student_blue_modal' : ''}
          sx={{
            ...commonStyle.studentDialogueContentStyle,
            ...contentStyle,
          }}>
            {backgroundBlue && <div className='student_blue_modal_inner'>{children}</div>}
            {!backgroundBlue && children}
          
        </DialogContent>

        {showActionButtons && (
          <DialogActions>
            <div className="dialogActionsWrapper">{dialogActionButtons}</div>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
