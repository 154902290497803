import React from 'react';
import PropTypes from 'prop-types';
import './TokenProgressBar.scss';

const TokenProgressBar = ({ value, total, dummyProgress }) => {
  
  const progress = dummyProgress ? 75 : (value ? Math.min((value / total) * 100, 100) : 0);

  return (
    <div className='position-relative token-progress-wrapper'>
      <div className="token-progress-inner">
        <div
          className="token-progress-bar"
          style={{ width: `${progress}%`, backgroundColor: '#ffc107' }}>
          <div className="progress-gradient"></div>
          <div className="progress-bar" />
          {value && <div className="token-info">{`${value}`}</div>}
        </div>
      </div>
      {value >= 0 && <div className="token-progress-bar-s" style={{ left: `${progress}%` }}>
        <img src="/images/tokenIcon.svg" />
      </div> }
    </div>
  );
};

TokenProgressBar.propTypes = {
  value: PropTypes.number,
  total: PropTypes.number.isRequired,
  dummyProgress: PropTypes.bool,
};

export default TokenProgressBar;
