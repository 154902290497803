import React from 'react';
import {useNavigate} from 'react-router';
import {useIntl} from 'react-intl';
import CustomModalStudent from '../CustomModalStudent/CustomModalStudent';
import NotificationsRightColLayout from '../NotificationsRightColLayout/NotificationsRightColLayout';
import {MANAGE_STUDENT_LESSON__PATH} from '../../constants/routePaths';
import './Notification.scss';

function Notification({
  notificationOpen,
  setNotificationOpen,
  isViewAllCase,
  setShowDashboard,
}) {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleCloseNotification = () => {
    setNotificationOpen(false);
  };

  const handleCancel = () => {
    handleCloseNotification();
  };

  const dialogHeaderContent = (
    <div className="help-header-wrapper">
      <div onClick={handleCancel} className="back-icon">
        <img src="/images/back.svg" alt="back" />
      </div>
      <h2 className="help-header text-shadow">
        {intl.formatMessage({id: 'label.tabs.notifyAndAnnouncements'})}
      </h2>
    </div>
  );

  function redirectHandler(notification) {
    setNotificationOpen(false);
    if (isViewAllCase) setShowDashboard(false);
    const notificationLesson = {
      id: notification?.data?.lesson_id,
      status: 'published',
    };
    const cleanedSlug = notification?.data?.module_element_slug.replace(
      /_/g,
      '',
    );
    navigate(
      `${MANAGE_STUDENT_LESSON__PATH.replace(
        ':id',
        notification?.data?.section_id,
      ).replace(':section', cleanedSlug)}`,
      {
        state: {
          slug: notification?.data?.module_slug,
          isNotificationRedirect: true,
          notificationLesson: notificationLesson,
        },
      },
    );
  }

  return (
    <div className="notification-wrapper">
      {notificationOpen && (
        <CustomModalStudent
          open={notificationOpen}
          handleClose={handleCloseNotification}
          backgroundBlue={true}
          modalWidth={'90%'}
          modalHeight={'97%'}
          marginTop={'1%'}
          showHeader
          dialogHeader={dialogHeaderContent}>
          <NotificationsRightColLayout
            NotifyClassName="student-notification"
            desc={false}
            btnText={intl.formatMessage({id: 'label.go_to_lesson'})}
            notifyIcon={'/images/school.svg'}
            redirectHandler={redirectHandler}
            isPagination={true}
            tabsClassname="student-tabs"
            annoucementIcon={'/images/school.svg'}
          />
        </CustomModalStudent>
      )}
    </div>
  );
}

export default Notification;
