import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import CustomTable from '../../components/CustomTable/CustomTable';
import NotificationsRightColLayout from '../../components/NotificationsRightColLayout/NotificationsRightColLayout';
import DashboardStatisticsCardView from '../../components/DashboardStatisticsCardView/DashboardStatisticsCardView';
import DashboardTabsFilterSection from './DashboardTabsFilterSection/DashboardTabsFilterSection';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';
import {
  getDashboardStats,
  getSuperAdminDashboardDistricts,
} from '../../store/actions/dashboardActions';
import {fetchCountriesData, fetchStatesData} from '../../services/auth-service';
import {
  ALL_NOTIFICATIONS_ANNOUNCEMENTS_PATH,
  MANAGE_SCHOOLS_PATH,
  MANAGE_SCHOOL_DISTRICTS_PATH,
  UPDATE_SCHOOL_PATH,
} from '../../constants/routePaths';

export default function SuperAdminDashboardView() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const {dashboardDistrictsData, dashboardStatsInfo} = useSelector(
    state => state?.dashboard,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [countries, setCountries] = useState([]);
  const [countryId, setCountryId] = useState('');
  const [states, setStates] = useState([]);
  const [stateId, setStateId] = useState('');

  useEffect(() => {
    fetchDashboardStats();
    fetchCountriesData(dispatch, setCountries);
  }, []);

  useEffect(() => {
    fetchSuperAdminDashboardDistrictsData();
  }, [currentPage, countryId, stateId]);

  useEffect(() => {
    if (countryId) {
      fetchStatesData(dispatch, countryId, setStates);
    }
  }, [countryId]);

  function fetchDashboardStats() {
    dispatch(getDashboardStats({loaderDispatch}));
  }

  function fetchSuperAdminDashboardDistrictsData() {
    dispatch(
      getSuperAdminDashboardDistricts({
        loaderDispatch,
        currentPage,
        countryId,
        stateId,
      }),
    );
  }

  function onPageChange(page) {
    setCurrentPage(page);
  }

  function onChangeCountry(e) {
    setStateId('');
    setStates([]);
    setCountryId(e.target.value);
  }

  function onChangeState(e) {
    setStateId(e.target.value);
  }

  function redirectHandler(notification) {
    switch (notification?.type) {
      case 'App\\Notifications\\SuperAdmin\\AddDistrictNotification':
        navigate(MANAGE_SCHOOL_DISTRICTS_PATH);
        break;
      case 'App\\Notifications\\SuperAdmin\\AddSchoolNotification':
        navigate(MANAGE_SCHOOLS_PATH);
        break;
      case 'App\\Notifications\\SuperAdmin\\SchoolStatusChangeNotification':
      case 'App\\Notifications\\SuperAdmin\\SchoolLicenseExpiryNotification':
        navigate(
          `${UPDATE_SCHOOL_PATH.replace(':id', notification?.data?.school_id)}`,
        );
      default:
        break;
    }
  }

  function onViewAllHandler() {
    navigate(ALL_NOTIFICATIONS_ANNOUNCEMENTS_PATH);
  }

  const TABLE_HEAD = [
    {
      id: 'schoolDistrictName',
      label: intl.formatMessage({id: 'label.schoolDistrictName'}),
      alignRight: false,
      width: '25%',
      renderColumn: schoolDistrict => {
        return (
          <div key={schoolDistrict?.id} className="s_name_address d-flex">
            <div className="s_name">{schoolDistrict?.name}</div>
          </div>
        );
      },
    },
    {
      id: 'schoolCount',
      label: intl.formatMessage({id: 'label.noSchool'}),
      alignRight: false,
      width: '25%',
      dataKey: 'schools_count',
    },
    {
      id: 'staffCount',
      label: intl.formatMessage({id: 'label.noStaff'}),
      alignRight: false,
      width: '25%',
      dataKey: 'total_staff',
    },
    {
      id: 'studentCount',
      label: intl.formatMessage({id: 'label.noStudents'}),
      alignRight: false,
      width: '25%',
      dataKey: 'total_students',
    },
  ];

  return (
    <div className="dashboard">
      <div className="left-column">
        <div className="cards-row">
          <DashboardStatisticsCardView
            title={intl.formatMessage({id: 'label.heading.schoolDistricts'})}
            icon={'/images/schoolDistrictStatsIcon.svg'}
            count={dashboardStatsInfo?.total_districts}
          />
          <DashboardStatisticsCardView
            title={intl.formatMessage({id: 'label.heading.schools'})}
            icon={'/images/schoolStatsIcon.svg'}
            count={dashboardStatsInfo?.total_schools}
          />
          <DashboardStatisticsCardView
            title={intl.formatMessage({id: 'label.heading.staffs'})}
            icon={'/images/teacherStatsIcon.svg'}
            count={dashboardStatsInfo?.total_staff}
          />
          <DashboardStatisticsCardView
            title={intl.formatMessage({id: 'label.heading.students'})}
            icon={'/images/studentStatsIcon.svg'}
            count={dashboardStatsInfo?.total_students}
          />
        </div>
        <div className="content-row">
          <DashboardTabsFilterSection
            ddOptionsFirst={countries}
            ddOptionsSecond={states}
            firstPlaceholder={intl.formatMessage({
              id: 'addDistrict.addCountryName',
            })}
            secondPlaceholder={intl.formatMessage({
              id: 'addDistrict.addStateName',
            })}
            onSelectFirst={onChangeCountry}
            onSelectSecond={onChangeState}
            valueOne={countryId}
            valueTwo={stateId}
          />

          <CustomTable
            tableHead={TABLE_HEAD}
            tableData={dashboardDistrictsData?.data}
            currentPage={currentPage}
            onPageChange={onPageChange}
            totalPages={dashboardDistrictsData?.last_page}
            totalCount={dashboardDistrictsData?.total}
            isDashboardTable
            dashboardTableClass="dashboard-tableContainer"
          />
        </div>
      </div>

      <div className="right-column">
        <div className="notifications-list">
          <NotificationsRightColLayout
            desc={true}
            notifyIcon={'/images/book.svg'}
            redirectHandler={redirectHandler}
            viewAll={onViewAllHandler}
            isPagination={false}
          />
        </div>
      </div>
    </div>
  );
}
