import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {PiDotsThreeVertical} from 'react-icons/pi';
import {Typography} from '@mui/material';
import CustomTable from '../../components/CustomTable/CustomTable';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';
import DeactivateModal from '../../components/DeactivateModal/DeactivateModal';
import ImportBulkModal from '../../components/ImportBulkModal/ImportBulkModal';
import TabsFilterSection from '../../components/TabsFilterSection/TabsFilterSection';
import {exportStudentProgressReport} from '../../store/actions/progressReportActions';
import {
  fetchStaffList,
  updateStaffStatus,
} from '../../store/actions/staffActions';
import {
  fetchSchoolList,
  getDistricts,
  fetchStates,
} from '../../store/actions/schoolActions';
import {
  uploadTemplate,
  downloadTemplate,
} from '../../store/actions/bulkImportActions';
import {
  checkIsSuperAdmin,
  concatenateStrings,
  getLocalizedMessage,
  getStaffTabsOptions,
  getStatusText,
} from '../../utilityFunction/helper';
import {STAFF_TEMPLATE_TYPE} from '../../utilityFunction/constants';
import {useNavigate} from '../../routes/index.js';
import {ADD_STAFF_PATH, UPDATE_STAFF_PATH} from '../../constants/routePaths.js';
import useUserRole from '../../hooks/useUserRole';
import UserRoles from '../../constants/userRoles';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';

export default function ManageStaffListing() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const {isAuthenticated} = useSelector(state => state?.auth);
  const schoolList = useSelector(
    state => state?.school?.schoolList?.data?.data?.data,
  );
  const {staffsList} = useSelector(state => state?.staff);
  const userRole = useUserRole();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [showImportModal, setShowImportModal] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const [selectedState, setSelectedState] = useState('');
  const [selectedSchoolDistrict, setSelectedSchoolDistrict] = useState('');
  const [schoolId, setSchoolId] = useState(null);
  const schoolIdFromAuth = useSelector(
    state => state?.auth?.data?.data?.data?.user?.school?.id,
  );
  const studentList = useSelector(
    state => state.progressReport?.progressReport?.listdata,
  );
  const [studentData, setStudentData] = useState(studentList || []);

  useEffect(() => {
    const getData = async () => {
      await dispatch(fetchStates());
      await dispatch(getDistricts({}));
    };
    getData();
  }, []);

  useEffect(() => {
    setSchoolId(schoolList?.[0]?.id);
  }, [schoolList]);

  useEffect(() => {
    if (isAuthenticated && isSuperAdminRole) {
      fetchSchoolData();
    }
  }, [
    isAuthenticated,
    searchTerm,
    selectedSchoolDistrict,
    selectedState,
    currentPage,
    sortOrder,
  ]);

  useEffect(() => {
    setSelectedSchoolDistrict('');
  }, [selectedState]);

  useEffect(() => {
    if (isAuthenticated && schoolId) {
      fetchProgressReport(schoolId);
    } else {
      fetchProgressReport(schoolIdFromAuth);
    }
  }, [schoolId]);

  const fetchSchoolData = async () => {
    try {
      await dispatch(
        fetchSchoolList({
          searchTerm,
          districtId: selectedSchoolDistrict,
          stateId: selectedState,
          currentPage,
          sortOrder,
          loaderDispatch,
        }),
      );
    } catch (error) {
      console.log('Error fetching school data:', error);
    }
  };

  const exportProgressReport = async () => {
    const res = await dispatch(
      exportStudentProgressReport({
        schoolId,
        loaderDispatch,
      }),
    );
    const downloadLink = document.createElement('a');
    downloadLink.href = res.payload.download_url;
    downloadLink.download = 'student-progress-report.xlsx';
    downloadLink.click();
  };

  const fetchProgressReport = async id => {
    if (id) {
      const res = await dispatch(
        exportStudentProgressReport({
          schoolId: id,
          loaderDispatch,
        }),
      );

      setStudentData(res?.payload?.listdata);
    }
  };

  useEffect(() => {
    if (isAuthenticated && showSchoolDropdown()) {
      fetchSchoolData();
    }
  }, [isAuthenticated]);

  const showSchoolDropdown = () => {
    const rolePermissions = [UserRoles.SUPER_ADMIN];
    return rolePermissions?.includes(userRole);
  };

  const showAddImportBtns = () => {
    const rolePermissions = [UserRoles.SCHOOL_ADMIN];
    return rolePermissions?.includes(userRole);
  };

  const fetchStaffData = async () => {
    await dispatch(
      fetchStaffList({
        searchTerm,
        currentPage,
        sortOrder,
        selectedRole,
        schoolId,
        loaderDispatch,
      }),
    );
  };

  const onSortChange = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);

    const sortedStudentData = [...studentData].sort((a, b) => {
      if (sortOrder === 'asc') {
        if (a.first_name < b.first_name) return -1;
        if (a.first_name > b.first_name) return 1;
      } else {
        if (a.first_name > b.first_name) return -1;
        if (a.first_name < b.first_name) return 1;
      }
      return 0;
    });

    setStudentData(sortedStudentData);
  };

  const onPageChange = page => {
    setCurrentPage(page);
  };

  const onSearch = e => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);

    const filteredStudentData =
      e.target.value === ''
        ? studentList
        : studentData?.filter(student => {
            const fullName =
              `${student.first_name} ${student.last_name}`.toLowerCase();
            return fullName.includes(e.target.value.toLowerCase());
          });

    setStudentData(filteredStudentData);
  };

  const onTabChange = selectTabId => {
    setSelectedRole(selectTabId);
    setCurrentPage(1);
  };

  const templateDownloadHandler = async () => {
    dispatch(
      downloadTemplate({
        templateType: STAFF_TEMPLATE_TYPE,
        intl,
        templateName: 'Staff_Template.xlsx',
        loaderDispatch,
      }),
    );
    setShowImportModal(false);
  };

  const fileImportHandler = async file => {
    dispatch(uploadTemplate({file, url: '/bulk-upload-staff', intl})).then(
      () => {
        fetchStaffData();
        setShowImportModal(false);
      },
    );
  };

  const handleDeactivateConfirm = async () => {
    if (selectedStaff?.id) {
      setOpenDeactivateModal(false);
      dispatch(updateStaffStatus({id: selectedStaff?.id, intl})).then(() => {
        setSelectedStaff(null);
        fetchStaffData();
      });
    }
  };

  const handleAvatarClick = staff => {
    if (selectedStaff?.id === staff?.id) setSelectedStaff(null);
    else setSelectedStaff(staff);
  };
  const handleAddStaff = () => {
    navigate(ADD_STAFF_PATH);
  };

  const viewProfile = id => {
    navigate(`${UPDATE_STAFF_PATH.replace(':id', id)}`);
  };
  const onSelectChange = value => {
    setSchoolId(value);
  };

  const isSuperAdminRole = checkIsSuperAdmin(userRole);

  const TABLE_HEAD = [
    {
      id: 'studentFirstName',
      label: intl.formatMessage({id: 'label.first_name'}),
      alignRight: false,
      dataKey: 'first_name',
    },
    {
      id: 'studentLastName',
      label: intl.formatMessage({id: 'label.last_name'}),
      alignRight: false,
      dataKey: 'last_name',
    },
    {
      id: 'studentEmailAddress',
      label: intl.formatMessage({id: 'label.email_address'}),
      alignRight: false,
      dataKey: 'email',
    },
    {
      id: 'studentSchoolName',
      label: intl.formatMessage({id: 'label.school_name'}),
      alignRight: false,
      dataKey: 'school_name',
    },
    {
      id: 'studentCoinsEarned',
      label: intl.formatMessage({id: 'label.coins_earned'}),
      alignRight: false,
      dataKey: 'coins_earned',
    },
    {
      id: 'studentTropicalModuleCount',
      label: intl.formatMessage({id: 'label.tropical_module_count'}),
      alignRight: false,
      dataKey: 'tropical_module_count',
    },
    {
      id: 'studentUrbanModuleCount',
      label: intl.formatMessage({id: 'label.urban_module_count'}),
      alignRight: false,
      dataKey: 'urban_module_count',
    },
    {
      id: 'studentRuralModuleCount',
      label: intl.formatMessage({id: 'label.rural_module_count'}),
      alignRight: false,
      dataKey: 'rural_module_count',
    },
    {
      id: 'studentFantasyModuleCount',
      label: intl.formatMessage({id: 'label.fantasy_module_count'}),
      alignRight: false,
      dataKey: 'fantasy_module_count',
    },
    {
      id: 'studentTotalModulesCompleted',
      label: intl.formatMessage({id: 'label.total_modules_completed'}),
      alignRight: false,
      dataKey: 'total_modules_completed',
    },
  ];

  return (
    <div>
      <div className="d-flex student-detail-wrapper">
        <div className="school-label">
          {intl.formatMessage({id: 'label.student_progress_report'})}
        </div>
        <div className="add-import-btn">
          <div className="import-btn">
            <button onClick={exportProgressReport}>
              {intl.formatMessage({id: 'label.exportReport'})}
            </button>
          </div>
        </div>
      </div>

      {/* <TabsFilterSection
        onSearchChange={onSearch}
        handleSort={onSortChange}
        sortOrder={sortOrder}
        searchPlaceholder={intl.formatMessage({id: 'label.search_name_class'})}
        dropdownOptions={schoolList}
      /> */}

      <TabsFilterSection
        onSearchChange={onSearch}
        handleSort={onSortChange}
        sortOrder={sortOrder}
        searchPlaceholder={intl.formatMessage({id: 'label.search_name_class'})}
        showSelect={showSchoolDropdown()}
        dropdownOptions={schoolList}
        dropdownPlaceholder={intl.formatMessage({
          id: 'label.select.school',
        })}
        handleSelectChange={onSelectChange}
        selectedSchoolId={schoolId}
      />

      <CustomTable
        tableHead={TABLE_HEAD}
        tableData={studentData}
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalPages={staffsList?.last_page}
        totalCount={staffsList?.total}
      />

      {showImportModal && (
        <ImportBulkModal
          open={showImportModal}
          handleClose={() => setShowImportModal(false)}
          modalTitle={'Staff Bulk Upload'}
          onDownloadTemplate={templateDownloadHandler}
          onImportFile={fileImportHandler}
        />
      )}

      {openDeactivateModal && (
        <DeactivateModal
          open={openDeactivateModal}
          setOpen={setOpenDeactivateModal}
          handleDeactivate={handleDeactivateConfirm}
          confirmText={getStatusText(selectedStaff?.is_active, intl)}
          modalContentText={
            !selectedStaff?.is_active
              ? getLocalizedMessage(intl, 'staff.statusModal.activate', {
                  staffMember: selectedStaff?.staff_types,
                })
              : getLocalizedMessage(intl, 'staff.statusModal.deactivate', {
                  staffMember: selectedStaff?.staff_types,
                })
          }
        />
      )}
    </div>
  );
}
