import {createSlice} from '@reduxjs/toolkit';
import {
  fetchSchoolList,
  fetchIntermediateUnits,
  fetchDistricts,
  uploadMedia,
  fetchDesignation,
  fetchCountries,
  fetchStates,
  fetchSchoolById,
  getDistricts
} from '../actions/schoolActions';

const initialState = {
  loading: false,
  error: null,
  schoolList: [],
  districtsList: [],
  intermediateUnits: [],
  uploadMedia: {},
  DesignationList: [],
  CountriesList: [],
  StatesList: [],
  schoolById: null,
  districtData: []
};

const schoolSlice = createSlice({
  name: 'school',
  initialState,
  reducers: {
    // ther reducers here...
  },
  extraReducers: builder => {
    builder
      .addCase(fetchSchoolList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSchoolList.fulfilled, (state, action) => {
        state.loading = false;
        state.schoolList = action.payload;
        state.error = null;
      })
      .addCase(fetchSchoolList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchIntermediateUnits.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchIntermediateUnits.fulfilled, (state, action) => {
        state.loading = false;
        state.intermediateUnits = action.payload;
        state.error = null;
      })
      .addCase(fetchIntermediateUnits.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchDistricts.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDistricts.fulfilled, (state, action) => {
        state.loading = false;
        state.districtsList = action.payload;
        state.error = null;
      })
      .addCase(fetchDistricts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(uploadMedia.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(uploadMedia.fulfilled, (state, action) => {
        state.loading = false;
        state.uploadMedia = action.payload;
        state.error = null;
      })
      .addCase(uploadMedia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchDesignation.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDesignation.fulfilled, (state, action) => {
        state.loading = false;
        state.DesignationList = action.payload;
        state.error = null;
      })
      .addCase(fetchDesignation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCountries.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCountries.fulfilled, (state, action) => {
        state.loading = false;
        state.CountriesList = action.payload;
        state.error = null;
      })
      .addCase(fetchCountries.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchStates.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStates.fulfilled, (state, action) => {
        state.loading = false;
        state.StatesList = action.payload;
        state.error = null;
      })
      .addCase(fetchStates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSchoolById.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSchoolById.fulfilled, (state, action) => {
        state.loading = false;
        state.schoolById = action.payload;
        state.error = null;
      })
      .addCase(fetchSchoolById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getDistricts.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDistricts.fulfilled, (state, action) => {
        state.loading = false;
        state.districtData = action.payload;
        state.error = null;
      })
      .addCase(getDistricts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default schoolSlice.reducer;
