import {createSlice} from '@reduxjs/toolkit';
import {
  fetchStudentList,
  fetchGradeList,
  fetchSectionList,
  fetchCounsellors,
  fetchTeachers,
} from '../actions/studentActions';

const initialState = {
  studentList: [],
  gradeList: [],
  sectionList: [],
  counsellorsList: [],
  teacherList: [],
};

const studentSlice = createSlice({
  name: 'student',
  initialState,
  reducers: {
    // Your other reducers here...
  },
  extraReducers: builder => {
    builder
      .addCase(fetchStudentList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStudentList.fulfilled, (state, action) => {
        state.loading = false;
        state.studentList = action.payload;
        state.error = null;
      })
      .addCase(fetchStudentList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchGradeList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchGradeList.fulfilled, (state, action) => {
        state.loading = false;
        state.gradeList = action.payload;
        state.error = null;
      })
      .addCase(fetchGradeList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSectionList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSectionList.fulfilled, (state, action) => {
        state.loading = false;
        state.sectionList = action.payload;
        state.error = null;
      })
      .addCase(fetchSectionList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCounsellors.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCounsellors.fulfilled, (state, action) => {
        state.loading = false;
        state.counsellorsList = action.payload;
        state.error = null;
      })
      .addCase(fetchCounsellors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchTeachers.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTeachers.fulfilled, (state, action) => {
        state.loading = false;
        state.teacherList = action.payload;
        state.error = null;
      })
      .addCase(fetchTeachers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default studentSlice.reducer;
