import React from 'react';
import {useIntl} from 'react-intl';
import {PiDotsThreeVertical} from 'react-icons/pi';
import DropdownMenu from '../DropdownMenu/DropdownMenu';
import {
  getIslandStatusText,
  getLocalizedMessage,
} from '../../utilityFunction/helper';
import {commonStyle} from '../../Style/commonStyle';
import './IslandCardDetails.scss';

export default function IslandCardDetails({
  islandOptions,
  onSeeSection,
  onEditIsland,
  onUpdateStatus,
  selectedIsland,
  setSelectedIsland,
  isEditAvailable,
}) {
  const intl = useIntl();

  const handleMenuItemClick = island => {
    if (selectedIsland?.id === island?.id) setSelectedIsland(null);
    else setSelectedIsland(island);
  };

  return (
    <div className="islandsContainer">
      {islandOptions?.map(island => (
        <div className="islandCardDetailsWrapper" key={island?.id}>
          <div className="detailsContainer">
            <div className="detailsWrapper">
              {island?.module_icon && (
                <img
                  className="islandIcon"
                  src={island?.module_icon?.path}
                  alt="Island icon"
                />
              )}
              <div>
                <div className="islandName">{island?.name}</div>
                <div className="desc">{island?.description}</div>
              </div>
            </div>
          </div>

          <div className="sectionsContainer">
            <div className="sectionName">
              {getLocalizedMessage(intl, 'label.sectionsCount')}
            </div>
            <div className="desc">{island?.no_of_sections}</div>
          </div>

          <div className="actionsContainer">
            <button
              style={commonStyle.seeSectionBtn}
              onClick={() => onSeeSection(island?.id)}>
              {getLocalizedMessage(intl, 'label.seeSections')}
            </button>

            {isEditAvailable && (
              <div
                className={
                  island?.id === selectedIsland?.id
                    ? 'selected-dropdownAction'
                    : 'dropdownAction'
                }>
                <div style={{position: 'relative'}}>
                  <PiDotsThreeVertical
                    onClick={() => handleMenuItemClick(island)}
                    style={commonStyle.menuItemStyle}
                  />
                  {island?.id === selectedIsland?.id && (
                    <DropdownMenu isOpen={true} className="menu-wrapper">
                      <div
                        className="pointer"
                        onClick={() => onEditIsland(island)}>
                        {intl.formatMessage({id: 'label.editDetails'})}
                      </div>

                      <div
                        className="pointer"
                        onClick={() => onUpdateStatus(island)}>
                        {getIslandStatusText(island?.is_active, intl)}
                      </div>
                    </DropdownMenu>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
