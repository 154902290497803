import React, {useEffect, useContext} from 'react';
import WeeklyProgressCard from '../../../../components/WeeklyProgressCard/WeeklyProgressCard';
import {fetchStudentDashboard} from '../../../../store/actions/studentDashboardActions';
import {useDispatch, useSelector} from 'react-redux';
import {GlobalLoaderContext} from '../../../../globalContext/globalLoader/globalLoaderProvider';
import './StudentDashboardLeftBody.scss';
import IslandProgressBar from '../../../../components/IslandProgressBar/IslandProgressBar';
import {formatTime} from '../../../../utilityFunction/helper';
import StudentNotification from '../../StudentNotification/StudentNotification';
import {useIntl} from 'react-intl';

function StudentDashboardLeftBody({setShowDashboard}) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const studentDashboardData = useSelector(
    state => state?.studentDashboard?.studentDashboardData,
  );
 const dashboardData = studentDashboardData?.dashboard_data
  useEffect(() => {
    dispatch(fetchStudentDashboard({loaderDispatch}));
  }, [dispatch, loaderDispatch]);

  const currentWeekTimeSpent = dashboardData?.current_week_time_spent || 0;
  const previousWeekTimeSpent = dashboardData?.previous_week_time_spent || 0;
  const currentWeekLessonDone = dashboardData?.lesson_completed_in_current_week || 0;
  const previousWeekLessonDone = dashboardData?.lesson_completed_in_previous_week || 0;

  const formattedTime = formatTime(dashboardData?.current_week_time_spent || 0);

  function calculatePercentageDifference() {
    if (previousWeekTimeSpent === 0 && currentWeekTimeSpent === 0) {
      return 0;
    } else if (previousWeekTimeSpent === 0) {
      return 100;
    } else {
      const timeSpentDifference = currentWeekTimeSpent - previousWeekTimeSpent;
      return Math.round((timeSpentDifference / previousWeekTimeSpent) * 100);
    }
  }

  function calculateWeeklyProgress() {
    let progressPercentage;
    let progressDifference;

    if (previousWeekLessonDone === 0 && currentWeekLessonDone === 0) {
      progressPercentage = 0;
      progressDifference = 0;
    } else if (previousWeekLessonDone === 0) {
      progressPercentage = 100;
      progressDifference = 100;
    } else {
      progressPercentage = Math.round((currentWeekLessonDone / previousWeekLessonDone) * 100);
      const lessonDoneDifference = currentWeekLessonDone - previousWeekLessonDone;
      progressDifference = Math.round((lessonDoneDifference / previousWeekLessonDone) * 100);
    }

    return {
      progressPercentage,
      progressDifference
    };
  }

  const { progressPercentage, progressDifference } = calculateWeeklyProgress();

  function getBarColor(moduleName) {
    switch (moduleName) {
      case 'Tropical':
        return '#03E3AD';
      case 'Urban':
        return '#3BBBF7';
      case 'Rural':
        return '#F7F53B';
      case 'Fantasy':
        return '#AF33FF';
      default:
        return '#000000';
    }
  }

  const moduleProgressData = dashboardData?.module_data?.map(module => ({
    moduleName: module?.module_name,
    completedSections: module?.completed_sections,
    totalSections: module?.total_sections,
    barColor: getBarColor(module?.module_name),
  }));

  return (
    <div className="container student-dashboard">
      <div className="row">
        <div className="col-md-6 mb-3">
          <WeeklyProgressCard
            progressIcon="/images/clock.svg"
            progressTitle={intl.formatMessage({id: 'label.weekly_time'})}
            progressValue={formattedTime}
            IncDec={calculatePercentageDifference()}
          />
        </div>
        <div className="col-md-6 mb-3">
          <WeeklyProgressCard
            progressIcon="/images/bar-chart.png"
            progressTitle={intl.formatMessage({id: 'label.weekly_performance'})}
            progressValue={progressPercentage}
            IncDec={progressDifference}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <div className="blue-card">
            <h3>{intl.formatMessage({id: 'label.ongoing_islands'})}</h3>
            {moduleProgressData?.map(module => (
              <IslandProgressBar
                key={module.moduleName}
                value={module.completedSections}
                total={module.totalSections}
                barColor={module.barColor}
                islandName={module.moduleName}
                classNameWrapper="student-dash-progress"
              />
            ))}
          </div>
        </div>
        <div className="col-md-6 mb-3">
          <div className="blue-card">
            <h3>{intl.formatMessage({id: 'label.module_statistics'})}</h3>
            <div className="module_stats mb-3">
              <div className="value">
                {dashboardData?.total_completed_section_count}
              </div>
              <div className="title">{intl.formatMessage({id: 'label.sections_covered'})}</div>
            </div>
            <div className="module_stats">
              <div className="value">
                {dashboardData?.total_completed_lessons_count}
              </div>
              <div className="title">{intl.formatMessage({id: 'label.chapters_covered'})}</div>
            </div>
          </div>
        </div>
      </div>


      <div className="row">
        <div className="col-md-12 mb-3">
          <StudentNotification 
          setShowDashboard={setShowDashboard}
          isPagination={false}
          />
        </div>
      </div>
      
    </div>
  );
}

export default StudentDashboardLeftBody;
