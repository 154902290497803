import * as Yup from 'yup';
import {LESSON_NAME_REGEX} from '../core/validation';
export const addAnnouncementFieldName = {
  TITLE: 'description',
};
export const addAnnouncementInitialValues = {
  [addAnnouncementFieldName.TITLE]: '',
};
export const validationSchema = intl => {
  return Yup.object().shape({
    [addAnnouncementFieldName.TITLE]: Yup.string()
      .required(intl.formatMessage({id: 'label.required_announcement'}))
      .matches(
        LESSON_NAME_REGEX,
        intl.formatMessage({id: 'label.fieldError.invalid.title'}),
      ),
  });
};
