import React, {useContext, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import CustomDialogue from '../../components/CustomDialogue/CustomDialogue';
import InputLabel from '../../components/InputLabel/InputLabel';
import {useIntl} from 'react-intl';
import {useFormik} from 'formik';
import {
  resetPasswordFieldName,
  resetPasswordInitialValues,
  validationSchema,
} from '../../validationSchema/resetPasswordStudentSchema';
import {changePassword} from '../../store/actions/userProfileActions.js';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider.js';
import {IoEye, IoEyeOff} from 'react-icons/io5';
import {usePasswordVisibility} from '../../core/helpers/utils';
import '../SetPassword/SetPassword.scss';
import {useNavigate} from '../../routes';

function SetPassword({open, onClose}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const [showConfirmPassword, toggleConfirmPasswordVisibility] =
    usePasswordVisibility();
  const [showPassword, togglePasswordVisibility] = usePasswordVisibility();

  const {
    values,
    handleChange,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    setFieldValue,
  } = useFormik({
    initialValues: resetPasswordInitialValues,
    onSubmit: values => {
      const newPassword = values[resetPasswordFieldName.PASSWORD];
      dispatch(
        changePassword({
          password: newPassword,
          loaderDispatch,
          intl,
          dispatch,
          navigate,
        }),
      );
      onClose();
    },
    validationSchema: validationSchema(intl),
  });

  useEffect(() => {
    if (open) {
      setFieldValue(resetPasswordFieldName.PASSWORD, '');
      setFieldValue(resetPasswordFieldName.CONFIRM_PASSWORD, '');
    }
  }, [open]);

  return (
    <div>
      {open && (
        <CustomDialogue
          open={open}
          cancelText={intl.formatMessage({
            id: 'label.cancel',
          })}
          confirmText={intl.formatMessage({
            id: 'label.change-password',
          })}
          dialogueTitle={intl.formatMessage({
            id: 'label.change-password',
          })}
          hideTitle={false}
          onCancel={onClose}
          handleClose={onClose}
          onSubmit={handleSubmit}>
          <div className="container">
            <div className="row">
              <div className="mb-3 col-md-6">
                <div className="set-password-input">
                  <InputLabel
                    type={showPassword ? 'text' : 'password'}
                    name={resetPasswordFieldName.PASSWORD}
                    label={intl.formatMessage({
                      id: 'label.password',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'label.re-new-password',
                    })}
                    required
                    value={values[resetPasswordFieldName.PASSWORD]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched[resetPasswordFieldName.PASSWORD] &&
                      errors[resetPasswordFieldName.PASSWORD]
                    }
                  />
                  {!(
                    touched[resetPasswordFieldName.PASSWORD] &&
                    errors[resetPasswordFieldName.PASSWORD]
                  ) && (
                    <div
                      className="password-toggle"
                      onClick={togglePasswordVisibility}>
                      {showPassword ? <IoEyeOff /> : <IoEye />}
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-3 col-md-6">
                <div className="set-password-input">
                  <InputLabel
                    type={showConfirmPassword ? 'text' : 'password'}
                    name={resetPasswordFieldName.CONFIRM_PASSWORD}
                    label={intl.formatMessage({
                      id: 'label.confirm_password',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'label.re-enter_password',
                    })}
                    required
                    value={values[resetPasswordFieldName.CONFIRM_PASSWORD]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched[resetPasswordFieldName.CONFIRM_PASSWORD] &&
                      errors[resetPasswordFieldName.CONFIRM_PASSWORD]
                    }
                  />
                  {!(
                    touched[resetPasswordFieldName.CONFIRM_PASSWORD] &&
                    errors[resetPasswordFieldName.CONFIRM_PASSWORD]
                  ) && (
                    <div
                      className="password-toggle"
                      onClick={toggleConfirmPasswordVisibility}>
                      {showConfirmPassword ? <IoEyeOff /> : <IoEye />}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </CustomDialogue>
      )}
    </div>
  );
}

export default SetPassword;
