import React from 'react';
import {useIntl} from 'react-intl';
import {useNavigate} from 'react-router';
import CustomTabs from '../../components/CustomTabs/CustomTabs';
import NotificationsLayoutList from '../../components/NotificationsRightColLayout/NotificationsLayoutList/NotificationsLayoutList';
import AnnouncementLayoutList from '../../components/NotificationsRightColLayout/AnnouncementLayoutList/AnnouncementLayoutList';
import useUserRole from '../../hooks/useUserRole';
import UserRoles from '../../constants/userRoles';
import {isStaffOrSchoolAdminRole} from '../../utilityFunction/helper';
import {
  MANAGE_LESSONS_PATH,
  MANAGE_SCHOOLS_PATH,
  MANAGE_SCHOOL_DISTRICTS_PATH,
  STUDENT_DETAILS_PATH,
  UPDATE_SCHOOL_PATH,
  VIEW_LESSON_CONTENTS,
} from '../../constants/routePaths';
import './AllNotifcationsAndAnnouncementsListView.scss';

export default function AllNotifcationsAndAnnouncementsListView() {
  const intl = useIntl();
  const navigate = useNavigate();
  const userRole = useUserRole();

  const tabLabels = [
    intl.formatMessage({id: 'label.tabs.notify'}),
    ...(userRole !== UserRoles.SUPER_ADMIN
      ? [intl.formatMessage({id: 'label.tabs.Announcements'})]
      : []),
  ];

  function redirectHandler(notification) {
    if (UserRoles.SUPER_ADMIN === userRole) {
      switch (notification?.type) {
        case 'App\\Notifications\\SuperAdmin\\AddDistrictNotification':
          navigate(MANAGE_SCHOOL_DISTRICTS_PATH);
          break;
        case 'App\\Notifications\\SuperAdmin\\AddSchoolNotification':
          navigate(MANAGE_SCHOOLS_PATH);
          break;
        case 'App\\Notifications\\SuperAdmin\\SchoolStatusChangeNotification':
        case 'App\\Notifications\\SuperAdmin\\SchoolLicenseExpiryNotification':
          navigate(
            `${UPDATE_SCHOOL_PATH.replace(
              ':id',
              notification?.data?.school_id,
            )}`,
          );
        default:
          break;
      }
    } else if (isStaffOrSchoolAdminRole(userRole)) {
      switch (notification?.type) {
        case 'App\\Notifications\\Staff\\AddQuestionNotification':
        case 'App\\Notifications\\Staff\\AnswerAddedNotification':
          const lesson = {
            id: notification?.data?.lesson_id,
          };
          navigate(VIEW_LESSON_CONTENTS, {
            state: {lessonInfo: lesson, isStaffOrSchoolAdmin: true},
          });
          break;
        case 'App\\Notifications\\Staff\\StudentAddedNotification':
        case 'App\\Notifications\\Staff\\StudentStatusChangedNotification':
          navigate(
            `${STUDENT_DETAILS_PATH.replace(
              ':id',
              notification?.data?.student_id,
            )}`,
          );
          break;
        case 'App\\Notifications\\Staff\\AddLessonNotification':
          navigate(MANAGE_LESSONS_PATH, {
            state: {
              sectionName: notification?.data?.section_name,
              sectionId: notification?.data?.section_id,
            },
          });
          break;
        case 'App\\Notifications\\SchoolAdmin\\LicenseRenewedNotification':
        case 'App\\Notifications\\SuperAdmin\\SchoolLicenseExpiryNotification':
          navigate(
            `${UPDATE_SCHOOL_PATH.replace(
              ':id',
              notification?.data?.school_id,
            )}`,
          );
        default:
          break;
      }
    } else {
      console.error('An error occured');
    }
  }

  const tabComponents = [
    {
      component: NotificationsLayoutList,
      props: {
        desc: true,
        notifyIcon: '/images/book.svg',
        isPagination: true,
        redirectHandler: redirectHandler,
        showGoToBtn: false,
      },
    },
    ...(userRole !== UserRoles.SUPER_ADMIN
      ? [
          {
            component: AnnouncementLayoutList,
            props: {
              notifyIcon: '/images/book.svg',
              isPagination: true,
              desc: true,
              showGoToBtn: false,
            },
          },
        ]
      : []),
  ];

  return (
    <div className="all-notifyAnnouncements-wrapper">
      <CustomTabs
        tabLabels={tabLabels}
        tabComponents={tabComponents}
        tabsClassname="notify-announcements-tabsWrapper"
      />
    </div>
  );
}
