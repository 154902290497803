import React,{useEffect, useContext} from 'react';
import CustomModalStudent from '../../../components/CustomModalStudent/CustomModalStudent';
import { useIntl } from 'react-intl';
import StudentDashboardLeftBody from './StudentDashboardLeftBody/StudentDashboardLeftBody';
import StudentDashboardRight from './StudentDashboardRight/StudentDashboardRight';
import {useDispatch, useSelector} from 'react-redux';
import {GlobalLoaderContext} from '../../../globalContext/globalLoader/globalLoaderProvider';


function StudentDashboard({ showDashboard, closeModal,setShowDashboard }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  

  const handleCloseModal = () => {
    closeModal(false);
  };

  const dialogHeaderContent = (
    <div className="help-header-wrapper">
      <div onClick={handleCloseModal} className="back-icon">
        <img src="/images/back.svg" alt="back" />
      </div>
      <h2 className="help-header text-shadow">
        {intl.formatMessage({ id: 'label.dashboard' })}
      </h2>
    </div>
  );

  return (
    <div className="student-setting-modal-outer">
      <CustomModalStudent
        open={showDashboard}
        handleClose={handleCloseModal}
        backgroundBlue={true}
        modalWidth={'90%'}
        modalHeight={'97%'}
        marginTop={'1%'}
        showHeader
        dialogHeader={dialogHeaderContent}
      >
        <div className="student-setting-modal-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <StudentDashboardLeftBody 
                setShowDashboard={setShowDashboard}/>
              </div>

              {/* <div className="col-3 sidebar">
                <StudentDashboardRight />
              </div> */}
            </div>
          </div>
        </div>
      </CustomModalStudent>
    </div>
  );
}

export default StudentDashboard;
