import * as Yup from 'yup';
import {EMAIL_REGEX} from '../core/validation';
export const addSuperAdminFieldName = {
  ID: 'id',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
};
export const addSuperAdminInitialValues = {
  [addSuperAdminFieldName.ID]: '',
  [addSuperAdminFieldName.FIRST_NAME]: '',
  [addSuperAdminFieldName.LAST_NAME]: '',
  [addSuperAdminFieldName.EMAIL]: '',
};
export const validationSchema = intl => {
  return Yup.object().shape({
    [addSuperAdminFieldName.EMAIL]: Yup.string()
      .required(intl.formatMessage({id: 'label.email_required'}))
      .matches(EMAIL_REGEX, intl.formatMessage({id: 'label.fieldError.email'})),
    [addSuperAdminFieldName.FIRST_NAME]: Yup.string().required(
      intl.formatMessage({id: 'label.first_name_required'}),
    ),
    [addSuperAdminFieldName.LAST_NAME]: Yup.string().required(
      intl.formatMessage({id: 'label.last_name_required'}),
    ),
  });
};
