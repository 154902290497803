import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {PiDotsThreeVertical} from 'react-icons/pi';
import {Typography} from '@mui/material';
import CustomTable from '../../components/CustomTable/CustomTable';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';
import DeactivateModal from '../../components/DeactivateModal/DeactivateModal';
import {
  getSuperAdminData,
  addSuperAdminData,
  updateSuperAdminData,
  updateSuperAdminStatus,
} from '../../store/actions/superAdminActions';
import {getLocalizedMessage, getStatusText} from '../../utilityFunction/helper';
import {useNavigate} from '../../routes/index.js';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';
import AddSuperAdmin from './AddSuperAdmin.js';
import {useFormik} from 'formik';
import {
  addSuperAdminFieldName,
  addSuperAdminInitialValues,
  validationSchema,
} from '../../validationSchema/addSuperAdminSchema.js';

export default function SuperAdminListing() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const {isAuthenticated} = useSelector(state => state?.auth);
  const {superAdminList} = useSelector(state => state?.superAdmin);
  const superAdminId = useSelector(
    state => state?.auth?.data?.data?.data?.user?.id,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSuperAdmin, setSelectedSuperAdmin] = useState(null);
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const [openAddSuperAdmin, setOpenAddSuperAdmin] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (isAuthenticated) fetchSuperAdminData();
  }, [isAuthenticated]);

  const fetchSuperAdminData = async () => {
    await dispatch(
      getSuperAdminData({
        currentPage,
        loaderDispatch,
      }),
    );
  };

  const onPageChange = page => {
    setCurrentPage(page);
  };

  const handleDeactivateConfirm = async () => {
    if (selectedSuperAdmin?.id) {
      setOpenDeactivateModal(false);
      dispatch(updateSuperAdminStatus({id: selectedSuperAdmin?.id, intl})).then(
        () => {
          setSelectedSuperAdmin(null);
          fetchSuperAdminData();
        },
      );
    }
  };

  const handleQuickActions = superAdmin => {
    if (selectedSuperAdmin?.id === superAdmin?.id) setSelectedSuperAdmin(null);
    else setSelectedSuperAdmin(superAdmin);
  };

  const editDetails = id => {
    setEditMode(true);
    const superAdminToEdit = superAdminList.data.find(
      superAdmin => superAdmin.id === id,
    );
    if (superAdminToEdit) {
      setFieldValue(
        addSuperAdminFieldName.FIRST_NAME,
        superAdminToEdit.first_name || '',
      );
      setFieldValue(
        addSuperAdminFieldName.LAST_NAME,
        superAdminToEdit.last_name || '',
      );
      setFieldValue(addSuperAdminFieldName.EMAIL, superAdminToEdit.email || '');
      setOpenAddSuperAdmin(true);
    }
  };

  const handleAddSuperAdmin = () => {
    setEditMode(false);
    setOpenAddSuperAdmin(true);
    setFieldValue(addSuperAdminFieldName.FIRST_NAME, '');
    setFieldValue(addSuperAdminFieldName.LAST_NAME, '');
    setFieldValue(addSuperAdminFieldName.EMAIL, '');
  };

  const handleCloseAddSuperAdmin = () => {
    setOpenAddSuperAdmin(false);
  };

  const {
    values,
    handleChange,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: addSuperAdminInitialValues,
    onSubmit: values => {
      if (editMode) {
        dispatch(
          updateSuperAdminData({
            id: selectedSuperAdmin.id,
            payload: values,
            intl,
          }),
        ).then(() => {
          fetchSuperAdminData();

          handleCloseAddSuperAdmin();
        });
      } else {
        dispatch(
          addSuperAdminData({
            payload: values,
            intl,
          }),
        ).then(() => {
          fetchSuperAdminData();

          handleCloseAddSuperAdmin();
        });
      }
    },
    validationSchema: validationSchema(intl),
  });

  const TABLE_HEAD = [
    {
      id: 'idNumber',
      label: intl.formatMessage({id: 'label.idNumber'}),
      alignRight: false,
      renderColumn: superAdmin => {
        return (
          <Typography variant="subtitle">{superAdmin?.id || '-'}</Typography>
        );
      },
    },
    {
      id: 'superAdminName',
      label: intl.formatMessage({id: 'label.super_admin_name'}),
      alignRight: false,
      renderColumn: superAdmin => {
        return (
          <div key={superAdmin?.id} className="s_name_address d-flex">
            <div>
              <div className="s_name">{`${superAdmin?.first_name} ${
                superAdmin?.last_name || ''
              }`}</div>
            </div>
          </div>
        );
      },
    },
    {
      id: 'emailAddress',
      label: intl.formatMessage({id: 'label.email_address'}),
      alignRight: false,
      dataKey: 'email',
    },
    {
      id: 'status',
      label: intl.formatMessage({id: 'label.status'}),
      alignRight: false,
      renderColumn: superAdmin => {
        return (
          <div
            variant="subtitle"
            className={`${
              superAdmin.is_active ? 'school-active' : 'school-deactivate'
            }`}>
            {superAdmin?.is_active ? 'active' : 'inactive'}
          </div>
        );
      },
    },
    {
      id: 'quickActions',
      label: intl.formatMessage({id: 'label.quick_actions'}),
      alignRight: false,
      dataKey: '',
      renderColumn: superAdmin => {
        return (
          <div className="d-flex actions-wrapper">
            <div
              className={
                superAdmin?.id === selectedSuperAdmin?.id
                  ? 'edit-delete selected-action'
                  : 'edit-delete'
              }>
              {!superAdmin.is_default_superadmin && (
                <div style={{position: 'relative'}}>
                  <PiDotsThreeVertical
                    onClick={() => handleQuickActions(superAdmin)}
                  />
                  {superAdmin?.id === selectedSuperAdmin?.id && (
                    <DropdownMenu isOpen={true}>
                      <div
                        className="pointer"
                        onClick={() => editDetails(superAdmin.id)}>
                        {intl.formatMessage({id: 'label.edit-profile'})}
                      </div>
                      {superAdmin?.id !== superAdminId && (
                        <div
                          className="pointer"
                          onClick={() => setOpenDeactivateModal(true)}>
                          {getStatusText(superAdmin?.is_active, intl)}
                        </div>
                      )}
                    </DropdownMenu>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="d-flex student-detail-wrapper">
        <div className="school-label">
          {intl.formatMessage({id: 'label.details_of_admin'})}
        </div>

        <div className="add-import-btn">
          <div>
            <button onClick={handleAddSuperAdmin} className="btn btn-primary">
              {intl.formatMessage({id: 'label.addSuperAdmin'})}
            </button>
          </div>
        </div>
      </div>

      <CustomTable
        tableHead={TABLE_HEAD}
        tableData={superAdminList?.data}
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalPages={superAdminList?.last_page}
        totalCount={superAdminList?.total}
      />

      {openDeactivateModal && (
        <DeactivateModal
          open={openDeactivateModal}
          setOpen={setOpenDeactivateModal}
          handleDeactivate={handleDeactivateConfirm}
          confirmText={getStatusText(selectedSuperAdmin?.is_active, intl)}
          modalContentText={
            !selectedSuperAdmin?.is_active
              ? getLocalizedMessage(intl, 'superAdmin.statusModal.activate')
              : getLocalizedMessage(intl, 'superAdmin.statusModal.deactivate')
          }
        />
      )}

      <AddSuperAdmin
        open={openAddSuperAdmin}
        handleClose={handleCloseAddSuperAdmin}
        handleSubmit={handleSubmit}
        values={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
        handleBlur={handleBlur}
        setFieldValue={setFieldValue}
        editMode={editMode}
      />
    </div>
  );
}
