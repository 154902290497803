import * as Yup from 'yup';
import {LESSON_NAME_REGEX, NAME_REGEX} from '../core/validation';

export const lessonDetailsFormFieldNames = {
  LESSON_NAME: 'name',
  LESSON_DESC: 'short_description',
  MIN_READ_TIME: 'minimum_read_time',
};

export const lessonDetailsFormInitialValues = {
  [lessonDetailsFormFieldNames.LESSON_NAME]: '',
  [lessonDetailsFormFieldNames.LESSON_DESC]: '',
  [lessonDetailsFormFieldNames.MIN_READ_TIME]: '',
};

export const validationSchema = intl => {
  return Yup.object().shape({
    [lessonDetailsFormFieldNames.LESSON_NAME]: Yup.string()
      .required(
        intl.formatMessage({id: 'lessonDetails.fieldError.name.required'}),
      )
      .min(
        2,
        intl.formatMessage({id: 'lessonDetails.fieldError.name.minLength'}),
      )
      .max(
        80,
        intl.formatMessage({id: 'lessonDetails.fieldError.name.maxLength'}),
      )
      .matches(
        LESSON_NAME_REGEX,
        intl.formatMessage({id: 'lessonDetails.fieldError.name.invalid'}),
      ),

    [lessonDetailsFormFieldNames.LESSON_DESC]: Yup.string()
      .notRequired()
      .max(
        200,
        intl.formatMessage({id: 'lessonDetails.fieldError.desc.maxLength'}),
      )
      .matches(
        LESSON_NAME_REGEX,
        intl.formatMessage({id: 'lessonDetails.fieldError.desc.invalid'}),
      ),

    [lessonDetailsFormFieldNames.MIN_READ_TIME]: Yup.number()
      .typeError(
        intl.formatMessage({id: 'lessonDetails.fieldError.readTime.typeError'}),
      )
      .required(
        intl.formatMessage({id: 'lessonDetails.fieldError.readTime.required'}),
      )
      .min(
        1,
        intl.formatMessage({id: 'lessonDetails.fieldError.readTime.minValue'}),
      )
      .max(
        200,
        intl.formatMessage({id: 'lessonDetails.fieldError.readTime.maxValue'}),
      ),
  });
};
