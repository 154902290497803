const style = {
    container: {
      flex: 1,
      justifyContent: 'center',
      padding: 16,
      alignItems: 'center',
      backgroundColor: 'red',
    },
    img: {
      maxWidth: 127,
      margin: 'auto',
    },
    header: {
      fontSize: 24,
      marginBottom: 5,
      textAlign: 'center',
    },
  
    button: {
      backgroundColor: 'var(--primary-color)',
      padding: 12.8,
      alignItems: 'center',
      borderRadius: 12,
      fontFamily: 'var(--font-inter-bold)',
      color: '#fff',
      fontSize: 16,
      cursor: 'pointer',
    },
    lightButton: {
      backgroundColor: 'var(--faded-blue)',
      padding: 12.8,
      alignItems: 'center',
      borderRadius: 12,
      fontFamily: 'var(--font-inter-bold)',
      color: 'var(--primary-color)',
      fontSize: 16,
      cursor:'pointer',
    },
  };
  
  export default style;
  