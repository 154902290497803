import React from 'react';
import {Outlet} from '../routes';

import MainLayout from '../layouts/MainLayout';
import HeaderContainer from '../containers/Header';
import MenuContainer from '../containers/Menu';
import useUserRole from '../hooks/useUserRole';
import StudentLayout from '../layouts/StudentLayout/StudentLayout';
import StudentHeader from '../views/Student/StudentHeader/StudentHeader';

function Home({headerLabel}) {
  const userRole = useUserRole();

  if (!userRole) {
    return null;
  }
  return (
    <>
      {userRole === 'student' ? (
        <StudentLayout header={<StudentHeader />} content={<Outlet />} />
      ) : (
        <MainLayout
          header={<HeaderContainer headerLabel={headerLabel} />}
          menu={<MenuContainer />}
          content={<Outlet />}
        />
      )}
    </>
  );
}

export default Home;
