import React from 'react';
import {useIntl} from 'react-intl';
import ThreeDButton from '../../../../components/ThreeDButton/ThreeDButton';
import {formatTime} from '../../../../utilityFunction/helper';
import './LessonCompleteModal.scss';

export default function LessonCompleteModal({readTime, handleStartNewLesson}) {
  const intl = useIntl();
  return (
    <div className="lesson-complete-wrapper">
      <div className="lesson-complete-details">
        <img
          className="lesson-complete-icon"
          src={'/images/lessonCompleteCheck.svg'}
          alt="Checkmark indicating lesson completion"
        />

        <div className="lesson-complete-text-wrapper">
          <div className="lesson-complete-text">
            {intl.formatMessage({id: 'lesson.complete'})}
          </div>
          <div className="lesson-complete-subtext">
            {intl.formatMessage({id: 'reached.goal'})}
          </div>
        </div>

        <div className="lesson-time-taken-container">
          <img
            src={'/images/timer.svg'}
            className="lesson-timer-icon"
            alt="Timer"
          />
          <div className="lesson-time-taken">{formatTime(readTime)}</div>
          <div className="time-taken-text">
            {intl.formatMessage({id: 'label.timeTaken'})}
          </div>
        </div>
      </div>

      <div>
        <ThreeDButton
          className="border-0"
          handleClick={handleStartNewLesson}
          labelText={intl.formatMessage({id: 'startNewLesson'})}
        />
      </div>
    </div>
  );
}
