import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {
  deleteQuestion,
  getStudentQuesAns,
} from '../../../../store/actions/questionAnswerActions';
import QuestionAnswerListingView from '../../../../components/QuestionAnswerListingView/QuestionAnswerListingView';
import CustomModalStudent from '../../../../components/CustomModalStudent/CustomModalStudent';
import ThreeDButton from '../../../../components/ThreeDButton/ThreeDButton';
import ContentWrapper from '../../StudentViewLessonContent/ContentWrapper';
import AddQuestionByStudentView from '../AddQuestionByStudentView/AddQuestionByStudentView';
import {
  getQuestionsNotPinned,
  getQuestionsPinned,
} from '../../../../utilityFunction/helper';
import {GlobalLoaderContext} from '../../../../globalContext/globalLoader/globalLoaderProvider';
import {commonStyle} from '../../../../Style/commonStyle';
import './StudentQuestionAnswerView.scss';

export default function StudentQuestionAnswerView({currentLesson}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const {quesAnsList} = useSelector(state => state?.questionAnswer);
  const [searchTerm, setSearchTerm] = useState('');
  const [showAddQuestionView, setShowAddQuestionView] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedDeleteQues, setSelectedDeleteQues] = useState(null);

  useEffect(() => {
    if (searchTerm.trim() === '' && !showAddQuestionView) {
      fetchStudentQuestions();
    }
  }, [currentLesson, showAddQuestionView, searchTerm]);

  function fetchStudentQuestions() {
    dispatch(
      getStudentQuesAns({
        lessonId: currentLesson?.id,
        search: searchTerm.trim() !== '' ? searchTerm : undefined,
      }),
    );
  }

  function onAddNewQues() {
    setShowAddQuestionView(true);
  }

  function handleQuestionDelete(question) {
    if (question) {
      setSelectedDeleteQues(question);
      setShowDeleteModal(true);
    }
  }

  function onConfirmDelete() {
    if (selectedDeleteQues) {
      const quesId = selectedDeleteQues?.question_id;
      dispatch(deleteQuestion({quesId, intl, loaderDispatch})).then(() => {
        fetchStudentQuestions();
        setShowDeleteModal(false);
        setSelectedDeleteQues(null);
      });
    }
  }

  function onCancelDelete() {
    setShowDeleteModal(false);
    setSelectedDeleteQues(null);
  }

  function handleSearch() {
    fetchStudentQuestions();
  }

  function handleSearchInputChange(event) {
    setSearchTerm(event.target.value);
  }

  const questionsPinned = getQuestionsPinned(quesAnsList);
  const questionsNotPinned = getQuestionsNotPinned(quesAnsList);
  const areQuestionsNotAvailable =
    questionsPinned?.length === 0 && questionsNotPinned?.length === 0;

  return (
    <>
      {showAddQuestionView ? (
        <AddQuestionByStudentView
          open={showAddQuestionView}
          setOpen={setShowAddQuestionView}
          lessonId={currentLesson?.id}
          setSearchTerm={setSearchTerm}
        />
      ) : (
        <div className="student-ques-ans-list-wrapper">
          <div className="searchQuestions-wrapper">
            <div style={{flex: 1}}>
              <ContentWrapper
                outerClassName="outer-searchdoc-container"
                innerClassName="inner-searchdoc-container">
                <div className="searchQues-contentWrapper">
                  <img src="/images/search.svg" alt="Search icon" />
                  <input
                    className="quesSearchInputField"
                    value={searchTerm}
                    type="text"
                    placeholder={intl.formatMessage({
                      id: 'placeholder.searchQues',
                    })}
                    onChange={handleSearchInputChange}
                  />
                </div>
              </ContentWrapper>
            </div>
            <div className="searchQues-btn">
              <ThreeDButton
                className="border-0"
                labelText={intl.formatMessage({id: 'label.search'})}
                buttonColorClass="blueBtn"
                labelClassName="search-btn-label"
                innerColorClass="blueInner"
                handleClick={handleSearch}
              />
            </div>
          </div>

          {areQuestionsNotAvailable && (
            <div className="addNewQues-btn">
              <ThreeDButton
                className="add-question-btn border-0"
                labelText={intl.formatMessage({id: 'add.newQuestion'})}
                handleClick={onAddNewQues}
              />
            </div>
          )}

          {questionsPinned?.length > 0 && (
            <div className="quesAns-container">
              <div className="quesTypeHeaderText-container">
                <div className="quesTypeHeaderText">
                  {intl.formatMessage({id: 'lesson.featureQuestion'})}
                </div>
                <ThreeDButton
                  className="add-question-btn border-0"
                  labelText={intl.formatMessage({id: 'add.newQuestion'})}
                  handleClick={onAddNewQues}
                />
              </div>
              <QuestionAnswerListingView
                quesAnsData={questionsPinned}
                lessonName={currentLesson?.name}
              />
            </div>
          )}

          {questionsNotPinned?.length > 0 && (
            <div className="quesAns-container">
              <div className="quesTypeHeaderText-container">
                <div className="quesTypeHeaderText">
                  {intl.formatMessage({id: 'lesson.quesAskByYou'})}
                </div>
                {questionsPinned?.length === 0 && (
                  <ThreeDButton
                    className="add-question-btn border-0"
                    labelText={intl.formatMessage({id: 'add.newQuestion'})}
                    handleClick={onAddNewQues}
                  />
                )}
              </div>
              <QuestionAnswerListingView
                quesAnsData={questionsNotPinned}
                lessonName={currentLesson?.name}
                onDeleteQues={handleQuestionDelete}
              />
            </div>
          )}
        </div>
      )}

      {showDeleteModal && (
        <CustomModalStudent
          open={showDeleteModal}
          contentStyle={commonStyle.blueModalContentStyle}>
          <div className="deleteModal-wrapper">
            <div className="delete-text text-shadow">
              {intl.formatMessage({id: 'deleteModal.quesText'})}
            </div>

            <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
              <ThreeDButton
                handleClick={onCancelDelete}
                labelText={intl.formatMessage({id: 'label.cancel'})}
                buttonColorClass="blueBtn"
                innerColorClass="blueInner"
                className="border-0"
              />

              <ThreeDButton
                handleClick={onConfirmDelete}
                labelText={intl.formatMessage({id: 'label.delete'})}
                className="border-0"
              />
            </div>
          </div>
        </CustomModalStudent>
      )}
    </>
  );
}
