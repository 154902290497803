import * as Yup from 'yup';
import {NAME_REGEX} from '../core/validation';

export const addDistrictFormFieldNames = {
  DISTRICT_NAME: 'name',
  COUNTRY_ID: 'country',
  STATE_ID: 'state',
};

export const addDistrictFormInitialValues = {
  [addDistrictFormFieldNames.DISTRICT_NAME]: '',
  [addDistrictFormFieldNames.COUNTRY_ID]: '',
  [addDistrictFormFieldNames.STATE_ID]: '',
};

export const validationSchema = intl => {
  return Yup.object().shape({
    [addDistrictFormFieldNames.DISTRICT_NAME]: Yup.string()
      .required(
        intl.formatMessage({id: 'addDistrict.fieldError.name.required'}),
      )
      .min(2, intl.formatMessage({id: 'addDistrict.fieldError.name.minLength'}))
      .max(
        20,
        intl.formatMessage({id: 'addDistrict.fieldError.name.maxLength'}),
      )
      .matches(
        NAME_REGEX,
        intl.formatMessage({id: 'addDistrict.fieldError.name.invalid'}),
      ),

    [addDistrictFormFieldNames.COUNTRY_ID]: Yup.string().required(
      intl.formatMessage({id: 'addDistrict.fieldError.country.required'}),
    ),

    [addDistrictFormFieldNames.STATE_ID]: Yup.string().required(
      intl.formatMessage({id: 'addDistrict.fieldError.state.required'}),
    ),
  });
};
