import React from 'react';
import {useIntl} from 'react-intl';
import CustomTabs from '../CustomTabs/CustomTabs';
import NotificationsLayoutList from './NotificationsLayoutList/NotificationsLayoutList';
import AnnouncementLayoutList from './AnnouncementLayoutList/AnnouncementLayoutList';
import UserRoles from '../../constants/userRoles';
import useUserRole from '../../hooks/useUserRole';
import './NotificationsRightColLayout.scss';

export default function NotificationsRightColLayout({
  NotifyClassName,
  desc,
  btnText,
  notifyIcon,
  redirectHandler,
  viewAll,
  isPagination,
  tabsClassname,
  annoucementIcon,
}) {
  const intl = useIntl();
  const userRole = useUserRole();

  const tabLabels = [
    intl.formatMessage({id: 'label.tabs.notify'}),
    ...(userRole !== UserRoles.SUPER_ADMIN
      ? [intl.formatMessage({id: 'label.tabs.Announcements'})]
      : []),
  ];

  const tabComponents = [
    {
      component: NotificationsLayoutList,
      props: {
        NotifyClassName: NotifyClassName,
        desc: desc,
        btnText: btnText,
        notifyIcon: notifyIcon,
        redirectHandler: redirectHandler,
        isPagination: isPagination,
        showGoToBtn: UserRoles.STUDENT === userRole ? true : false,
      },
    },
    ...(userRole !== UserRoles.SUPER_ADMIN
      ? [
          {
            component: AnnouncementLayoutList,
            props: {
              notifyIcon: annoucementIcon,
              ...(userRole === 'student'
                ? {NotifyClassName: 'student-notification'}
                : {}),
              isPagination: isPagination,
              desc: desc,
              showGoToBtn: false,
            },
          },
        ]
      : []),
  ];

  return (
    <div>
      {!isPagination && (
        <div className="notify-dashboardHeader">
          <div className="recentNotify-heading">
            {intl.formatMessage({
              id:
                UserRoles.SUPER_ADMIN === userRole
                  ? 'label.recentNotify'
                  : 'label.recentNotifyAndAnnouncements',
            })}
          </div>
          <div className="viewAll-text" onClick={viewAll}>
            {intl.formatMessage({id: 'label.viewAll'})}
          </div>
        </div>
      )}
      <CustomTabs
        tabLabels={tabLabels}
        tabComponents={tabComponents}
        tabsClassname={tabsClassname}
      />
    </div>
  );
}
