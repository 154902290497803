import React from 'react';
import PropTypes from 'prop-types';
import './SelectDropdown.scss';

const SelectDropdown = props => {
  const {
    options,
    value,
    onChange,
    placeholder,
    name,
    required,
    disabled,
    error,
    selectClassName,
    className,
    htmlFor,
    labelClassName,
    label,
  } = props;

  const parentClassName = disabled ? `${selectClassName || 'form-control cus-select'} disabled` : selectClassName || 'form-control cus-select';
  return (
    <>
      {label && (
        <label htmlFor={htmlFor} className={labelClassName || 'form-label cus-form-label'}>
          {label} {required && <span className="text-danger">*</span>}
        </label>
      )}
      <div className={parentClassName}>
        <select
          value={value}
          onChange={onChange}
          className={className || 'border-0 w-100'}
          name={name}
          disabled={disabled}
          required={required}
          error={error}>
           {placeholder && <option value="">{placeholder}</option>}
          {options && options?.map(option => (
            <option
              key={option.id?.toString()}
              value={option.id?.toString()}
              name={option.name}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
      {error && <p className="text-danger mt-1">{error}</p>}
    </>
  );
};

SelectDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default SelectDropdown;
