import React, {useContext, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import {useDropzone} from 'react-dropzone';
import {handleUploadFile} from '../../store/actions/fileActions';
import {MAX_FILE_SIZE} from '../../utilityFunction/constants';
import {showToast} from '../Toast/Toast';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';
import './AttachImageVideoCard.scss';

export default function AttachImageVideoCard({
  multiple = false,
  handleImageVideoDelete,
  initialFile,
  typeFile,
  error,
  mediaNameField,
  filePreviewNameField,
  fileTypeNameField,
  setFieldTouched,
  setFieldValue,
  mediaPreviewUrlField,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const [filePreview, setFilePreview] = useState(initialFile?.path || '');
  const [fileName, setFileName] = useState(initialFile?.name || '');
  const [fileType, setFileType] = useState(typeFile || '');

  const onDrop = (acceptedFiles, fileRejections) => {
    if (fileRejections.length > 0) {
      fileRejections.forEach(({file, errors}) => {
        errors.forEach(error => {
          showToast(
            getLocalizedMessage(intl, 'error.invalidFile.type'),
            'error',
          );
        });
      });
      return;
    }
    const file = acceptedFiles[0];
    if (file && file?.size <= MAX_FILE_SIZE) {
      setFilePreview('');
      setFilePreview(URL.createObjectURL(file));
      setFileName(file.name);
      setFileType(file.type);
      const typeOfFile = file.type.startsWith('image/')
        ? 'image'
        : file.type.startsWith('video/')
        ? 'video'
        : null;
      if (file && typeOfFile) {
        onFileUpload(file, typeOfFile);
      }
    } else {
      showToast(getLocalizedMessage(intl, 'label.maxFileSize'), 'error');
      return;
    }
  };

  const onFileUpload = async (file, typeOfFile) => {
    dispatch(handleUploadFile({file, intl, loaderDispatch}))
      .unwrap()
      .then(response => {
        setFieldValue(mediaNameField, response?.media_id);
        setFieldValue(mediaPreviewUrlField, response?.media_path);
        setFieldValue(filePreviewNameField, file.name);
        setFieldValue(fileTypeNameField, typeOfFile);
        setFieldTouched(mediaNameField, true);
      })
      .catch(error => {
        setFieldValue(mediaNameField, '');
        setFieldValue(filePreviewNameField, '');
        setFieldValue(fileTypeNameField, '');
        setFieldTouched(mediaNameField, true);
        setFilePreview('');
        setFileName('');
        setFileType('');
        console.error('Upload failed', error);
      });
  };

  const renderPreview = () => {
    if (!filePreview) return null;

    if (fileType.startsWith('image/') || fileType === 'image') {
      return <img src={filePreview} alt="Preview" className="preview-image" />;
    } else if (fileType.startsWith('video/') || fileType === 'video') {
      return (
        <video className="preview-video" controls>
          <source src={filePreview} type={fileType} />
          {intl.formatMessage({id: 'noVideoSupport.browser'})}
        </video>
      );
    }
  };

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'video/mp4': ['.mp4'],
      'video/webm': ['.webm'],
    },
    multiple,
  });

  return (
    <>
      <div className="image-video-wrapper">
        <div className="attachment-text">
          <div className="attachment-label">
            <span className="handle-icon mr-8">☰</span>
            {intl.formatMessage({id: 'label.attach.imageVideo'})}
            <span className="ms-1">
              {intl.formatMessage({id: 'label.maxFileSize'})}
            </span>
          </div>
          <img
            className="trash-icon"
            src="/images/trash.svg"
            onClick={handleImageVideoDelete}
          />
        </div>
        <div className="file-show-wrapper">
          <div {...getRootProps()} className="image-video-dropzone">
            <input {...getInputProps()} />
            {isDragActive ? (
              <div>{intl.formatMessage({id: 'label.dropFiles'})}</div>
            ) : (
              <div>{intl.formatMessage({id: 'label.clickFile'})}</div>
            )}
          </div>

          {filePreview && (
            <div className="preview-container">
              <div className="file-preview">{renderPreview()}</div>
              <div className="file-name">{fileName}</div>
            </div>
          )}
        </div>
      </div>

      {error && <p className="text-danger mt-1">{error}</p>}
    </>
  );
}
