import React, {useState} from 'react';
import {EditorState, convertToRaw} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import {convertFromRaw} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './TextEditor.scss';

export default function TextEditor({
  handleEditorDelete,
  initialEditorState,
  setFieldValue,
  setFieldTouched,
  name,
  error,
}) {
  const [editorState, setEditorState] = useState(() =>
    initialEditorState
      ? EditorState.createWithContent(
          convertFromRaw(JSON.parse(initialEditorState)),
        )
      : EditorState.createEmpty(),
  );

  const onEditorStateChange = newEditorState => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const contentStateString = JSON.stringify(convertToRaw(contentState));
    setFieldValue(name, contentStateString);
    setFieldTouched(name, true);
  };

  const toolbarOptions = {
    options: ['inline', 'list', 'link', 'history'],
    inline: {
      options: ['bold', 'italic', 'underline'],
      bold: {className: 'toolbar-item'},
      italic: {className: 'toolbar-item'},
      underline: {className: 'toolbar-item'},
    },
    list: {
      inDropdown: false,
      options: ['unordered', 'ordered'],
    },
  };

  return (
    <div>
      <div className="text-editor-wrapper">
        <div className="editor-label-text">
          <div className="editor-label">
            <span className="handle-icon mr-8">☰</span> {'Text'}
          </div>
          <img
            className="trash-icon"
            src="/images/trash.svg"
            onClick={handleEditorDelete}
          />
        </div>
        <div>
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={onEditorStateChange}
            toolbar={toolbarOptions}
            toolbarClassName="toolbar-class"
            placeholder="Type something here..."
          />
        </div>
      </div>
      {error && <p className="text-danger mt-1">{error}</p>}
    </div>
  );
}
