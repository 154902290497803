import React from 'react';
import './ContentWrapper.scss';

export default function ContentWrapper({
  innerClassName,
  outerClassName,
  children,
}) {
  return (
    <div className={`content-wrap ${outerClassName}`}>
      <div className={`content-wrap-inner ${innerClassName}`}>{children}</div>
    </div>
  );
}
