import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {showToast} from '../../components/Toast/Toast';
import {setLoading} from '../../globalContext/globalLoader/globalLoaderAction';
export const fetchModuleSectionList = createAsyncThunk(
  'content/section/fetchSectionList',
  async ({id, sectionId, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    let url = `/module-section?module_id=${id}`;
    if (sectionId) {
      url += `&id=${sectionId}`;
    }
    try {
      const response = await axiosPrivateInstance.get(url);
      loaderDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      throw error.response?.data?.message;
    }
  },
);
export const fetchSectionObjectsList = createAsyncThunk(
  'content/section/fetchSectionObjects',
  async ({id, sectionId}, thunkAPI) => {
    try {
      let url = `/module-elements?module_id=${id}`;
      if (sectionId) {
        url += `&section_id=${sectionId}`;
      }
      const response = await axiosPrivateInstance.get(url);
      return response.data?.data?.data;
    } catch (error) {
      throw error.response?.data?.message;
    }
  },
);
export const addSectionList = createAsyncThunk(
  'content/section/addSectionList',
  async (payload, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.post(
        '/module-section',
        payload,
      );
      return response.data;
    } catch (error) {
      throw error.response?.data?.message;
    }
  },
);
export const updateSectionList = createAsyncThunk(
  'content/section/updateSectionList',
  async ({id, payload, intl}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.patch(
        `/module-section/${id}`,
        payload,
      );
      showToast(getLocalizedMessage(intl, 'success.updated'), 'success');
      return response.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response?.data?.message;
    }
  },
);
export const updateSectionStatus = createAsyncThunk(
  'students/updateSectionStatus',
  async ({id, intl}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.patch(
        `/modules-sections/status/${id}`,
      );
      showToast(getLocalizedMessage(intl, 'section.success.updated'), 'success');
      return response.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

