const style = {
  menuList: {
    alignItems: 'center',
    flexDirection: 'row',
    color: 'white',
  },
  active: {
    background: '#02AE85',
    padding: 10,
    borderRadius: 12,
  },
  menuContainer: {
    padding: 20,
  },
};

export default style;
