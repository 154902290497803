import React from 'react';
import {
    Paper,
    TableBody,
    TableCell,
    TableRow,
    Typography,
  } from "@mui/material";

function TableNotFound() {
  return (
    <TableBody>
      <TableRow>
        <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
            <Typography variant="h6" paragraph>
              <img src="/images/NoDataFound.svg" alt="no_data" />
            </Typography>
        </TableCell>
      </TableRow>
    </TableBody>

  )
}

export default TableNotFound