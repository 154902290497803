import React from 'react';
import {useIntl} from 'react-intl';
import {useSelector} from 'react-redux';
import useUserRole from '../../hooks/useUserRole';
import AddStaffAnswerView from '../../views/AddStaffAnswerView/AddStaffAnswerView';
import {
  checkSchoolAdmin,
  getStaffLoginId,
  getTimeAgo,
} from '../../utilityFunction/helper';
import './StaffQuestionAnswerListView.scss';

export default function StaffQuestionAnswerListView({
  quesAnsData,
  showAddAnsView,
  selectedQues,
  setShowAddAnsView,
  setSelectedQues,
  onAddAnswer,
  onPinQues,
  name,
  value,
  setFieldValue,
  onChange,
  onBlur,
  error,
  resetForm
}) {
  const intl = useIntl();
  const loginData = useSelector(state => state?.auth);
  const userRole = useUserRole();
  const isSchoolAdmin = checkSchoolAdmin(userRole);

  const onAddAnsViewHandler = (ques, quesIndex) => {
    const quesInfo = {
      ...ques,
      index: quesIndex + 1,
    };
    setSelectedQues(quesInfo);
    setShowAddAnsView(true);
  };

  const onBackToQuestions = () => {
    setSelectedQues(null);
    setShowAddAnsView(false);
    resetForm();
  };

  const onSubmitAnswer = ques => e => {
    if (ques?.answers?.answer_id) {
      const isEdit = true;
      onAddAnswer(ques, isEdit);
    } else {
      const isEdit = false;
      onAddAnswer(ques, isEdit);
    }
  };

  const onEditAnsViewHandler = (ques, quesIndex) => {
    if (ques?.answers?.answer_id) {
      const ansValue = ques?.answers?.answer;
      const quesInfo = {
        ...ques,
        index: quesIndex + 1,
      };
      setSelectedQues(quesInfo);
      setFieldValue('answer', ansValue);
      setShowAddAnsView(true);
    }
  };

  const staffLoginId = getStaffLoginId(loginData, userRole);

  return (
    <>
      {showAddAnsView ? (
        <AddStaffAnswerView
          ques={selectedQues}
          onBack={onBackToQuestions}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          onSubmitAnswer={onSubmitAnswer}
        />
      ) : (
        <div className="staffQuesAnsListContainer">
          <div class="questionsAskedLabel">
            {intl.formatMessage({id: 'label.questionAskStudents'})}
          </div>
          {quesAnsData?.map((quesOpt, index) => (
            <div className="staffQuesAnsWrapper">
              <div className="staffQuesIndex">{`${index + 1}. `}</div>

              <div className="staffQuesAnsDetailsContainer">
                <div className="quesAnsDetails">
                  <div className="staffQuesText">{quesOpt?.question}</div>

                  <div className="submit-ansWrapper">
                    <div className="staffAnsText">
                      {quesOpt?.answers?.answer}
                    </div>
                  </div>

                  <div className="quesAskedByWrapper">
                    <div className="askByDetailsContainer">
                      <div className="askByText">
                        {intl.formatMessage({id: 'label.askedBy'})}
                      </div>
                      <div className="askByPerson">{`${quesOpt?.first_name} ${quesOpt?.last_name}`}</div>
                    </div>

                    {!isSchoolAdmin && quesOpt?.answers === null && (
                      <div className="askByDetailsContainer">
                        <img
                          className="pinDot"
                          src="/images/ellipsePinnedDot.svg"
                        />
                        <div className="askMonths">
                          <div
                            className="submitAnsText"
                            onClick={() => onAddAnsViewHandler(quesOpt, index)}>
                            {'Answer'}
                          </div>
                        </div>
                      </div>
                    )}

                    {!isSchoolAdmin &&
                      quesOpt?.answers?.answer_id &&
                      staffLoginId ===
                        quesOpt?.answers?.created_by?.staff_id && (
                        <div className="askByDetailsContainer">
                          <img
                            className="pinDot"
                            src="/images/ellipsePinnedDot.svg"
                          />
                          <div className="askMonths">
                            <div
                              className="submitAnsText"
                              onClick={() =>
                                onEditAnsViewHandler(quesOpt, index)
                              }>
                              {'Edit Answer'}
                            </div>
                          </div>
                        </div>
                      )}

                    <div className="askByDetailsContainer">
                      <img
                        className="pinDot"
                        src="/images/ellipsePinnedDot.svg"
                      />
                      <div className="askMonths">
                        <div className="askMonthsText">
                          {getTimeAgo(quesOpt?.date)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {!isSchoolAdmin && quesOpt?.answers?.answer_id && (
                <img
                  src={
                    quesOpt?.is_pinned
                      ? '/images/pinned.svg'
                      : '/images/notPinned.svg'
                  }
                  alt="Question status"
                  className="pin-star"
                  onClick={() => onPinQues(quesOpt)}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
}
