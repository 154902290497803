import {toast} from 'react-toastify';

const DEFAULT_AUTO_CLOSE = 3000;
const DEFAULT_POSITION = 'top-right';
const DEFAULT_THEME = 'colored';

export const showToast = (message, type = 'default', onClick = () => {}) => {
  const toastOptions = {
    position: DEFAULT_POSITION,
    autoClose: DEFAULT_AUTO_CLOSE,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    type: ['info', 'success', 'warning', 'error', 'default'].includes(type)
      ? type
      : 'default',
    theme: DEFAULT_THEME,
    onClick,
  };

  toast(message, toastOptions);
};

// Allow passing additional options to customize the toast further
export const showToastWithCustomOptions = (message, options = {}) => {
  const toastOptions = {
    position: DEFAULT_POSITION,
    autoClose: DEFAULT_AUTO_CLOSE,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: DEFAULT_THEME,
    ...options,
  };

  toast(message, toastOptions);
};
