import React, {useEffect, useState, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchSchoolList,
  updateSchoolStatus,
  getDistricts,
  fetchStates,
} from '../../store/actions/schoolActions';
import {
  uploadTemplate,
  downloadTemplate,
} from '../../store/actions/bulkImportActions';
import CustomTable from '../../components/CustomTable/CustomTable.js';
import style from './ManageSchool.scss';
import SearchFilterSection from '../../components/SearchFilterSection/SearchFilterSection.js';
import {PiDotsThreeVertical} from 'react-icons/pi';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';
import {useNavigate} from '../../routes';
import {ADD_SCHOOL_PATH, UPDATE_SCHOOL_PATH} from '../../constants/routePaths';
import {useIntl} from 'react-intl';
import ImportBulkModal from '../../components/ImportBulkModal/ImportBulkModal.js';
import {SCHOOL_TEMPLATE_TYPE} from '../../utilityFunction/constants.js';
import DeactivateModal from '../../components/DeactivateModal/DeactivateModal.js';
import {
  getLocalizedMessage,
  getStatusText,
} from '../../utilityFunction/helper.js';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';

function ManageSchoolListing() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const isAuthenticated = useSelector(state => state?.auth?.isAuthenticated);
  const schoolList = useSelector(
    state => state?.school?.schoolList?.data?.data,
  );
  const StatesList = useSelector(state => state?.school?.StatesList?.data);
  const districtsList = useSelector(state => state?.school?.districtData);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedSchoolDistrict, setSelectedSchoolDistrict] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState('asc');
  const [loading, setLoading] = useState(false);
  const [showBulkImportModal, setShowBulkImportModal] = useState(false);
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(null);

  useEffect(() => {
    const getData = async () => {
      await dispatch(fetchStates());
      await dispatch(getDistricts({}));
    };
    getData();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchSchoolData();
    }
  }, [
    isAuthenticated,
    searchTerm,
    selectedSchoolDistrict,
    selectedState,
    currentPage,
    sortOrder,
  ]);

  useEffect(() => {
    setSelectedSchoolDistrict('');
  }, [selectedState]);

  const fetchSchoolData = async () => {
    try {
      await dispatch(
        fetchSchoolList({
          searchTerm,
          districtId: selectedSchoolDistrict,
          stateId: selectedState,
          currentPage,
          sortOrder,
          loaderDispatch,
        }),
      );
    } catch (error) {
      console.log('Error fetching school data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleStateChange = event => {
    const stateId = event.target.value;
    setSelectedState(stateId);
    dispatch(getDistricts({stateId}));
  };

  const handleSchoolDistrictChange = event => {
    setSelectedSchoolDistrict(event.target.value);
  };

  const handleSort = () => {
    if (loading) return;
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';

    setLoading(true);
    setSortOrder(newSortOrder);
    fetchSchoolData(newSortOrder);
  };

  const handleViewAll = () => {
    setSelectedState('');
    setSelectedSchoolDistrict('');
    setCurrentPage(1);
    fetchSchoolData();
  };

  const handleAvatarClick = school => {
    if (selectedSchool?.id === school?.id) setSelectedSchool(null);
    else setSelectedSchool(school);
  };

  const viewProfile = id => {
    navigate(`${UPDATE_SCHOOL_PATH.replace(':id', id)}`);
  };

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const handleAddSchool = () => {
    navigate(ADD_SCHOOL_PATH);
  };

  const templateDownloadHandler = async () => {
    dispatch(
      downloadTemplate({
        templateType: SCHOOL_TEMPLATE_TYPE,
        intl,
        templateName: 'School_Template.xlsx',
        loaderDispatch,
      }),
    );
    setShowBulkImportModal(false);
  };

  const fileImportHandler = async file => {
    dispatch(uploadTemplate({file, url: '/bulk-upload-school', intl})).then(
      () => {
        fetchSchoolData();
        setShowBulkImportModal(false);
      },
    );
  };

  const onDeactivate = () => {
    if (selectedSchool?.id) {
      setOpenDeactivateModal(false);
      dispatch(updateSchoolStatus({id: selectedSchool?.id, intl})).then(() => {
        setSelectedSchool(null);
        fetchSchoolData();
      });
    }
  };

  const TABLE_HEAD = [
    {
      id: 'schoolName',
      label: intl.formatMessage({id: 'label.school-name-address'}),
      alignRight: false,
      dataKey: '',
      renderColumn: school => {
        return (
          <div key={school.id} className="s_name_address d-flex">
            <div className="s_logo">
              <img
                src={
                  school?.media
                    ? school?.media?.path
                    : '/images/schoolPlaceholder.jpg'
                }
                alt="School Logo"
              />
            </div>
            <div>
              <div className="s_name">{school.name}</div>
              <div className="s_address">{school.address}</div>
            </div>
          </div>
        );
      },
    },
    {
      id: 'email',
      label: intl.formatMessage({id: 'label.school-email'}),
      alignRight: false,
      dataKey: 'email',
    },
    {
      id: 'contact',
      label: intl.formatMessage({id: 'label.contact'}),
      alignRight: false,
      dataKey: 'contact_no',
    },
    {
      id: 'licenseStatus',
      label: intl.formatMessage({id: 'label.license'}),
      alignRight: false,
      dataKey: 'license_status',
      renderColumn: school => {
        return (
          <div className="d-flex actions-wrapper">
            <div
              className={`${
                school.license_status === 'active'
                  ? 'school-active'
                  : 'school-deactivate'
              }`}>
              {school.license_status}
            </div>
          </div>
        );
      },
    },
    {
      id: 'actions',
      label: intl.formatMessage({id: 'label.actions'}),
      alignRight: false,
      dataKey: '',
      renderColumn: school => {
        return (
          <div className="d-flex actions-wrapper">
            {/* <div className="actions gap-4">
              <img src="/images/message-text.png" alt="message-chat" />
              <img src="/images/send.svg" alt="send" />
            </div> */}
            <div
              className={
                school?.id === selectedSchool?.id
                  ? 'edit-delete selected-action'
                  : 'edit-delete'
              }>
              <div style={{position: 'relative'}}>
                <PiDotsThreeVertical
                  onClick={() => handleAvatarClick(school)}
                />
                {school?.id === selectedSchool?.id && (
                  <DropdownMenu isOpen={true}>
                    <div
                      className="pointer"
                      onClick={() => viewProfile(school?.id)}>
                      {intl.formatMessage({id: 'label.view-profile'})}
                    </div>

                    <div
                      className="pointer"
                      onClick={() => setOpenDeactivateModal(true)}>
                      {getStatusText(school?.is_active, intl)}
                    </div>
                  </DropdownMenu>
                )}
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const getDistrictList = () => {
    return districtsList?.map(opt => ({
      ...opt,
      id: opt?.district_id,
      name: opt?.district_name,
    }));
  };

  return (
    <div>
      <div className="d-flex student-detail-wrapper">
        <div className="school-label">
          {intl.formatMessage({id: 'label.details-schools'})}{' '}
        </div>
        <div className="add-import-btn">
          <div className="add-student-btn">
            <button onClick={handleAddSchool}>
              {' '}
              <img src="/images/add.svg" alt="add" />{' '}
              {intl.formatMessage({id: 'label.add-school'})}
            </button>
          </div>
          <div className="import-btn">
            <button onClick={() => setShowBulkImportModal(true)}>
              {' '}
              <img className="me-1" src="/images/importIcon.svg" />{' '}
              {intl.formatMessage({id: 'label.import'})}
            </button>
          </div>
        </div>
      </div>

      <SearchFilterSection
        optionsOne={StatesList || []}
        valueOne={selectedState}
        handleOptionOneChange={handleStateChange}
        optionsTwo={getDistrictList() || []}
        valueTwo={selectedSchoolDistrict}
        handleOptionTwoChange={handleSchoolDistrictChange}
        setSearchTerm={setSearchTerm}
        setCurrentPage={setCurrentPage}
        handleSort={handleSort}
        sortOrder={sortOrder}
        handleViewAll={handleViewAll}
        onSearchChange={e => {
          setSearchTerm(e.target.value);
          setCurrentPage(1);
        }}
        filterOnePlaceholder={intl.formatMessage({
          id: 'addDistrict.addStateName',
        })}
        filterTwoPlaceholder={intl.formatMessage({id: 'label.selectdistricts'})}
        searchPlaceholder={intl.formatMessage({id: 'label.search_name_zip'})}
      />

      <CustomTable
        tableHead={TABLE_HEAD}
        tableData={schoolList?.data}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        totalPages={schoolList?.last_page}
        totalCount={schoolList?.total}
      />

      {showBulkImportModal && (
        <ImportBulkModal
          open={showBulkImportModal}
          handleClose={() => setShowBulkImportModal(false)}
          modalTitle={'Bulk Upload'}
          onDownloadTemplate={templateDownloadHandler}
          onImportFile={fileImportHandler}
        />
      )}

      {openDeactivateModal && (
        <DeactivateModal
          open
          setOpen={setOpenDeactivateModal}
          handleDeactivate={onDeactivate}
          confirmText={getStatusText(selectedSchool?.is_active, intl)}
          modalContentText={
            !selectedSchool?.is_active
              ? getLocalizedMessage(intl, 'school.statusModal.activate')
              : getLocalizedMessage(intl, 'school.statusModal.deactivate')
          }
        />
      )}
    </div>
  );
}

export default ManageSchoolListing;
