import { createSlice } from '@reduxjs/toolkit';
import { fetchModuleSectionList, fetchSectionObjectsList } from '../actions/sectionActions';

const initialState = {
  sectionsList: [],
  sectionObjectsList: [],
};

const sectionSlice = createSlice({
  name: 'section',
  initialState,
  reducers: {
    resetSectionsList(state) {
      state.sectionsList = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchModuleSectionList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchModuleSectionList.fulfilled, (state, action) => {
        state.loading = false;
        state.sectionsList = action.payload;
        state.error = null;
      })
      .addCase(fetchModuleSectionList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchSectionObjectsList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSectionObjectsList.fulfilled, (state, action) => {
        state.loading = false;
        state.sectionObjectsList = action.payload;
        state.error = null;
      })
      .addCase(fetchSectionObjectsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetSectionsList } = sectionSlice.actions;

export default sectionSlice.reducer;
