import React, { useEffect, useContext, useState } from 'react';
import CustomDialogue from '../../../components/CustomDialogue/CustomDialogue';
import InputLabel from '../../../components/InputLabel/InputLabel';
import { useIntl } from 'react-intl';
import { addHelpFieldName } from '../../../validationSchema/addHelpSchema.js';
import AttachFileCard from '../../../components/AttachFileCard/AttachFileCard.js';
import { handleUploadFile } from '../../../store/actions/fileActions';
import { useDispatch } from 'react-redux';
import { GlobalLoaderContext } from '../../../globalContext/globalLoader/globalLoaderProvider';

const AddHelp = ({
  open,
  handleClose,
  handleSubmit,
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  helpData,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const [mediaId, setMediaId] = useState(null);
  const [fileName, setFileName] = useState('');
  useEffect(() => {
    setFieldValue(addHelpFieldName.MEDIA, mediaId);
  }, [mediaId, setFieldValue]);

  const handleFileUpload = async (file) => {
    setFileName(file.name);
    dispatch(handleUploadFile({ file, intl, loaderDispatch }))
      .unwrap()
      .then(mediaId => {
        setMediaId(mediaId.media_id);
      })
      .catch(error => {
        console.error('Upload failed', error);
      });
  };

  return (
    <CustomDialogue
      open={open}
      handleClose={handleClose}
      cancelText={intl.formatMessage({
        id: 'label.cancel',
      })}
      confirmText={intl.formatMessage({
        id: 'label.save',
      })}
      onCancel={handleClose}
      hideTitle={false}
      onSubmit={handleSubmit}>
      <div className="container">
        <div className="row mt-4">
          <div className="mb-3 col-md-12">
          <InputLabel
              name={addHelpFieldName.TITLE}
              label={intl.formatMessage({
                id: 'label.title',
              })}
              placeholder={intl.formatMessage({
                id: 'label.enter_pdf_title',
              })}
              required
              value={values[addHelpFieldName.TITLE]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[addHelpFieldName.TITLE] &&
                errors[addHelpFieldName.TITLE]
              }
            />
            <AttachFileCard
              onFileUpload={file => handleFileUpload(file)}
              isOnlyPdf
              filePreviewName={fileName}
              error={
                touched[addHelpFieldName.MEDIA] &&
                errors[addHelpFieldName.MEDIA]
              }
            />
          </div>
        </div>
      </div>
    </CustomDialogue>
  );
};

export default AddHelp;
