import axiosPrivateInstance from '../api/axiosPrivateInstance';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../services/local-storage';

export async function refreshToken() {
  try {
    const refreshToken = getLocalStorageItem('refreshToken');
    const response = await axiosPrivateInstance.post('/refresh', {
      refresh_token: refreshToken,
    });
    const {access_token, refresh_token, expires_in} = response.data.data;

    const newExpiryTime = Math.floor(Date.now() / 1000) + expires_in;
    setLocalStorageItem('accessToken', access_token);
    setLocalStorageItem('refreshToken', refresh_token);
    setLocalStorageItem('expiryTime', newExpiryTime);
    return access_token;
  } catch (error) {
    throw error;
  }
}
