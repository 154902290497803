import * as Yup from 'yup';

export const editIslandDetailsFormFieldNames = {
  ISLAND_NAME: 'name',
  ISLAND_DESCRIPTION: 'description',
};

export const editIslandDetailsFormInitialValues = {
  [editIslandDetailsFormFieldNames.ISLAND_NAME]: '',
  [editIslandDetailsFormFieldNames.ISLAND_DESCRIPTION]: '',
};

export const validationSchema = intl => {
  return Yup.object().shape({
    [editIslandDetailsFormFieldNames.ISLAND_NAME]: Yup.string()
      .required(intl.formatMessage({id: 'editIsland.fieldError.name.required'}))
      .min(2, intl.formatMessage({id: 'editIsland.fieldError.name.minLength'}))
      .max(
        20,
        intl.formatMessage({id: 'editIsland.fieldError.name.maxLength'}),
      ),

    [editIslandDetailsFormFieldNames.ISLAND_DESCRIPTION]: Yup.string()
      .required(intl.formatMessage({id: 'editIsland.fieldError.desc.required'}))
      .min(10, intl.formatMessage({id: 'editIsland.fieldError.desc.minLength'}))
      .max(
        200,
        intl.formatMessage({id: 'editIsland.fieldError.desc.maxLength'}),
      ),
  });
};
