import React from 'react';
import {useIntl} from 'react-intl';
import './LessonContentHeader.scss';

export default function LessonContentHeader({
  myLessonInformation,
  isStudentRole,
  onBack,
}) {
  const intl = useIntl();

  return (
    <div className="module-header">
      {!isStudentRole && (
        <img
          src="/images/modalBackIcon.svg"
          alt="Back icon"
          onClick={onBack}
          className="docs-view-icon"
        />
      )}
      <div className="module-name">{myLessonInformation?.name}</div>
      <div className="module-header-right-wrapper">
        <img src="/images/clock.svg" alt="Lesson timer" />
        <div className="lesson-readtime">
          {myLessonInformation?.minimum_read_time}
          <span className="ms-1">{intl.formatMessage({id: 'label.mins'})}</span>
        </div>
      </div>
    </div>
  );
}
