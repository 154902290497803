import React, {useContext} from 'react';
import {useDispatch} from 'react-redux';
import {useTheme} from '@unthinkable/react-theme';
import {useIntl} from 'react-intl';
import CustomDialogue from '../../components/CustomDialogue/CustomDialogue';
import {fetchDistricts} from '../../store/actions/schoolActions';
import {disableDistrictData} from '../../store/actions/districtActions';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';
import {commonStyle} from '../../Style/commonStyle';

export default function DisableDistrictModal({
  open,
  setOpen,
  disableRowData,
  setDisableRowData,
  currentPage,
}) {
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const dispatch = useDispatch();
  const intl = useIntl();
  const icons = useTheme('icons');
  const {circleInfo} = icons;

  const closeDisablePopup = () => {
    setOpen(false);
    setDisableRowData(null);
  };

  const handleDisable = () => {
    setOpen(false);
    dispatch(disableDistrictData({districtId: disableRowData?.district_id, intl}));
    dispatch(fetchDistricts({
        isPaginate: true,
        currentPage: currentPage,
        loaderDispatch,
      }),
    );
    setDisableRowData(null);
  };

  return (
    <CustomDialogue
      open={open}
      onCancel={closeDisablePopup}
      cancelText={getLocalizedMessage(intl, 'label.cancel')}
      confirmText={getLocalizedMessage(intl, 'label.deactivate')}
      dialogueTitle={getLocalizedMessage(intl, 'label.title.disableDistrict')}
      className={'center-btn'}
      onSubmit={handleDisable}
      hideTitle
      isDisableModal>
      <div style={commonStyle.textAlignCenter}>
        <img src={circleInfo} />
        <div style={commonStyle.modalContentTextStyle}>
          {getLocalizedMessage(intl, 'disableDistict.disableModal.text', {
            districtName: disableRowData?.district_name,
          })}
        </div>
      </div>
    </CustomDialogue>
  );
}
