import React, {useContext, useState, useEffect} from 'react';
import './StudentAttachmentsView.scss';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import {useIntl} from 'react-intl';
import ContentWrapper from '../../StudentViewLessonContent/ContentWrapper';
import ThreeDButton from '../../../../components/ThreeDButton/ThreeDButton';
import {
  getAttachmentById,
  handleStoreAttachment,
} from '../../../../store/actions/fileActions';
import {GlobalLoaderContext} from '../../../../globalContext/globalLoader/globalLoaderProvider';
import {setLoading} from '../../../../globalContext/globalLoader/globalLoaderAction';

export default function StudentAttachmentsView({currentLesson}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const authData = useSelector(state => state?.auth);
  const studentId = authData?.data?.data?.data?.user?.student_data?.id;

  useEffect(() => {
    const fetchAttachments = async () => {
      try {
        const attachments = await dispatch(
          getAttachmentById({
            lessonId: currentLesson.id,
            intl,
            loaderDispatch,
          }),
        ).unwrap();
        setUploadedFiles(attachments);
      } catch (error) {
        console.error('Failed to fetch attachments', error);
      }
    };

    fetchAttachments();
  }, [dispatch, currentLesson, intl, loaderDispatch]);

  const formik = useFormik({
    initialValues: {
      file: null,
    },
    onSubmit: async values => {
      if (values.file) {
        try {
          const uploadedFile = await dispatch(
            handleStoreAttachment({
              lesson_id: currentLesson.id,
              attachment: values.file,
              intl,
              loaderDispatch,
            }),
          ).unwrap();
          setUploadedFiles([...uploadedFiles, uploadedFile]);
          const file = document.querySelector('input[name="file"]');
          file.value = '';
          values.file = null;
        } catch (error) {
          console.error('Upload failed', error);
        }
      }
    },
  });

  const handleFileChange = event => {
    formik.setFieldValue('file', event.currentTarget.files[0]);
  };

  const handleDownload = async attachment_path => {
    try {
      loaderDispatch(setLoading(true));

      const downloadLink = document.createElement('a');
      downloadLink.href = attachment_path;
      downloadLink.download = attachment_path.split('/').pop(); // Extract the file name from the path
      downloadLink.target = '_blank'; // Ensure it does not redirect the current page
      document.body.appendChild(downloadLink); // Append to the body
      downloadLink.click();

      // Clean up
      document.body.removeChild(downloadLink);

      loaderDispatch(setLoading(false));
    } catch (error) {
      console.error('Download failed', error);
      loaderDispatch(setLoading(false)); // Ensure to stop loading state in case of error
    }
  };

  const extractAttachment = url => {
    const match = url.match(/\/attachment\/(.+)/);
    return match ? match[1] : null;
  };

  return (
    <>
      <div className="text-md mb-3">Upload Attachments</div>
      <form
        className="d-flex align-items-center mb-4"
        onSubmit={formik.handleSubmit}>
        <input
          type="file"
          name="file"
          onChange={handleFileChange}
          className="file-input"
        />
        <button className="btn btn-outline-primary btn-md ms-2" type="submit">
          Upload
        </button>
      </form>

      {uploadedFiles ? (
        <>
          <div className="text-md mb-3">Uploaded Files</div>
          <div className="d-flex flex-column gap-2">
            {uploadedFiles.map((file, index) => (
              <span
                key={index}
                onClick={() => handleDownload(file.attachment_path)}
                className="link-primary download-attachment-link">
                {extractAttachment(file.attachment_path)}
              </span>
            ))}
          </div>
        </>
      ) : null}
    </>
  );
}
