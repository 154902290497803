import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {setLoading} from '../../globalContext/globalLoader/globalLoaderAction';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {showToast} from '../../components/Toast/Toast';

export const getStudentQuesAns = createAsyncThunk(
  'student/quesAns',
  async ({lessonId, search}, thunkAPI) => {
    try {
      const params = {
        lesson_id: lessonId,
        search: search,
      };
      const response = await axiosPrivateInstance.get('/questions', {
        params,
      });
      return response?.data?.data?.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);

export const addQuestionInLesson = createAsyncThunk(
  'student/addQuestion',
  async ({payload, intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.post('/questions', payload);
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'success.questionAdded'), 'success');
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const deleteQuestion = createAsyncThunk(
  'student/deleteQuestion',
  async ({quesId, intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.delete(
        `/questions/${quesId}`,
      );
      loaderDispatch(setLoading(false));
      showToast(
        getLocalizedMessage(intl, 'success.questionDeleted'),
        'success',
      );
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const pinQuestion = createAsyncThunk(
  'question/Pinned',
  async ({quesId, intl, loaderDispatch}, thunkAPI) => {
    try {
      loaderDispatch(setLoading(true));
      const response = await axiosPrivateInstance.patch(
        `questions/pinned/${quesId}`,
      );
      showToast(getLocalizedMessage(intl, 'success.quesPinned'), 'success');
      loaderDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const unPinQuestion = createAsyncThunk(
  'question/unPinned',
  async ({quesId, intl, loaderDispatch}, thunkAPI) => {
    try {
      loaderDispatch(setLoading(true));
      const response = await axiosPrivateInstance.patch(
        `questions/unpinned/${quesId}`,
      );
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'success.quesUnpinned'), 'success');
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const getQuesAnsList = createAsyncThunk(
  'all/QuesAns',
  async ({currentPage, search, loaderDispatch}, thunkAPI) => {
    try {
      loaderDispatch(setLoading(true));
      const params = {
        per_page: 10,
        page: currentPage,
        ...(search && {search: search}),
      };
      const response = await axiosPrivateInstance.get('/questions', {
        params,
      });
      loaderDispatch(setLoading(false));
      return response?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      throw error.response.data.message;
    }
  },
);

export const submitAnswer = createAsyncThunk(
  'addQuestion/answer',
  async ({ansData, intl, loaderDispatch}, thunkAPI) => {
    try {
      loaderDispatch(setLoading(true));
      const response = await axiosPrivateInstance.post('/answers', ansData);
      loaderDispatch(setLoading(false));
      showToast(
        getLocalizedMessage(intl, 'success.answerSubmitted'),
        'success',
      );
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const updateAnswer = createAsyncThunk(
  'update/Answer',
  async ({ansId, ansData, intl, loaderDispatch}, thunkAPI) => {
    try {
      loaderDispatch(setLoading(true));
      const response = await axiosPrivateInstance.patch(
        `/answers/${ansId}`,
        ansData,
      );
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'success.answerUpdated'), 'success');
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);
