import React from 'react';
import { GoDotFill } from 'react-icons/go';
import { IoCheckmark } from 'react-icons/io5';

function ProgressBar({ currentStep, allSteps }) {
  return (
    <div className="progress-bar">
      <div className="counter">
        {allSteps.map((stepTitle, index) => (
          <div
            key={index}
            className={
              currentStep === index + 1
                ? 'active'
                : currentStep > index + 1
                ? 'filled'
                : ''
            }>
            <div className="step">
              {currentStep > index + 1 ? <IoCheckmark /> : <GoDotFill />}
            </div>
            {index !== allSteps.length - 1 && <span></span>} {/* Hide <span> for the last step */}
            <div className='step_title'>{stepTitle}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProgressBar;
