import React, { useState, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@unthinkable/react-button';
import { View, Text, TextInput } from '@unthinkable/react-core-components';
import { useDispatch } from 'react-redux';
import { resetPassword, setPassword } from '../../store/actions/authActions';
import styles from './ResetPassword.style';
import { useTheme } from '@unthinkable/react-theme';
import { useLocation } from 'react-router-dom';
import { validatePassword } from '../../core/validation';
import { IoEye, IoEyeOff } from 'react-icons/io5';
import { usePasswordVisibility } from '../../core/helpers/utils';
import { GlobalLoaderContext } from '../../globalContext/globalLoader/globalLoaderProvider';
import {getLocalStorageItem} from '../../services/local-storage';
import { LOGIN_PATH } from '../../constants/routePaths';

function ResetPassword() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const [passwordValue, setPasswordValue] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const icons = useTheme('icons');
  const { logo } = icons;
  const [showPassword, togglePasswordVisibility] = usePasswordVisibility();
  const [showConfirmPassword, toggleConfirmPasswordVisibility] = usePasswordVisibility();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);

  useEffect(() => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const token = params.get('token');
    const emailMatch = queryString.match(/(?:^|&)email=([^&]*)/);
    const encodedEmail = emailMatch ? emailMatch[1] : null;
    const email = encodedEmail ? decodeURIComponent(encodedEmail.replace(/\+/g, '%20').replace(/%20/g, '+')) : null;
    setToken(token);
    setEmail(email);
  }, [location.search]);

  const handlePasswordChange = value => {
    setPasswordValue(value);
    if (!validatePassword(value)) {
      setError(intl.formatMessage({ id: 'label.password-criteria' }));
    } else {
      setError('');
    }
  };

  const navigateToLogin = () => {
    window.location.href = LOGIN_PATH;
  };

  const onResetPassword = async () => {
    setIsProcessing(true);
    try {
      if (passwordValue !== confirmPassword) {
        throw new Error(intl.formatMessage({ id: 'label.password-no-match' }));
      }
      if (!validatePassword(passwordValue)) {
        throw new Error(intl.formatMessage({ id: 'label.password-criteria' }));
      }
      if (token && email && passwordValue) {
        const response = await dispatch(resetPassword({ token, email, password: passwordValue }));
        if (resetPassword.fulfilled.match(response)) {
          setIsProcessing(false);
          setSuccess(true);
        } else {
          setIsProcessing(false);
          setError('An error occurred while resetting password');
        }
      } else {
        const setPasswordToken = getLocalStorageItem('accessToken');
        if (!setPasswordToken) {
          throw new Error('Access token not found in local storage');
        }
        const response = await dispatch(setPassword({ token: setPasswordToken, password: passwordValue, intl, setError, loaderDispatch, dispatch }));
        if (setPassword.fulfilled.match(response)) {
          setIsProcessing(false);
          setSuccess(true);
        } else {
          setIsProcessing(false);
          setError('An error occurred while setting password');
        }
      }
    } catch (error) {
      setIsProcessing(false);
      setError(error.message);
    }
  };

  const isResetPasswordActionDisabled = !passwordValue.trim() || !confirmPassword.trim();

  return (
    <View className="reset-password-wrapper login-wrapper">
      <View className="reset-password-container login-container">
        <img style={styles.img} src={logo} alt="Gradrock Logo" />
        {success ? (
          <Text style={styles.header}>
            <h3 style={styles.title}>
              {intl.formatMessage({ id: 'label.reset-password' })}
            </h3>
            <p className="subtitle">
              {intl.formatMessage({ id: 'label.password-reset-success' })}
            </p>
          </Text>
        ) : (
          <Text style={styles.header}>
            <h3 style={styles.title}>
              {intl.formatMessage({ id: 'label.new-password' })}
            </h3>
            <p className="subtitle">
              {intl.formatMessage({ id: 'label.new-password-subtitle' })}
            </p>
          </Text>
        )}
        {!success && (
          <>
            <div className="password-input mb-3">
              <TextInput
                type={showPassword ? 'text' : 'password'}
                placeholder={intl.formatMessage({
                  id: 'label.enter-new-password',
                })}
                onChangeText={handlePasswordChange}
                value={passwordValue}
                className={error ? 'input-error form-control' : 'form-control'}
              />
              <div
                className="password-toggle"
                onClick={togglePasswordVisibility}>
                {showPassword ? <IoEyeOff /> : <IoEye />}
              </div>
            </div>
            <div className="error">
              {error && <Text style={styles.error}>{error}</Text>}
            </div>
            <div className="password-input mb-3">
              <TextInput
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder={intl.formatMessage({ id: 'label.re-new-password' })}
                onChangeText={setConfirmPassword}
                value={confirmPassword}
                className={error ? 'input-error form-control' : 'form-control'}
              />
              <div
                className="password-toggle"
                onClick={toggleConfirmPasswordVisibility}>
                {showConfirmPassword ? <IoEyeOff /> : <IoEye />}
              </div>
            </div>
          </>
        )}
        <Button
          text={
            success
              ? intl.formatMessage({ id: 'label.back-to-login' })
              : intl.formatMessage({ id: 'label.submit' })
          }
          onPress={
            success ? navigateToLogin() : onResetPassword
          }
          disabled={success || isResetPasswordActionDisabled}
          disabledContainerStyle={{ opacity: 1 }}
          containerStyle={styles.button}
          className="button-text"
        />
      </View>
    </View>
  );
}

export default ResetPassword;
