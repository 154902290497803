import {createSlice} from '@reduxjs/toolkit';
import {
    fetchBookmark,
} from '../actions/bookmarkActions';

const initialState = {
    bookmarkList:[],
};

const bookmarkSlice = createSlice({
  name: 'bookmark',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchBookmark.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBookmark.fulfilled, (state, action) => {
        state.loading = false;
        state.bookmarkList = action.payload;
        state.error = null;
      })
      .addCase(fetchBookmark.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default bookmarkSlice.reducer;
