import React, {useState} from 'react';
import DocViewer, {DocViewerRenderers} from '@cyntler/react-doc-viewer';
import {Document, Page, pdfjs} from 'react-pdf';
import {
  getMimeTypeFromFileExtension,
  isAttachmentAnImage,
  isAttachmentAnPdf,
} from '../../utilityFunction/helper';
import './StudentDocViewer.scss';

export default function StudentDocViewer({mediaPath, docViewerClasssName}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const checkIsAttachmentAnImage = isAttachmentAnImage(mediaPath);
  const checkIsAttachmentAnPdf = isAttachmentAnPdf(mediaPath);

  const docs = !!mediaPath
    ? [
        {
          uri: mediaPath,
          fileType: getMimeTypeFromFileExtension(mediaPath),
        },
      ]
    : [];

  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    <div className={docViewerClasssName}>
      {checkIsAttachmentAnImage ? (
        <RenderImageDocView mediaPath={mediaPath} />
      ) : checkIsAttachmentAnPdf ? (
        <>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Document file={mediaPath} onLoadSuccess={onDocumentLoadSuccess}>
              {Array.from(new Array(numPages), (el, index) => (
                <React.Fragment key={`fragment_page_${index + 1}`}>
                  <Page
                    key={`page_${index + 1}`}
                    pageNumber={index + 1}
                    className="pdf-page"
                  />
                  {index + 1 < numPages && (
                    <div className="pdf-page-separator" />
                  )}
                </React.Fragment>
              ))}
            </Document>
          </div>
        </>
      ) : (
        <DocViewer
          prefetchMethod="GET"
          documents={docs}
          style={{height: 500}}
          config={{header: {disableHeader: true}}}
          pluginRenderers={DocViewerRenderers}
        />
      )}
    </div>
  );
}

const RenderImageDocView = ({mediaPath}) => {
  return (
    <div
      style={{
        height: 500,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
      }}>
      <img src={mediaPath} alt="File media" />
    </div>
  );
};
