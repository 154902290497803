import React, {useContext, useState, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  Image,
  TouchableOpacity,
  View,
} from '@unthinkable/react-core-components';
import {MediaQueryContext, useTheme} from '@unthinkable/react-theme';

import {TwoColumn, TwoRow} from '../../core/layouts';

import {useNavigate, useLocation} from '../../routes';

import Styles from './menu.style';
import './menu.scss';
import {
  ROOT_PATH,
  MANAGE_SCHOOLS_PATH,
  SCHOOL_ADMINS_PATH,
  PROFILE_PATH,
  MANAGE_SCHOOL_DISTRICTS_PATH,
  MANAGE_STUDENTS_PATH,
  MANAGE_STAFF_PATH,
  MANAGE_CONTENTS_PATH,
  UPDATE_SCHOOL_PATH,
  MANAGE_QUESTIONS_PATH,
  MANAGE_ANNOUNCEMENTS_PATH,
  MANAGE_SUPER_ADMIN,
  REPORTS_PATH,
  STAFF_STUDENT_ATTACHMENTS_PATH,
} from '../../constants/routePaths';
import userRoles from '../../constants/userRoles';
import {useIntl} from 'react-intl';
import useUserRole from '../../hooks/useUserRole';
import {exportStudentProgressReport} from '../../store/actions/progressReportActions';

function useMenu() {
  const intl = useIntl();
  const navigate = useNavigate();
  const {pathname: currentRoute} = useLocation();
  const navigateToHome = () => navigate(ROOT_PATH);
  const navigateToProfile = () => navigate(PROFILE_PATH);
  const userRole = useUserRole();

  return {
    navigateToHome,
    navigateToProfile,
    currentRoute,
    userRole,
    intl,
  };
}

function MenuContainer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {navigateToHome, navigateToProfile, currentRoute, userRole, intl} =
    useMenu();
  const icons = useTheme('icons');
  const {
    whiteLogo,
    homeOutline,
    homeSolid,
    profileOutline,
    profileSolid,
    dashboard,
    schoolSolid,
    schoolAdmin,
    settings,
    settingsSolid,
    students,
    document: documentIcon,
    announce,
    management,
  } = icons;
  const {current: currentBreakpoint} = useContext(MediaQueryContext);
  const [activeMenuItem, setActiveMenuItem] = useState(0);
  const dashboardIcon = currentRoute === '/' ? dashboard : dashboard;
  const schoolIcon =
    currentRoute === MANAGE_SCHOOLS_PATH ? schoolSolid : schoolSolid;
  const profileIcon =
    currentRoute === '/profile' ? profileSolid : profileOutline;
  const schoolAdminIcon =
    currentRoute === SCHOOL_ADMINS_PATH ? schoolAdmin : schoolAdmin;
  const settingIcon =
    currentRoute === MANAGE_SCHOOL_DISTRICTS_PATH ? settingsSolid : settings;
  const studentIcon =
    currentRoute === MANAGE_STUDENTS_PATH ? students : students;
  const staffIcon =
    currentRoute === MANAGE_STAFF_PATH ? profileSolid : profileOutline;
  const contentIcon =
    currentRoute === MANAGE_CONTENTS_PATH ? documentIcon : documentIcon;

  const schoolId = useSelector(
    state => state?.auth?.data?.data?.data?.user?.school?.id,
  );

  let menuItems;

  const handleMenuItemClick = async (redirectPath, index) => {
    if (redirectPath === REPORTS_PATH) {
      await exportProgressReport();
    }
    setActiveMenuItem(index);
    setTimeout(() => {
      navigate(redirectPath);
    }, 0);
  };

  const exportProgressReport = async () => {
    await dispatch(
      exportStudentProgressReport({
        schoolId,
      }),
    );
  };

  if (userRole == userRoles.SUPER_ADMIN) {
    menuItems = [
      {
        label: intl.formatMessage({id: 'label.dashboard'}),
        redirectPath: ROOT_PATH,
        icon: dashboardIcon,
      },
      {
        label: intl.formatMessage({id: 'label.manage-schools'}),
        redirectPath: MANAGE_SCHOOLS_PATH,
        icon: schoolIcon,
      },
      {
        label: intl.formatMessage({id: 'label.manage-staff'}),
        redirectPath: MANAGE_STAFF_PATH,
        icon: staffIcon,
      },
      {
        label: intl.formatMessage({id: 'label.manage-students'}),
        redirectPath: MANAGE_STUDENTS_PATH,
        icon: studentIcon,
      },
      {
        label: intl.formatMessage({id: 'label.manage-content'}),
        redirectPath: MANAGE_CONTENTS_PATH,
        icon: contentIcon,
      },
      {
        label: intl.formatMessage({id: 'label.system-settings'}),
        redirectPath: MANAGE_SCHOOL_DISTRICTS_PATH,
        icon: settingIcon,
      },
      {
        label: intl.formatMessage({id: 'label.tabs.Announcements'}),
        redirectPath: MANAGE_ANNOUNCEMENTS_PATH,
        icon: announce,
      },
      {
        label: intl.formatMessage({id: 'label.super_admin_management'}),
        redirectPath: MANAGE_SUPER_ADMIN,
        icon: management,
      },
      {
        label: intl.formatMessage({id: 'label.reports'}),
        redirectPath: REPORTS_PATH,
        icon: contentIcon,
      },
    ];
  } else if (userRole === userRoles.SCHOOL_ADMIN) {
    menuItems = [
      {
        label: intl.formatMessage({id: 'label.dashboard'}),
        redirectPath: ROOT_PATH,
        icon: dashboardIcon,
      },
      {
        label: intl.formatMessage({id: 'label.manage-staff'}),
        redirectPath: MANAGE_STAFF_PATH,
        icon: staffIcon,
      },
      {
        label: intl.formatMessage({id: 'label.manage-students'}),
        redirectPath: MANAGE_STUDENTS_PATH,
        icon: studentIcon,
      },
      {
        label: intl.formatMessage({id: 'label.manage-schools'}),
        redirectPath: `${UPDATE_SCHOOL_PATH.replace(':id', schoolId)}`,
        icon: schoolIcon,
      },
      {
        label: intl.formatMessage({id: 'label.view-contents'}),
        redirectPath: MANAGE_CONTENTS_PATH,
        icon: contentIcon,
      },
      {
        label: intl.formatMessage({id: 'label.reports'}),
        redirectPath: REPORTS_PATH,
        icon: contentIcon,
      },
    ];
  } else if (userRole === userRoles.SCHOOL_TEACHER) {
    menuItems = [
      {
        label: intl.formatMessage({id: 'label.dashboard'}),
        redirectPath: ROOT_PATH,
        icon: dashboardIcon,
      },
      {
        label: intl.formatMessage({id: 'label.manage-students'}),
        redirectPath: MANAGE_STUDENTS_PATH,
        icon: studentIcon,
      },
      {
        label: intl.formatMessage({id: 'label.view-contents'}),
        redirectPath: MANAGE_CONTENTS_PATH,
        icon: contentIcon,
      },
      {
        label: intl.formatMessage({id: 'label.manage-questions'}),
        redirectPath: MANAGE_QUESTIONS_PATH,
        icon: contentIcon,
      },
      {
        label: intl.formatMessage({id: 'label.student-attachments'}),
        redirectPath: STAFF_STUDENT_ATTACHMENTS_PATH,
        icon: contentIcon,
      },
    ];
  } else if (userRole === userRoles.COUNSELLOR) {
    menuItems = [
      {
        label: intl.formatMessage({id: 'label.dashboard'}),
        redirectPath: ROOT_PATH,
        icon: dashboardIcon,
      },
      {
        label: intl.formatMessage({id: 'label.manage-students'}),
        redirectPath: MANAGE_STUDENTS_PATH,
        icon: studentIcon,
      },
      {
        label: intl.formatMessage({id: 'label.view-contents'}),
        redirectPath: MANAGE_CONTENTS_PATH,
        icon: contentIcon,
      },
      {
        label: intl.formatMessage({id: 'label.manage-questions'}),
        redirectPath: MANAGE_QUESTIONS_PATH,
        icon: contentIcon,
      },
      {
        label: intl.formatMessage({id: 'label.student-attachments'}),
        redirectPath: STAFF_STUDENT_ATTACHMENTS_PATH,
        icon: contentIcon,
      },
    ];
  } else if (userRole === userRoles.STUDENT) {
    menuItems = [];
  } else {
    menuItems = [];
  }

  if (currentBreakpoint === 'md') {
    return (
      <TwoRow
        className="dashboard-menu-list"
        style={Styles.menuContainer}
        topSection={
          <div className="text-center">
            <Image
              className="sidebar-logo"
              source={whiteLogo}
              alt="Gradrock White Logo"
            />
          </div>
        }
        bottomSection={
          <TwoRow
            topSection={
              <>
                {menuItems.map((item, index) => (
                  <TouchableOpacity
                    key={index}
                    className={
                      activeMenuItem === index
                        ? 'active menu-item'
                        : 'menu-item'
                    }
                    style={Styles.menuList}
                    onPress={() =>
                      handleMenuItemClick(item.redirectPath, index)
                    }>
                    <Image source={item.icon} />
                    <span>{item.label}</span>
                  </TouchableOpacity>
                ))}
              </>
            }
            isTopFillSpace={true}
            isBottomFillSpace={false}
          />
        }
        isTopFillSpace={false}
        isBottomFillSpace={true}
      />
    );
  }

  return (
    <TwoColumn
      leftSection={
        <View style={{alignItems: 'center'}}>
          <TouchableOpacity
            style={{width: 20, height: 20, padding: 15}}
            onPress={navigateToHome}>
            <Image
              className="sidebar-logo"
              source={whiteLogo}
              alt="Gradrock White Logo"
            />
          </TouchableOpacity>
        </View>
      }
      rightSection={
        <View style={{alignItems: 'center'}}>
          <TouchableOpacity
            style={{width: 20, height: 20, padding: 15}}
            onPress={navigateToProfile}>
            <Image
              source={profileIcon}
              style={{width: '100%', height: '100%'}}
            />
          </TouchableOpacity>
        </View>
      }
      isLeftFillSpace={true}
      isRightFillSpace={true}
    />
  );
}
export default MenuContainer;
