import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {MdDisabledVisible} from 'react-icons/md';
import {FaRegEdit} from 'react-icons/fa';
import {Typography} from '@mui/material';
import CustomButton from '../../components/CustomButton/CustomButton';
import CustomTable from '../../components/CustomTable/CustomTable';
import AddDistrictModal from './AddDistrictModal';
import DisableDistrictModal from './DisableDistrictModal';
import {fetchDistricts} from '../../store/actions/schoolActions';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';
import {commonStyle} from '../../Style/commonStyle';

export default function ManageSchoolDistrictsListing() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const {isAuthenticated} = useSelector(state => state?.auth);
  const {districtsList} = useSelector(state => state?.school);
  const districtsListData = districtsList?.data || [];
  const [currentPage, setCurrentPage] = useState(1);
  const [openAddDistrictModal, setOpenAddDistrictModal] = useState(false);
  const [openDisableModal, setOpenDisableModal] = useState(false);
  const [editRow, setEditRow] = useState(null);

  useEffect(() => {
    const getDistrictsData = async () => {
      dispatch(
        fetchDistricts({
          isPaginate: true,
          currentPage: currentPage,
          loaderDispatch,
        }),
      );
    };

    if (isAuthenticated) getDistrictsData();
  }, [isAuthenticated, currentPage]);

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const TABLE_HEAD = [
    {
      id: 'countryName',
      label: intl.formatMessage({id: 'label.country-name'}),
      width: '27%',
      renderColumn: row => {
        return (
          <Typography variant="subtitle">
            {row?.country?.name || '-'}
          </Typography>
        );
      },
    },
    {
      id: 'stateName',
      label: intl.formatMessage({id: 'label.state-name'}),
      width: '27%',
      renderColumn: row => {
        return (
          <Typography variant="subtitle">{row?.state?.name || '-'}</Typography>
        );
      },
    },
    {
      id: 'districtName',
      label: intl.formatMessage({id: 'label.school-district-name'}),
      dataKey: 'district_name',
      width: '27%',
    },
    {
      id: 'districtActions',
      width: '19%',
      label: intl.formatMessage({id: 'label.actions'}),
      renderColumn: row => {
        return (
          <div style={commonStyle.tableActionContainer}>
            <FaRegEdit
              style={commonStyle.tableActionIcon}
              onClick={() => {
                setEditRow(row);
                setOpenAddDistrictModal(true);
              }}
            />
            <MdDisabledVisible
              style={commonStyle.tableActionIcon}
              onClick={() => {
                setEditRow(row);
                setOpenDisableModal(true);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className="d-flex student-detail-wrapper">
        <div className="school-label">
          {intl.formatMessage({id: 'label.details-districts'})}
        </div>
        <CustomButton
          variant="blue"
          labelText={`+ ${intl.formatMessage({id: 'label.add-new'})}`}
          handleClick={() => setOpenAddDistrictModal(true)}
        />
      </div>

      <CustomTable
        tableHead={TABLE_HEAD}
        tableData={districtsListData}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        totalPages={districtsList?.last_page}
        totalCount={districtsList?.total}
      />

      {openAddDistrictModal && (
        <AddDistrictModal
          open={openAddDistrictModal}
          setOpen={setOpenAddDistrictModal}
          editRowData={editRow}
          setEditRowData={setEditRow}
          currentPage={currentPage}
        />
      )}

      {openDisableModal && (
        <DisableDistrictModal
          open={openDisableModal}
          setOpen={setOpenDisableModal}
          disableRowData={editRow}
          setDisableRowData={setEditRow}
          currentPage={currentPage}
        />
      )}
    </div>
  );
}
