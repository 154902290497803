import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from '../routes';
import {useSelector} from 'react-redux';
import {
  LOGIN_PATH,
  ROOT_PATH,
  STUDENT_WELCOME_PATH,
} from '../constants/routePaths';
import useUserRole from '../hooks/useUserRole';
function withPrivateAccess(Component) {
  return props => {
    const userRole = useUserRole();
    const [isLoading, setIsLoading] = useState(true);
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const navigate = useNavigate();

    useEffect(() => {
      if (!isAuthenticated) {
        navigate(LOGIN_PATH);
      } else if (isAuthenticated && userRole === 'student') {
        setIsLoading(false);
        navigate(STUDENT_WELCOME_PATH);
      } else {
        setIsLoading(false);
        navigate(ROOT_PATH);
      }
    }, []);

    if (isLoading) {
      //add spinner
      return <div>Loading...</div>;
    }

    return <Component {...props} />;
  };
}

export default withPrivateAccess;
