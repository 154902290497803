import {createSlice} from '@reduxjs/toolkit';
import {
  getQuesAnsList,
  getStudentQuesAns,
} from '../actions/questionAnswerActions';

const initialState = {
  quesAnsList: [],
  allQuesAnsData: [],
};

const questionAnswer = createSlice({
  name: 'questionAnswer',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getStudentQuesAns.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getStudentQuesAns.fulfilled, (state, action) => {
        state.loading = false;
        state.quesAnsList = action.payload;
        state.error = null;
      })
      .addCase(getStudentQuesAns.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getQuesAnsList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getQuesAnsList.fulfilled, (state, action) => {
        state.loading = false;
        state.allQuesAnsData = action.payload;
        state.error = null;
      })
      .addCase(getQuesAnsList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default questionAnswer.reducer;
