import React from 'react';
import {CgSortAz} from 'react-icons/cg';
import {CgSortZa} from 'react-icons/cg';
import SearchBar from '../SearchBar/SearchBar';
import SelectDropdown from '../SelectDropdown/SelectDropdown';
import './TabsFilterSection.scss';

export default function TabsFilterSection({
  tabsOptions,
  handleTabSelect,
  searchPlaceholder,
  onSearchChange,
  handleSort,
  sortOrder,
  selectedTabId,
  showSelect,
  dropdownOptions,
  dropdownPlaceholder,
  handleSelectChange,
  selectedSchoolId,
}) {
  return (
    <div className="d-flex tabs-filter-wrapper">
      {tabsOptions ? (
        <div className="d-flex tabs-wrapper">
          {tabsOptions?.map(tabOption => (
            <div
              key={tabOption?.id}
              className={
                tabOption?.id === selectedTabId
                  ? 'tab-style-selected'
                  : 'tab-style'
              }
              onClick={() => handleTabSelect(tabOption?.id)}>
              {tabOption?.label}
            </div>
          ))}
        </div>
      ) : null}

      <div className="tabsSearchbar-inner">
        {showSelect && (
          <div>
            <SelectDropdown
              options={dropdownOptions}
              placeholder={dropdownPlaceholder}
              onChange={e => handleSelectChange(e.target.value)}
              selectClassName={'form-control cus-select tab-select-bar'}
              value={selectedSchoolId}
            />
          </div>
        )}
        <SearchBar placeholder={searchPlaceholder} onChange={onSearchChange} />
        <div className="sorting">
          <button onClick={handleSort} className="">
            {sortOrder === 'asc' ? <CgSortAz /> : <CgSortZa />} Sort
          </button>
        </div>
      </div>
    </div>
  );
}
