import {useSelector} from 'react-redux';
import {getLocalStorageItem} from '../services/local-storage';

export default function useUserRole() {
  const {isAuthenticated} = useSelector(state => state?.auth);
  const userRoleData = getLocalStorageItem('userRole');
  const userRole = userRoleData?.name;
  if (!isAuthenticated) return null;
  return userRole;
}
