import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {showToast} from '../../components/Toast/Toast';
import {setLoading} from '../../globalContext/globalLoader/globalLoaderAction';
import {MANAGE_ISLAND_LIST} from '../../constants/routePaths';
import {setLocalStorageItem} from '../../services/local-storage';

export const fetchAvatars = createAsyncThunk(
  'avatars/fetchAvatars',
  async (_, thunkAPI) => {
    try {
       const response = await axiosPrivateInstance.get('/avatars');
    
      return response.data.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);

export const AddStudentAvatars = createAsyncThunk(
  'avatars/AddStudentAvatars',
  async ({ media_id, intl, navigate }, thunkAPI) => {
    try {
      const payload = {
        avatar_id: media_id,
      };

      const response = await axiosPrivateInstance.patch('/students/avatar', payload);
      navigate(MANAGE_ISLAND_LIST);
    
      const selectedMediaId = response.data.data.media;
      setLocalStorageItem('studentIslandAvatar', selectedMediaId);
      return response;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);