export const commonStyle = {
  tableActionContainer: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'start',
  },
  tableActionIcon: {
    fontSize: '20px',
    color: '#4B7BFF',
    cursor: 'pointer',
  },
  cancelBtn: {
    padding: '11px 22.7px',
    fontWeight: '700',
    fontSize: '16px',
    borderRadius: '12px',
    border: '1.5px solid #0040F0',
    backgroundColor: '#fff',
    color: '#0040F0',
    width: '100%',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#0040F0',
    },
  },
  confirmBtn: {
    padding: '11px 22.7px',
    fontWeight: '700',
    fontSize: '16px',
    borderRadius: '12px',
    border: '1.5px solid #0040F0',
    backgroundColor: '#0040F0',
    color: '#fff',
    width: '100%',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#0040F0',
      color: '#fff',
    },
  },
  dialogueStyle: {
    '& .MuiDialog-paper': {
      padding: '30px 20px',
      maxWidth: '45% !important',
      width: '100%',
      borderRadius: '12px',
    },
  },
  disableModalStyle: {
    '& .MuiDialog-paper': {
      padding: '10px 20px 15px',
      maxWidth: '30% !important',
      width: '100%',
      borderRadius: '12px',
    },
  },
  modalContentTextStyle: {
    textAlign: 'center',
    color: '#1F2937',
    fontWeight: '600',
    fontSize: '16px',
    marginTop: '12px',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  editLessonStyleBtn: {
    backgroundColor: '#EBF5FF',
    color: '#4B7BFF',
    fontSize: '14px',
    fontWeight: '700',
    border: 'none',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  menuItemStyle: {
    width: '20px',
    height: '20px',
  },
  lessonEditButton: {
    backgroundColor: '#EBF5FF',
    color: '#4B7BFF',
    fontSize: '14px',
    fontWeight: '700',
    border: 'none',
    borderRadius: '60px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 20px',
    lineHeight: '40px',
  },
  seeSectionBtn: {
    backgroundColor: '#4B7BFF',
    borderRadius: '12px',
    padding: "0px 20px",
    fontSize: '16px',
    fontWeight: '700',
    lineHeight: '48px',
    color: '#FFF',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  lessonStyleModal: {
    '& .MuiDialog-paper': {
      padding: '30px 20px',
      maxWidth: '65% !important',
      width: '100%',
      borderRadius: '12px',
    },
  },
  studentDialogStyle: {
    '& .MuiDialog-paper': {
      padding: '30px 20px',
      maxWidth: '100% !important',
      width: '100%',
      borderRadius: '12px',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      maxWidth: '100% !important',
    },
    '& .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop': {
      backgroundColor: 'rgb(0 0 0 / 75%)',
    }
  },
  colorPickerMenu: {
    zIndex: 1300,
    border: '1.5px solid #F3F4F6',
    borderRadius: '8px',
    backgroundColor: 'white',
    padding: '10px 20px',
  },
  studentDialogueContentStyle: {
    borderRadius: '8px',
    padding: '20px',
    backgroundColor: '#FFF',
    border: '3px solid',
    marginTop: '8px'
  },
  typeContentStyle: {
    borderRadius: '24px',
    border: '4px solid transparent',
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
  },
  textEditorDivider: {
    width: '4px',
    borderRadius: '10px',
    backgroundColor: '#5C6DE0',
    opacity: 1,
  },
  blueModalContentStyle: {
    borderRadius: '8px',
    padding: '20px',
    backgroundColor: '#38469E',
    border: '3px solid',
    marginTop: '8px'
  },
  viewPreviewBtn: {
    backgroundColor: 'none',
    border: 'none',
    fontSize: '14px',
    color: '#1F2937',
    fontWeight: '700',
    lineHeight: '40px',
    backgroundColor: "transparent"
  },
  bottomSectionStyle: {
    marginTop: "6%",
  },
  pdfPaginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  pdfPaginationBtn: {
    border: 'none', 
    background: 'none', 
    cursor: 'pointer'
  },
  pdfPaginationIcon: {
    color: '#5C6DE0', 
    fontSize: 'xx-large'
  },
  tabLabelStyle: {
    textTransform: 'none',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '40px',
    padding: '0px 12px',
    color: '#6B7280',
    '&.Mui-selected': {
      color: '#2B53FF',
    }
  },
  backToAllQues: {
    border: '1.5px solid #E5E7EB',
    borderRadius: '10px',
    fontWeight: '700',
    fontSize: '14px',
    color: '#374151',
    lineHeight: '40px',
    float: "left",
    width: "fit-content",
    backgroundColor: "white",
    padding: "0px 20px"
  },
  submitAnsBtn: {
    padding: '11px 22.7px',
    fontWeight: '700',
    fontSize: '16px',
    borderRadius: '12px',
    border: '1.5px solid #0040F0',
    backgroundColor: '#0040F0',
    color: '#fff',
    textTransform: 'none',
    width: "auto",
    alignSelf: "flex-end",
    '&:hover': {
      backgroundColor: '#0040F0',
      color: '#fff',
    },
  },
  toggleText: {
    color: '#0040F0',
    cursor: 'pointer',
  },
};
