import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {showToast} from '../../components/Toast/Toast';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {setLoading} from '../../globalContext/globalLoader/globalLoaderAction';
import {MANAGE_CONTENTS_PATH} from '../../constants/routePaths';

export const getIslandsList = createAsyncThunk(
  'content/island/fetchIslandList',
  async ({loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.get('/modules');
      loaderDispatch(setLoading(false));
      return response?.data?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      throw error.response.data.message;
    }
  },
);

export const editIslandDetails = createAsyncThunk(
  'content/island/editIsland',
  async ({payload, islandId, intl}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.patch(
        `/modules/${islandId}`,
        payload,
      );
      showToast(getLocalizedMessage(intl, 'success.islandUpdated'), 'success');
      return response.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const updateIslandStatus = createAsyncThunk(
  'content/island/updateIslandStatus',
  async ({id, intl}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.patch(
        `/modules/status/${id}`,
      );
      showToast(getLocalizedMessage(intl, 'island.success.updated'), 'success');
      return response.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const getLessonStyle = createAsyncThunk(
  'content/island/getLessonStyle',
  async ({loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.get('/module-styles');
      loaderDispatch(setLoading(false));
      return response?.data?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      throw error.response.data.message;
    }
  },
);

export const updateLessonStyles = createAsyncThunk(
  'content/island/updateLessonStyles',
  async ({payload, intl, loaderDispatch, navigate}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.patch(
        `/module-styles`,
        payload,
      );
      loaderDispatch(setLoading(false));
      navigate(MANAGE_CONTENTS_PATH);
      showToast(
        getLocalizedMessage(intl, 'success.updated.lessonStyle'),
        'success',
      );
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);
