import React from 'react';
import {useIntl} from 'react-intl';
import InputLabel from '../../components/InputLabel/InputLabel';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import ColorPickerMenu from '../ColorPickerMenu/ColorPickerMenu';
import UploadFileImage from '../UploadFileImage/UploadFileImage';
import './LessonStyleCard.scss';

export default function LessonStyleCard({index, value, setFieldValue, error}) {
  const intl = useIntl();

  return (
    <div className="lesson-styleCard">
      <div className="left-container">
        <div>
          <InputLabel
            name={`${index}.labelName`}
            label={getLocalizedMessage(intl, 'lessonStyle.title')}
            placeholder={getLocalizedMessage(
              intl,
              'lessonStyle.title.placeholder',
            )}
            required
            value={value?.labelName}
            onChange={e =>
              setFieldValue(`${index}.labelName`, e.target.value, true)
            }
            onBlur={() =>
              setFieldValue(`${index}.labelName`, value?.labelName, true)
            }
            error={error?.labelName}
          />
        </div>

        <ColorPickerMenu
          selectedBgColor={value?.bgColor}
          handleColorSelect={bgColorVal =>
            setFieldValue(`${index}.bgColor`, bgColorVal)
          }
        />
      </div>

      <div className="right-container">
        <label htmlFor="file" className="form-label cus-form-label">
          {intl.formatMessage({id: 'label.uploadImage'})}
        </label>

        <UploadFileImage
          previewUrl={value?.previewUrl}
          imageName={value?.fileName}
          setFileId={idMedia => setFieldValue(`${index}.mediaId`, idMedia)}
          setPreviewUrl={urlPreview =>
            setFieldValue(`${index}.previewUrl`, urlPreview)
          }
          setImageName={nameFile =>
            setFieldValue(`${index}.fileName`, nameFile)
          }
          error={error?.mediaId}
          hideImageTag
        />
      </div>
    </div>
  );
}
