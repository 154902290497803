import {createSlice} from '@reduxjs/toolkit';
import {fetchStudentDashboard} from '../actions/studentDashboardActions';

const initialState = {
  studentDashboardData: [],
};

const studentDashboardSlice = createSlice({
  name: 'studentDashboard',
  initialState,
  reducers: {
    // for reducers
  },
  extraReducers: builder => {
    builder
      .addCase(fetchStudentDashboard.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStudentDashboard.fulfilled, (state, action) => {
        state.loading = false;
        state.studentDashboardData = action.payload;
        state.error = null;
      })
      .addCase(fetchStudentDashboard.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export default studentDashboardSlice.reducer;
