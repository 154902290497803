import React from 'react';
import {useIntl} from 'react-intl';
import {getTimeAgo} from '../../utilityFunction/helper';
import './QuestionAnswerListingView.scss';

export default function QuestionAnswerListingView({
  quesAnsData,
  lessonName,
  onDeleteQues,
}) {
  const intl = useIntl();

  return (
    <div className="quesAns-wrapper">
      {quesAnsData?.map((quesAnsOpt, index) => (
        <div
          className="studentQuesAns-container"
          key={`${quesAnsData?.question_id}@${quesAnsData?.lesson_id}`}>
          <div className="quesAns">
            <div className="ques-wrapper">
              <div className="quesText">{`${index + 1}.`}</div>
              <div className="quesText">{quesAnsOpt?.question}</div>
            </div>

            <div className="ans-wrapper">
              {quesAnsOpt?.answers ? (
                <div className="answerText">{quesAnsOpt?.answers?.answer}</div>
              ) : (
                <div className="answerNotAvailText">
                  {intl.formatMessage({id: 'label.ansNotAvail'})}
                </div>
              )}
            </div>

            {quesAnsOpt?.is_pinned && (
              <div className="pinnedDetails-wrapper">
                <div className="pin-container">
                  <div className="pinByText">
                    {intl.formatMessage({id: 'label.pinnedBy'})}
                  </div>
                  <div className="pinByPerson">{`${quesAnsOpt?.pinned_by?.first_name} ${quesAnsOpt?.pinned_by?.last_name}`}</div>
                </div>
                <div className="pin-container">
                  <img className="pinDot" src="/images/ellipsePinnedDot.svg" />
                  <div className="pinMonths">
                    <div className="pinLessonText">{lessonName}</div>
                  </div>
                </div>
                <div className="pin-container">
                  <img className="pinDot" src="/images/ellipsePinnedDot.svg" />
                  <div className="pinMonths">
                    <div className="pinMonthsText">
                      {getTimeAgo(quesAnsOpt?.pinned_at)}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {!quesAnsOpt?.is_pinned && (
            <img
              src="/images/deleteQuesIcon.svg"
              alt="Delete question"
              className="deleteQues-icon"
              onClick={() => onDeleteQues(quesAnsOpt)}
            />
          )}
        </div>
      ))}
    </div>
  );
}
