import {createSlice} from '@reduxjs/toolkit';
import {
  getUserAnnouncements,
  getUserNotifications,
} from '../actions/notificationActions';

const initialState = {
  userNotifications: [],
  userAnnouncements: [],
};

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUserNotifications.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.userNotifications = action.payload;
        state.error = null;
      })
      .addCase(getUserNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getUserAnnouncements.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserAnnouncements.fulfilled, (state, action) => {
        state.loading = false;
        state.userAnnouncements = action.payload;
        state.error = null;
      })
      .addCase(getUserAnnouncements.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default notificationSlice.reducer;
