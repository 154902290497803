import React from 'react';
import InputLabel from '../../components/InputLabel/InputLabel.js';
import {useIntl} from 'react-intl';

function AdminForm({onSubmit, onChange, formData, errors, editMode, id}) {
  const intl = useIntl();

  return (
    <div className="container add-school-wrapper admin-form-wrapper">
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="mb-3 col-md-6">
            <InputLabel
              type="text"
              name="admin_first_name"
              label={intl.formatMessage({id: 'label.admin_first_name'})}
              required={true}
              placeholder={intl.formatMessage({
                id: 'label.enter_admin_first_name',
              })}
              id="admin_first_name"
              value={formData.admin_first_name}
              onChange={onChange}
              htmlFor="adminFirstName"
              disabled={!editMode && id}
            />
            {errors.admin_first_name && (
              <div className="error-message">{errors.admin_first_name}</div>
            )}
          </div>
          <div className="mb-3 col-md-6">
            <InputLabel
              type="text"
              name="admin_last_name"
              label={intl.formatMessage({id: 'label.admin_last_name'})}
              required={true}
              placeholder={intl.formatMessage({
                id: 'label.enter_admin_last_name',
              })}
              id="admin_last_name"
              value={formData.admin_last_name}
              onChange={onChange}
              htmlFor="adminLastName"
              disabled={!editMode && id}
            />
            {errors.admin_last_name && (
              <div className="error-message">{errors.admin_last_name}</div>
            )}
          </div>

          <div className="mb-3 col-md-6">
            <InputLabel
              type="email"
              name="admin_email"
              label={intl.formatMessage({id: 'label.admin_email_address'})}
              required={true}
              placeholder={intl.formatMessage({
                id: 'label.enter_admin_email_address',
              })}
              id="admin_email"
              value={formData.admin_email}
              onChange={onChange}
              htmlFor="adminEmailAddress"
              disabled={!editMode && id || id}
            />
            {errors.admin_email && (
              <div className="error-message">{errors.admin_email}</div>
            )}
          </div>

          <div className="mb-3 col-md-6">
            <InputLabel
              type="number"
              name="admin_contact_no"
              label={intl.formatMessage({id: 'label.admin_contact_number'})}
              required={true}
              placeholder={intl.formatMessage({
                id: 'label.enter_admin_number',
              })}
              id="admin_contact_no"
              value={formData.admin_contact_no}
              onChange={onChange}
              htmlFor="adminContactNo"
              disabled={!editMode && id}
            />
            {errors.admin_contact_no && (
              <div className="error-message">{errors.admin_contact_no}</div>
            )}
          </div>
          <div className="mb-3 col-md-6">
            <InputLabel
              type="number"
              name="admin_alternate_no"
              label={intl.formatMessage({id: 'label.admin_alternate_contact_number'})}
              required={false}
              placeholder={intl.formatMessage({
                id: 'label.enter_admin_alternate_number',
              })}
              id="admin_alternate_no"
              value={formData.admin_alternate_no}
              onChange={onChange}
              htmlFor="adminAlternateContactNo"
              disabled={!editMode && id}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default AdminForm;
