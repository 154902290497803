import * as Yup from 'yup';
import {EditorState, convertFromRaw} from 'draft-js';

export const validationSchema = intl =>
  Yup.object({
    lesson_contents: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required(intl.formatMessage({id: 'field_required'})),
        media_id: Yup.string().test(
          'is-document-or-image-video',
          intl.formatMessage({id: 'lessonContent.file.required'}),
          function (value) {
            const {type} = this.parent;
            return (
              !['document', 'image/video'].includes(type) ||
              (['document', 'image/video'].includes(type) && Boolean(value))
            );
          },
        ),
        description: Yup.string().test(
          'is-required-text',
          intl.formatMessage({id: 'lessonContent.text.required'}),
          function (value) {
            const {type} = this.parent;
            return (
              !['alert', 'note', 'remember', 'best_advice'].includes(type) ||
              (['alert', 'note', 'remember', 'best_advice'].includes(type) &&
                Boolean(value))
            );
          },
        ),
        editorDescription: Yup.string().test(
          'is-non-empty-text',
          intl.formatMessage({id: 'lessonContent.text.required'}),
          function (value) {
            const {type} = this.parent;
            if (type !== 'text') {
              return true;
            }
            try {
              const contentState = convertFromRaw(JSON.parse(value));
              const editorState = EditorState.createWithContent(contentState);
              const text = editorState.getCurrentContent().getPlainText();
              return text.trim().length > 0;
            } catch (e) {
              return this.createError({
                message: intl.formatMessage({id: 'lessonContent.text.invalid'}),
              });
            }
          },
        ),
        article_title: Yup.string(),
        article_link: Yup.string(),
        article_preview_url: Yup.string(),
        file_preview_name: Yup.string(),
        file_preview_url: Yup.string()
      }),
    ),
  });
