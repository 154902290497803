import React, {useContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import {useFormik} from 'formik';
import InputLabel from '../../../components/InputLabel/InputLabel';
import CustomDialogue from '../../../components/CustomDialogue/CustomDialogue';
import UploadFileImage from '../../../components/UploadFileImage/UploadFileImage';
import {
  editIslandDetailsFormFieldNames,
  editIslandDetailsFormInitialValues,
  validationSchema,
} from '../../../validationSchema/editIslandDetailSchema';
import {getLocalizedMessage} from '../../../utilityFunction/helper';
import {
  editIslandDetails,
  getIslandsList,
} from '../../../store/actions/islandActions';
import {GlobalLoaderContext} from '../../../globalContext/globalLoader/globalLoaderProvider';
import {showToast} from '../../../components/Toast/Toast';

export default function EditIslandDetails({
  open,
  setOpen,
  islandData,
  setSelectedIsland,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const [fileId, setFileId] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [imageName, setImageName] = useState('');
  const [initialFormData, setInitialFormData] = useState([]);

  const {
    values,
    handleChange,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues: editIslandDetailsFormInitialValues,
    onSubmit: async values => {
      if (islandData) {
        if (!checkFormHasChanged()) {
          showToast(
            getLocalizedMessage(intl, 'label.noIslandDetailsChanged'),
            'warning',
          );
          return;
        }

        const payload = {
          ...values,
          module_icon: fileId,
        };
        setOpen(false);
        await dispatch(
          editIslandDetails({
            payload,
            islandId: islandData?.id,
            intl,
            loaderDispatch,
          }),
        );
        setSelectedIsland(null);
        await dispatch(getIslandsList({loaderDispatch}));
      }
    },
    validationSchema: validationSchema(intl),
  });

  useEffect(() => {
    if (islandData) {
      setPreviewUrl(islandData?.module_icon?.path);
      setFileId(islandData?.module_icon?.id);
      setImageName(islandData?.module_icon?.name);
      setValues(prev => ({
        ...prev,
        [editIslandDetailsFormFieldNames.ISLAND_NAME]: islandData?.name,
        [editIslandDetailsFormFieldNames.ISLAND_DESCRIPTION]:
          islandData?.description,
      }));
      setInitialFormData({
        name: islandData?.name,
        description: islandData?.description,
        module_icon: islandData?.module_icon?.id,
      });
    }
  }, [islandData]);

  const closeIslandDetailsModal = () => {
    setOpen(false);
    setSelectedIsland(null);
  };

  const checkFormHasChanged = () => {
    const formValues = {
      ...values,
      module_icon: fileId,
    };
    const formChanged =
      JSON.stringify(initialFormData) !== JSON.stringify(formValues);
    return formChanged;
  };

  return (
    <CustomDialogue
      open={open}
      onCancel={closeIslandDetailsModal}
      cancelText={getLocalizedMessage(intl, 'label.cancel')}
      confirmText={getLocalizedMessage(intl, 'label.save')}
      dialogueTitle={getLocalizedMessage(intl, 'label.title.islandDetails')}
      onSubmit={handleSubmit}
      className={'center-btn'}>
      <div className="container">
        <div className="row mt-4">
          <div className="mb-3 col-md-12">
            <InputLabel
              name={editIslandDetailsFormFieldNames.ISLAND_NAME}
              label={getLocalizedMessage(intl, 'editIsland.editIslandName')}
              placeholder={getLocalizedMessage(
                intl,
                'editIsland.editIslandName.placeholder',
              )}
              required
              value={values[editIslandDetailsFormFieldNames.ISLAND_NAME]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[editIslandDetailsFormFieldNames.ISLAND_NAME] &&
                errors[editIslandDetailsFormFieldNames.ISLAND_NAME]
              }
            />
          </div>

          <div className="mb-3 col-md-12">
            <InputLabel
              name={editIslandDetailsFormFieldNames.ISLAND_DESCRIPTION}
              label={getLocalizedMessage(intl, 'editIsland.editIslandDesc')}
              placeholder={getLocalizedMessage(
                intl,
                'editIsland.editIslandDesc.placeholder',
              )}
              required
              value={values[editIslandDetailsFormFieldNames.ISLAND_DESCRIPTION]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[editIslandDetailsFormFieldNames.ISLAND_DESCRIPTION] &&
                errors[editIslandDetailsFormFieldNames.ISLAND_DESCRIPTION]
              }
            />
          </div>

          <div className="mb-3 mt-3 col-md-12">
            <UploadFileImage
              setFileId={setFileId}
              previewUrl={previewUrl}
              setPreviewUrl={setPreviewUrl}
              acceptType={'image/*'}
              imageName={imageName}
              setImageName={setImageName}
            />
          </div>
        </div>
      </div>
    </CustomDialogue>
  );
}
