import React from 'react';
import './CardTextView.scss';

export default function CardTextView({
  textContent,
  headerText,
  handleTextChange,
  handleDelete,
  error,
  name,
  onBlur,
}) {
  return (
    <>
      <div className="card-text-wrapper">
        <div className="header-text">
          <div className="header-label">
            <span className="handle-icon mr-8">☰</span> {headerText}
          </div>
          <img
            className="trash-icon"
            src="/images/trash.svg"
            onClick={handleDelete}
          />
        </div>
        <div className="card-content">
          <input
            className="card-content-text"
            name={name}
            value={textContent}
            onChange={e => handleTextChange(e.target.value)}
            onBlur={onBlur}
          />
        </div>
      </div>
      {error && <p className="text-danger mt-1">{error}</p>}
    </>
  );
}
