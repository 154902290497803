import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {Typography} from '@mui/material';
import CustomTable from '../../components/CustomTable/CustomTable';
import DeactivateModal from '../../components/DeactivateModal/DeactivateModal';
import {
  updateStaticContentStatus,
  fetchStaticContent,
  addStaticContent,
} from '../../store/actions/helpActions';
import {getLocalizedMessage, getStatusText} from '../../utilityFunction/helper';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';
import AddHelp from './AddHelp/AddHelp.js';
import {useFormik} from 'formik';
import {
  addHelpFieldName,
  addHelpInitialValues,
  validationSchema,
} from '../../validationSchema/addHelpSchema.js';
import './Help.scss';

export default function Help() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const {isAuthenticated} = useSelector(state => state?.auth);
  const StaticContentList = useSelector(
    state => state?.help?.StaticContentList?.data,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedHelpFile, setSelectedHelpFile] = useState(null);
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);
  const [openAddSectionDialog, setOpenAddHelpDialog] = useState(false);

  useEffect(() => {
    if (isAuthenticated) fetchHelpData();
  }, [isAuthenticated, currentPage]);

  const fetchHelpData = async () => {
    await dispatch(
      fetchStaticContent({
        currentPage,
        pageName: 'help',
        loaderDispatch,
      }),
    );
  };

  const handleAddHelp = () => {
    setFieldValue(addHelpFieldName.MEDIA, '');
    setOpenAddHelpDialog(true);
  };
  const handleCloseAddHelpDialog = () => {
    setOpenAddHelpDialog(false);
    resetForm();
  };

  const {
    values,
    handleChange,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: addHelpInitialValues,
    onSubmit: values => {
      dispatch(
        addStaticContent({
          payload: {
            ...values,
            page_name: 'help',
            media_id: values?.media_id
          },
          intl,
        }),
      ).then(() => {
        fetchHelpData();
      });
      setCurrentPage(1);
      handleCloseAddHelpDialog();
    },
    
    validationSchema: validationSchema(intl),
  });
  const onPageChange = page => {
    setCurrentPage(page);
  };

  const handleDeactivateConfirm = async () => {
    if (selectedHelpFile?.id) {
      setOpenDeactivateModal(false);
      dispatch(
        updateStaticContentStatus({id: selectedHelpFile?.id, intl}),
      ).then(() => {
        setSelectedHelpFile(null);
        fetchHelpData();
      });
    }
  };
  
  const handleAvatarClick = helpFile => {
    setOpenDeactivateModal(true);
    if (selectedHelpFile?.id === helpFile?.id) {
      setSelectedHelpFile(null);
    } else {
      setSelectedHelpFile(helpFile);
    }
  };
  
  const TABLE_HEAD = [
    {
      id: 'idNumber',
      label: intl.formatMessage({id: 'label.idNumber'}),
      alignRight: false,
      width: '10%',
      renderColumn: helpFile => {
        return (
          <Typography variant="subtitle">{helpFile?.id + '.' || '-'}</Typography>
        );
      },
    },
    {
      id: 'title',
      label: intl.formatMessage({id: 'label.title'}),
      alignRight: false,
      width: '10%',
      renderColumn: helpFile => {
        return (
          <Typography variant="subtitle">{helpFile?.title || '-'}</Typography>
        );
      },
    },
    {
      id: 'pdf',
      label: intl.formatMessage({id: 'label.pdf'}),
      alignRight: false,
      renderColumn: helpFile => {
        const fileName = helpFile?.media?.name;
        const fileNameParts = fileName?.split('_');
        const displayName =
          fileNameParts?.length > 1
            ? fileNameParts?.slice(1).join('_')
            : fileName;
        return (
          <div key={helpFile?.id} className="s_name_address d-flex">
            <div className="s_logo">
              <a
                href={helpFile?.media?.path}
                target="_blank"
                rel="noopener noreferrer">
                {displayName}
              </a>
            </div>
          </div>
        );
      },
    },
    {
      id: 'is_active',
      label: intl.formatMessage({id: 'label.status'}),
      alignRight: false,
      width: '10%',
      renderColumn: helpFile => {
        return (
          <div
            className={`${
              helpFile.is_active ? 'school-active' : 'school-deactivate'
            }`}>
            {helpFile.is_active ? 'Active' : 'Inactive'}
          </div>
        );
      },
    },
    {
      id: 'quickActions',
      label: intl.formatMessage({id: 'label.quick_actions'}),
      alignRight: false,
      dataKey: '',
      width: '15%',
      renderColumn: helpFile => {
        return (
          <div className="d-flex actions-wrapper help-btn-wrapper">
            <div
              className="pointer"
              onClick={() => {
                handleAvatarClick(helpFile);
              }}>
              {getStatusText(helpFile?.is_active, intl)}
            </div>
          </div>
        );
      },
    },
    
  ];

  return (
    <div>
      <div className="d-flex student-detail-wrapper">
        <div className="school-label">
          {intl.formatMessage({id: 'label.help'})}
        </div>
        <div className="add-import-btn">
          <div className="">
            <button className="btn btn-primary" onClick={handleAddHelp}>
              <img src={'/images/white-add.svg'} alt={'Edit lesson'} />
              {intl.formatMessage({id: 'label.add_help'})}
            </button>
          </div>
        </div>
      </div>

      <CustomTable
        tableHead={TABLE_HEAD}
        tableData={StaticContentList?.data}
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalPages={StaticContentList?.last_page}
        totalCount={StaticContentList?.total}
      />

      {openDeactivateModal && (
        <DeactivateModal
          open={openDeactivateModal}
          setOpen={setOpenDeactivateModal}
          handleDeactivate={handleDeactivateConfirm}
          confirmText={getStatusText(selectedHelpFile?.is_active, intl)}
          modalContentText={
            !selectedHelpFile?.is_active
            ? getLocalizedMessage(intl, 'pdf.statusModal.activate')
            : getLocalizedMessage(intl, 'pdf.statusModal.deactivate')
          }
        />
      )}

      {openAddSectionDialog && (
          <AddHelp
            open={openAddSectionDialog}
            handleClose={handleCloseAddHelpDialog}
            handleSubmit={handleSubmit}
            values={values}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
          />
      )}
    </div>
  );
}
