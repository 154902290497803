import * as Yup from 'yup';
import {EMAIL_REGEX, NAME_REGEX, MOBILE_NUMBER_REGEX} from '../core/validation';
export const addUserProfileFormFieldNames = {
  MEDIA_ID: 'media_id',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  DESIGNATION: 'designation_id',
  PHONE: 'phone_no',
  ALTERNATE_PHONE: 'alternate_phone_no',
};
export const addUserProfileInitialValues = {
  [addUserProfileFormFieldNames.MEDIA_ID]: '',
  [addUserProfileFormFieldNames.FIRST_NAME]: '',
  [addUserProfileFormFieldNames.LAST_NAME]: '',
  [addUserProfileFormFieldNames.EMAIL]: '',
  [addUserProfileFormFieldNames.DESIGNATION]: '',
  [addUserProfileFormFieldNames.PHONE]: '',
  [addUserProfileFormFieldNames.ALTERNATE_PHONE]: '',
};
export const validationSchema = intl => {
  return Yup.object().shape({
    [addUserProfileFormFieldNames.FIRST_NAME]: Yup.string()
      .required(intl.formatMessage({id: 'label.first_name_required'}))
      .max(
        150,
        intl.formatMessage({id: 'label.fieldError.first_name.maxLength'}),
      )
      .matches(
        NAME_REGEX,
        intl.formatMessage({id: 'label.fieldError.whitespace'}),
      ),
    [addUserProfileFormFieldNames.LAST_NAME]: Yup.string()
      .required(intl.formatMessage({id: 'label.last_name_required'}))
      .max(
        150,
        intl.formatMessage({id: 'label.fieldError.first_name.maxLength'}),
      )
      .matches(
        NAME_REGEX,
        intl.formatMessage({id: 'label.fieldError.whitespace'}),
      ),
    [addUserProfileFormFieldNames.EMAIL]: Yup.string()
      .required(intl.formatMessage({id: 'label.email_required'}))
      .matches(EMAIL_REGEX, intl.formatMessage({id: 'label.fieldError.email'})),
    [addUserProfileFormFieldNames.DESIGNATION]: Yup.string().required(
      intl.formatMessage({id: 'label.designation_required'}),
    ),
    [addUserProfileFormFieldNames.PHONE]: Yup.string().required(
      intl.formatMessage({id: 'label.fieldError.phone-number.required'}),
    )
    .matches(MOBILE_NUMBER_REGEX, intl.formatMessage({id: 'label.fieldError.phone_number'})),
    [addUserProfileFormFieldNames.ALTERNATE_PHONE]: Yup.string()
    .matches(MOBILE_NUMBER_REGEX, intl.formatMessage({id: 'label.fieldError.phone_number'})),
  });
};

