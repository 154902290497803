import React from 'react';
import {View} from '@unthinkable/react-core-components';
import './CustomButton.style.scss';

const CustomButton = ({variant, labelText, disabled, handleClick, align}) => {
  function getButtonStyle(variant) {
    switch (variant) {
      case 'blue':
        return 'blueBtn';
      case 'white':
        return 'whiteBtn';
      default:
        break;
    }
  }

  function getTextAlignment(align) {
    switch (align) {
      case 'center':
        return 'text-center';
      case 'left':
        return 'text-left';
      case 'right':
        return 'text-right';
      default:
        break;
    }
  }

  return (
    <View>
      <div className={`${getButtonStyle(variant)} ${getTextAlignment(align)}`}>
        <button onClick={handleClick} disabled={disabled}>
          {labelText}
        </button>
      </div>
    </View>
  );
};

export default CustomButton;
