import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {Typography} from '@mui/material';
import CustomTable from '../../components/CustomTable/CustomTable';
import {
  getUserAnnouncements,
  addUserAnnouncements,
} from '../../store/actions/announcementActions.js';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';
import {useFormik} from 'formik';
import {
  addAnnouncementFieldName,
  addAnnouncementInitialValues,
  validationSchema,
} from '../../validationSchema/addAnnouncementSchema.js';
import CustomDialogue from '../../components/CustomDialogue/CustomDialogue.js';
import InputLabel from '../../components/InputLabel/InputLabel';
import moment from 'moment';

export default function Announcements() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const {isAuthenticated} = useSelector(state => state?.auth);
  const userAnnouncements = useSelector(
    state => state?.announcements?.userAnnouncements,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [openAddAnnouncementDialog, setOpenAddAnnouncementDialog] =
    useState(false);

  useEffect(() => {
    if (isAuthenticated) fetchAnnouncementData();
  }, [isAuthenticated, currentPage]);

  const fetchAnnouncementData = async () => {
    await dispatch(
      getUserAnnouncements({
        currentPage,
        loaderDispatch,
      }),
    );
  };

  const handleAddAnnouncement = () => {
    setFieldValue(addAnnouncementFieldName.TITLE, '');
    setOpenAddAnnouncementDialog(true);
  };
  const handleCloseAddAnnouncementDialog = () => {
    setOpenAddAnnouncementDialog(false);
    resetForm();
  };

  const {
    values,
    handleChange,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: addAnnouncementInitialValues,
    onSubmit: values => {
      dispatch(
        addUserAnnouncements({
          payload: {
            description: values?.description,
          },
          intl,
        }),
      ).then(() => {
        fetchAnnouncementData();
      });
      setCurrentPage(1);
      handleCloseAddAnnouncementDialog();
    },

    validationSchema: validationSchema(intl),
  });
  const onPageChange = page => {
    setCurrentPage(page);
  };

  const TABLE_HEAD = [
    {
      id: 'idNumber',
      label: intl.formatMessage({id: 'label.idNumber'}),
      alignRight: false,
      width: '10%',
      renderColumn: announcement => {
        return (
          <Typography variant="subtitle">
            {announcement?.id + '.' || '-'}
          </Typography>
        );
      },
    },
    {
      id: 'description',
      label: intl.formatMessage({id: 'label.description'}),
      alignRight: false,
      renderColumn: announcement => {
        return (
          <Typography variant="subtitle">
            {announcement?.description || '-'}
          </Typography>
        );
      },
    },

    {
      id: 'created_at',
      label: intl.formatMessage({id: 'label.created'}),
      alignRight: false,
      width: '15%',
      renderColumn: announcement => {
        return (
          <Typography variant="subtitle">
            <div>{moment(announcement.created_at).format('DD/MM/YYYY')}</div>
          </Typography>
        );
      },
    },
  ];

  return (
    <div>
      <div className="d-flex student-detail-wrapper">
        <div className="school-label">
          {intl.formatMessage({id: 'label.tabs.Announcements'})}
        </div>
        <div className="add-import-btn">
          <div className="">
            <button className="btn btn-primary" onClick={handleAddAnnouncement}>
              <img src={'/images/white-add.svg'} alt={'Edit lesson'} />
              {intl.formatMessage({id: 'label.add_announcement'})}
            </button>
          </div>
        </div>
      </div>

      <CustomTable
        tableHead={TABLE_HEAD}
        tableData={userAnnouncements?.data}
        currentPage={currentPage}
        onPageChange={onPageChange}
        totalPages={userAnnouncements?.last_page}
        totalCount={userAnnouncements?.total}
      />

      {openAddAnnouncementDialog && (
        <CustomDialogue
          open={open}
          handleClose={handleCloseAddAnnouncementDialog}
          cancelText={intl.formatMessage({
            id: 'label.cancel',
          })}
          confirmText={intl.formatMessage({
            id: 'label.publish',
          })}
          onCancel={handleCloseAddAnnouncementDialog}
          hideTitle={false}
          onSubmit={handleSubmit}>
          <div className="container">
            <div className="row mt-4">
              <div className="mb-3 col-md-12">
                <InputLabel
                  name={addAnnouncementFieldName.TITLE}
                  label={intl.formatMessage({
                    id: 'label.announcement',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'label.enter_announcement',
                  })}
                  required
                  value={values[addAnnouncementFieldName.TITLE]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched[addAnnouncementFieldName.TITLE] &&
                    errors[addAnnouncementFieldName.TITLE]
                  }
                  useTextarea
                />
              </div>
            </div>
          </div>
        </CustomDialogue>
      )}
    </div>
  );
}
