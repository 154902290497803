import React from 'react';
import {useIntl} from 'react-intl';
import './ArticleCardView.scss';

export default function ArticleCardView({
  handleArticleDelete,
  articleTitle,
  articleLink,
  articlePreviewUrl,
}) {
  const intl = useIntl();

  return (
    <div className="article-card-text-wrapper">
      <div className="article-card-text">
        <div className="article-label">
          <span className="handle-icon mr-8">☰</span>{' '}
          {intl.formatMessage({id: 'label.articleDetails'})}
        </div>
        <img
          className="trash-icon"
          src="/images/trash.svg"
          onClick={handleArticleDelete}
        />
      </div>
      <div className="add-article-content">
        <div className="article-prev-container">
          <img
            src={articlePreviewUrl}
            alt="article blog image"
            className="article-preview-img"
          />
        </div>

        <div className="article-content-text">
          <div className="article-title-text">{articleTitle}</div>
          <div className="article-link">
            <a href={articleLink} target="_blank" rel="noopener noreferrer">
              {articleLink}
            </a>
          </div>
        </div>
        <div></div>
      </div>
    </div>
  );
}
