import * as Yup from 'yup';
import { NAME_REGEX} from '../core/validation';
export const addStudentUserProfileFormFieldNames = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  TEACHER: 'teacher',
  COUNSELLOR:'counsellor',
};
export const addStudentUserProfileInitialValues = {
  [addStudentUserProfileFormFieldNames.FIRST_NAME]: '',
  [addStudentUserProfileFormFieldNames.LAST_NAME]: '',
  [addStudentUserProfileFormFieldNames.EMAIL]: '',
  [addStudentUserProfileFormFieldNames.TEACHER]: '',
  [addStudentUserProfileFormFieldNames.COUNSELLOR]: '',
};
export const validationSchema = intl => {
  return Yup.object().shape({
    [addStudentUserProfileFormFieldNames.FIRST_NAME]: Yup.string()
      .required(intl.formatMessage({id: 'label.first_name_required'}))
      .max(
        150,
        intl.formatMessage({id: 'label.fieldError.first_name.maxLength'}),
      )
      .matches(
        NAME_REGEX,
        intl.formatMessage({id: 'label.fieldError.whitespace'}),
      ),
    [addStudentUserProfileFormFieldNames.LAST_NAME]: Yup.string()
      .required(intl.formatMessage({id: 'label.last_name_required'}))
      .max(
        150,
        intl.formatMessage({id: 'label.fieldError.first_name.maxLength'}),
      )
      .matches(
        NAME_REGEX,
        intl.formatMessage({id: 'label.fieldError.whitespace'}),
      ),
  });
};

