import {createSlice} from '@reduxjs/toolkit';
import {exportStudentProgressReport} from '../actions/progressReportActions';

const initialState = {
  progressReport: null,
};

const progressReportSlice = createSlice({
  name: 'progressReport',
  initialState,
  reducers: {
    // for reducers
  },
  extraReducers: builder => {
    builder
      .addCase(exportStudentProgressReport.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(exportStudentProgressReport.fulfilled, (state, action) => {
        state.loading = false;
        state.progressReport = action.payload;
        state.error = null;
      })
      .addCase(exportStudentProgressReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default progressReportSlice.reducer;
