import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {Button} from '@unthinkable/react-button';
import {View, Text, TextInput} from '@unthinkable/react-core-components';
import {useDispatch, useSelector} from 'react-redux';
import {login} from '../../store/actions/authActions';
import {useNavigate} from '../../routes';
import styles from './loginForm.style';
import {useTheme} from '@unthinkable/react-theme';
import style from './loginForm.style.scss';
import {Link} from 'react-router-dom';
import {ROOT_PATH} from '../../constants/routePaths';
import {IoEye, IoEyeOff} from 'react-icons/io5';
import { usePasswordVisibility } from '../../core/helpers/utils';

function LoginForm(props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const icons = useTheme('icons');
  const {logo} = icons;

  const [credentials, setCredentials] = useState({email: '', password: ''});
  const [isProcessing, setIsProcessing] = useState(false);
  const isLoggedIn = useSelector(state => state.auth?.isAuthenticated);

  const [error, setError] = useState('');
  const [showPassword, togglePasswordVisibility] = usePasswordVisibility();
  const [isMounted, setIsMounted] = useState(true);
  
  const onLogin = async () => {
    setIsProcessing(true);
    try {
      const response = await dispatch(login(credentials));
      if (isMounted) {
        setIsProcessing(false);
        if (response.error) {
          setError({
            email: 'Invalid email address',
            password: 'Wrong password',
          });
        } else {
          navigate(ROOT_PATH);
        }
      }
    } catch (error) {
      if (isMounted) {
        setIsProcessing(false);
        throw error;
      }
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate(ROOT_PATH);
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    setIsMounted(true);
    return () => {
      setIsMounted(false);
    };
  }, []);

  const isLoginActionDisabled =
    !credentials.email.trim() || !credentials.password.trim();

  const handleChange = (key, value) => {
    setCredentials(prevState => ({
      ...prevState,
      [key]: value,
    }));
    setError('');
  };

  return (
    <View className="login-wrapper">
      <View className="login-container">
        <img style={styles.img} src={logo} alt="Gradrock Logo"/>
        <Text style={styles.header}>
          <h3 style={styles.title}>
            {' '}
            {intl.formatMessage({id: 'label.welcome'})}
          </h3>
          <p className="subtitle">
            {intl.formatMessage({id: 'label.login-subtitle'})}
          </p>
        </Text>
        <form onSubmit={onLogin}>
          <div className="mb-3">
            <TextInput
              placeholder={intl.formatMessage({id: 'label.email'})}
              onChangeText={value => handleChange('email', value)}
              value={credentials.email}
              className={
                error.email ? 'input-error form-control' : 'form-control'
              }
            />
          </div>
          <div className="error">
              {' '}
              {error.email && <Text style={styles.error}>{error.email}</Text>}
            </div>

          <div className="password-input mb-3">
            <TextInput
              type={showPassword ? "text" : "password"}
              placeholder={intl.formatMessage({id: 'label.password'})}
              onChangeText={value => handleChange('password', value)}
              value={credentials.password}
              className={
                error.password ? 'input-error form-control' : 'form-control'
              }
            />
            <div className="password-toggle" onClick={togglePasswordVisibility}>
              {showPassword ? <IoEyeOff /> : <IoEye />}
            </div>
          </div>
          <div className="error">
            {' '}
            {error.password && (
              <Text style={styles.error}>{error.password}</Text>
            )}
          </div>
          <Button
            text={intl.formatMessage({id: 'label.login'})}
            onPress={onLogin}
            disabled={isLoginActionDisabled}
            // disabledContainerStyle={{opacity: 0.5}}
            containerStyle={styles.button}
            className="button-text"
          />
        </form>
        <Link to="/forgot-password">{intl.formatMessage({id: 'label.forgot-password'})}</Link>
      </View>
     
    </View>
  );
}

export default LoginForm;
