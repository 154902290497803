import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {showToast} from '../../components/Toast/Toast';

export const addBookmark = createAsyncThunk(
  'add/bookmark',
  async ({payload, intl, isLesson}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.post('/bookmarks', payload);
      showToast(
        getLocalizedMessage(
          intl,
          isLesson ? 'success.lessonBookmark' : 'success.contentBookmark',
        ),
        'success',
      );
      return response.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const deleteBookmark = createAsyncThunk(
  'delete/bookmark',
  async ({intl, id, isLesson}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.delete(`/bookmarks/${id}`);
      showToast(
        getLocalizedMessage(
          intl,
          isLesson ? 'success.lessonUnBookmark' : 'success.contentUnBookmark',
        ),
        'success',
      );
      return response.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const fetchBookmark = createAsyncThunk(
  'add/fetchBookmark',
  async ({ is_content }, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.get(`/bookmarks?is_content=${is_content}`);
      return response?.data?.data?.data;
    } catch (error) {
    
      throw error.response.data.message;
    }
  },
);