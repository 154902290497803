import React, {useContext, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import {useLocation, useNavigate} from 'react-router';
import {useFormik} from 'formik';
import LessonStyleCard from '../../../components/LessonStyleCard/LessonStyleCard';
import CustomButton from '../../../components/CustomButton/CustomButton';
import {getLessonStylePayload} from '../../../utilityFunction/helper';
import {updateLessonStyles} from '../../../store/actions/islandActions';
import {GlobalLoaderContext} from '../../../globalContext/globalLoader/globalLoaderProvider';
import {MANAGE_CONTENTS_PATH} from '../../../constants/routePaths';
import {
  lessonStyleFormFieldNames,
  lessonStyleFormInitialValues,
  validationSchema,
} from '../../../validationSchema/lessonStyleSchema';
import './EditLessonStyle.scss';

export default function EditLessonStyle() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {state} = useLocation();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const {lessonStyleData} = state;

  const {values, errors, touched, handleSubmit, setFieldValue, resetForm} =
    useFormik({
      initialValues: lessonStyleFormInitialValues,
      onSubmit: async values => {
        const payload = getLessonStylePayload(values);
        await dispatch(
          updateLessonStyles({payload, intl, loaderDispatch, navigate}),
        );
      },
      validationSchema: validationSchema(intl),
    });

  useEffect(() => {
    if (lessonStyleData && lessonStyleData.length > 0) {
      const mappedData = lessonStyleData.map(style => ({
        [lessonStyleFormFieldNames.LABEL_NAME]: style.label,
        [lessonStyleFormFieldNames.BG_COLOR]: style.background_color,
        [lessonStyleFormFieldNames.TITLE_TYPE]: style.type,
        [lessonStyleFormFieldNames.MEDIA_ID]: style.media?.id.toString(),
        [lessonStyleFormFieldNames.PREVIEW_URL]: style.media?.path,
        [lessonStyleFormFieldNames.FILE_NAME]: style.media?.name,
      }));
      resetForm({values: mappedData});
    }
  }, [lessonStyleData, resetForm]);

  const handleCancel = () => {
    navigate(MANAGE_CONTENTS_PATH);
  };

  return (
    <div>
      <div className="lesson-heading">
        <div className="lesson-label">
          {intl.formatMessage({id: 'label.lessonStyles'})}
        </div>
        <div className="lessonStyleFormBtn">
          <CustomButton
            labelText={intl.formatMessage({id: 'label.cancel'})}
            handleClick={handleCancel}
            variant="white"
            align="right"
          />
          <CustomButton
            labelText={intl.formatMessage({id: 'label.save'})}
            handleClick={handleSubmit}
            variant="blue"
            align="right"
            type="submit"
          />
        </div>
      </div>

      <div className="lesson-style-wrapper">
        {lessonStyleData?.map((item, index) => {
          const fieldError = errors[index];
          const fieldTouched = touched[index];
          return (
            <LessonStyleCard
              index={index}
              setFieldValue={setFieldValue}
              value={values[index]}
              error={fieldError}
              touched={fieldTouched}
            />
          );
        })}
      </div>
    </div>
  );
}
