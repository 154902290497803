import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {setLoading} from '../../globalContext/globalLoader/globalLoaderAction';
import {showToast} from '../../components/Toast/Toast';
import {getLocalizedMessage} from '../../utilityFunction/helper';

export const getUserNotifications = createAsyncThunk(
  'user/notifications',
  async ({loaderDispatch, currentPage, isPagination = false}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const params = {
        ...(isPagination && {per_page: 10, page: currentPage}),
      };
      const response = await axiosPrivateInstance.get('/notifications', {
        params,
      });
      loaderDispatch(setLoading(false));
      return response?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      throw error.response.data.message;
    }
  },
);

export const markNotificationRead = createAsyncThunk(
  'notification/markRead',
  async ({idNotification, loaderDispatch, intl}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.post(
        `/notifications/${idNotification}`,
      );
      loaderDispatch(setLoading(false));
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const getUserAnnouncements = createAsyncThunk(
  'user/announcements',
  async ({loaderDispatch, currentPage, isPagination = false}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const params = {
        ...(isPagination && {per_page: 10, page: currentPage}),
        type: 'announcement',
      };
      const response = await axiosPrivateInstance.get('/notifications', {
        params,
      });
      loaderDispatch(setLoading(false));
      return response?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      throw error.response.data.message;
    }
  },
);
