import * as Yup from 'yup';

export const lessonStyleFormFieldNames = {
  LABEL_NAME: 'labelName',
  BG_COLOR: 'bgColor',
  MEDIA_ID: 'mediaId',
  PREVIEW_URL: 'previewUrl',
  FILE_NAME: 'fileName',
  TITLE_TYPE: 'type',
};

export const lessonStyleFormInitialValues = {
  [lessonStyleFormFieldNames.LABEL_NAME]: '',
  [lessonStyleFormFieldNames.BG_COLOR]: '',
  [lessonStyleFormFieldNames.MEDIA_ID]: '',
  [lessonStyleFormFieldNames.PREVIEW_URL]: '',
  [lessonStyleFormFieldNames.FILE_NAME]: '',
  [lessonStyleFormFieldNames.TITLE_TYPE]: '',
};

export const validationSchema = intl => {
  return Yup.array().of(
    Yup.object().shape({
      [lessonStyleFormFieldNames.LABEL_NAME]: Yup.string().required(
        intl.formatMessage({id: 'lessonStyle.fieldError.name.required'}),
      ),
      [lessonStyleFormFieldNames.BG_COLOR]: Yup.string().notRequired(),
      [lessonStyleFormFieldNames.MEDIA_ID]: Yup.string().required(
        intl.formatMessage({id: 'lessonStyle.fieldError.image.required'}),
      ),
      [lessonStyleFormFieldNames.PREVIEW_URL]: Yup.string().notRequired(),
      [lessonStyleFormFieldNames.FILE_NAME]: Yup.string().notRequired(),
      [lessonStyleFormFieldNames.TITLE_TYPE]: Yup.string().notRequired(),
    }),
  );
};
