import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {showToast} from '../../components/Toast/Toast';
import {getLocalizedMessage} from '../../utilityFunction/helper';

export const addDistrictData = createAsyncThunk(
  'district/addDistrict',
  async ({districtData, intl}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.post(
        '/districts',
        districtData,
      );
      showToast(
        getLocalizedMessage(intl, 'success.districtCreated'),
        'success',
      );
      return response.data;
    } catch (error) {
      if (error?.response?.data?.code === 'DISTRICT_ALREADY_EXIST')
        showToast(
          getLocalizedMessage(intl, 'error.districtAlreadyExist'),
          'error',
        );
      else
        showToast(
          getLocalizedMessage(intl, 'error.somethingWentWrong'),
          'error',
        );
      throw error.response.data.message;
    }
  },
);

export const editDistrictData = createAsyncThunk(
  'district/editDistrict',
  async ({districtData, districtId, intl}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.put(
        `/districts/${districtId}`,
        districtData,
      );
      showToast(
        getLocalizedMessage(intl, 'success.districtUpdated'),
        'success',
      );
      return response.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const disableDistrictData = createAsyncThunk(
  'district/disableDistrict',
  async ({districtId, intl}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.patch(
        `/districts/status/${districtId}`,
      );
      showToast(getLocalizedMessage(intl, 'success.updated'), 'success');
      return response.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);
