import React from 'react';
import SectionWrapper from '../SectionWrapper.js';
import './UrbanSection.scss';

function UrbanSection() {
  return (
    <SectionWrapper
      backgroundImg="/images/urbanBg.png"
      sectionWrapperClassName="student-urban-section-wrapper"
      helpBackgroundImg="/images/urbanHelp.svg"
      helpBackgroundImgClass="urbanHelp"
    />
  );
}

export default UrbanSection;
