import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {useParams, useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import InputLabel from '../../components/InputLabel/InputLabel';
import SelectDropdown from '../../components/SelectDropdown/SelectDropdown';
import {
  addStudentFormFieldNames,
  addStudentFormInitialValues,
  validationSchema,
} from '../../validationSchema/AddStudentSchema';
import CustomButton from '../../components/CustomButton/CustomButton';
import {
  fetchCounsellors,
  fetchTeachers,
  fetchStudentById,
  addStudent,
  updateStudent,
} from '../../store/actions/studentActions.js';
import {
  checkIsSuperAdmin,
  getCounsellors,
  getTeachers,
  isCounsellors,
  isTeachers,
} from '../../../src/utilityFunction/helper';
import './AddStudent.scss';
import useUserRole from '../../hooks/useUserRole.js';
import UploadFileImage from '../../components/UploadFileImage/UploadFileImage.js';
import {
  fetchGradeList,
  fetchSectionList,
} from '../../store/actions/studentActions.js';

export default function AddStudentForm() {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams();
  const userRole = useUserRole();
  const [editMode, setEditMode] = useState(false);
  const [showEditButton, setShowEditButton] = useState(true);
  const {counsellorsList, teacherList} = useSelector(state => state?.student);
  const sectionList = useSelector(state => state?.student?.sectionList?.data);
  const gradeList = useSelector(state => state?.student?.gradeList?.data?.data);
  const teacherListData = teacherList?.data?.data;
  const counsellorListData = counsellorsList?.data;
  const [fileId, setFileId] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [imageName, setImageName] = useState('');
  const teacherLoginData = useSelector(
    state => state?.auth?.data?.data?.data?.user?.teacher_data,
  );
  const counsellorLoginData = useSelector(
    state => state?.auth?.data?.data?.data?.user?.counsellor_data,
  );

  const teacherId = teacherLoginData?.id;
  const counsellorId = counsellorLoginData?.id
  const isTeacherRole = isTeachers(userRole);
  const isCounsellor = isCounsellors(userRole);

  const {
    values,
    handleChange,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues: addStudentFormInitialValues,
    onSubmit: values => {
      if (id) {
        dispatch(
          updateStudent({
            id,
            payload: {...values, media_id: fileId},
            intl,
            navigate,
          }),
        );
      } else {
        dispatch(
          addStudent({
            studentData: {
              ...values,
              counsellor_id: parseInt(values.counsellor_id),
              media_id: fileId,
            },
            intl: intl,
            navigate,
          }),
        );
      }
    },
    validationSchema: validationSchema(intl, id),
  });

  const fetchData = async () => {
    if (id) {
      try {
        const response = await dispatch(fetchStudentById(id));
        const studentData = response.payload?.data?.data;
        setPreviewUrl(studentData?.media?.path);
        setImageName(studentData?.media?.name);
        setFileId(studentData?.media?.id);
        setValues(prev => ({
          ...prev,
          [addStudentFormFieldNames.FIRST_NAME]: studentData?.first_name,
          [addStudentFormFieldNames.LAST_NAME]: studentData?.last_name,
          [addStudentFormFieldNames.EMAIL]: studentData?.email,
          [addStudentFormFieldNames.COUNSELLOR_ID]: studentData?.counsellor?.id,
          [addStudentFormFieldNames.TEACHER_ID]: studentData?.teacher?.id,
          [addStudentFormFieldNames.GRADE]: studentData?.grade?.id,
          [addStudentFormFieldNames.SECTION]: studentData?.section?.id,
        }));
      } catch (error) {
        console.log('Error fetching student data:', error);
      }
    }
  };

  useEffect(() => {
    dispatch(fetchCounsellors());
    dispatch(fetchTeachers());
    dispatch(fetchGradeList());
    dispatch(fetchSectionList());
  }, []);

  const onSectionChangeSingleSelect = e => {
    const selectedSectionId = e.target.value;
    setFieldValue(addStudentFormFieldNames.SECTION, selectedSectionId);
  };

  const onGradeChange = e => {
    const selectedGradeId = e.target.value;
    setFieldValue(addStudentFormFieldNames.GRADE, selectedGradeId);
  };

  useEffect(() => {
    if (id) {
      setEditMode(false);
      fetchData();
    }
  }, [id]);

  useEffect(() => {
    if (teacherId) {
      setFieldValue(addStudentFormFieldNames.TEACHER_ID, teacherId);
    }
  }, [teacherId, isTeacherRole]);

  useEffect(() => {
    if (counsellorId) {
      setFieldValue(addStudentFormFieldNames.COUNSELLOR_ID, counsellorId);
    }
  }, [counsellorId, isCounsellor]);

  const onTeacherChange = e => {
    const selectedTeacherId = e.target.value;
    setFieldValue(addStudentFormFieldNames.TEACHER_ID, selectedTeacherId);
  };

  const onCounsellorChange = e => {
    const selectedCounsellorId = e.target.value;
    setFieldValue(addStudentFormFieldNames.COUNSELLOR_ID, selectedCounsellorId);
  };

  const toggleEditMode = () => {
    setEditMode(prev => !prev);
    setShowEditButton(false);
  };

  const isSuperAdminRole = checkIsSuperAdmin(userRole);

  return (
    <div className="container">
      <div className="add-student-wrapper">
        <div className="row">
          {!isSuperAdminRole && !isTeacherRole &&  showEditButton && id && (
            <CustomButton
              labelText={intl.formatMessage({id: 'label.edit'})}
              handleClick={toggleEditMode}
              variant="blue"
              align="right"
            />
          )}

          <div className="cus-form-header">
            <h4>{intl.formatMessage({id: 'label.add_student_heading'})}</h4>
            <span>
              {intl.formatMessage({id: 'label.registration_instructions'})}
            </span>
          </div>
          <div className="mb-3 col-md-7">
            <label htmlFor="file" className="form-label cus-form-label">
              Profile image
            </label>
            <UploadFileImage
              setFileId={setFileId}
              previewUrl={previewUrl}
              setPreviewUrl={setPreviewUrl}
              acceptType={'image/*'}
              isDisabled={!editMode && id}
              hideImageTag
              imageName={imageName}
              setImageName={setImageName}
            />
          </div>
          <div className="mb-3 col-md-6">
            <InputLabel
              name={addStudentFormFieldNames.FIRST_NAME}
              label={intl.formatMessage({id: 'label.first_name'})}
              placeholder={intl.formatMessage({id: 'label.enter_first_name'})}
              value={values[addStudentFormFieldNames.FIRST_NAME]}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[addStudentFormFieldNames.FIRST_NAME] &&
                errors[addStudentFormFieldNames.FIRST_NAME]
              }
              disabled={!editMode && id}
            />
          </div>
          <div className="mb-3 col-md-6">
            <InputLabel
              name={addStudentFormFieldNames.LAST_NAME}
              label={intl.formatMessage({id: 'label.last_name'})}
              placeholder={intl.formatMessage({id: 'label.enter_last_name'})}
              value={values[addStudentFormFieldNames.LAST_NAME]}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[addStudentFormFieldNames.LAST_NAME] &&
                errors[addStudentFormFieldNames.LAST_NAME]
              }
              disabled={!editMode && id}
            />
          </div>

          <div className="mb-3 col-md-6">
            <InputLabel
              name={addStudentFormFieldNames.EMAIL}
              label={intl.formatMessage({id: 'label.email_address'})}
              placeholder={intl.formatMessage({
                id: 'label.enter_email_address',
              })}
              value={values[addStudentFormFieldNames.EMAIL]}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[addStudentFormFieldNames.EMAIL] &&
                errors[addStudentFormFieldNames.EMAIL]
              }
              disabled={(!editMode && id) || id}
            />
          </div>

          {!id && (
            <div className="mb-3 col-md-6">
              <InputLabel
                name={addStudentFormFieldNames.CREATE_NEW_PASSWORD}
                label={intl.formatMessage({id: 'label.create_new_password'})}
                placeholder={intl.formatMessage({
                  id: 'label.enter-new-password',
                })}
                value={values[addStudentFormFieldNames.CREATE_NEW_PASSWORD]}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                error={
                  touched[addStudentFormFieldNames.CREATE_NEW_PASSWORD] &&
                  errors[addStudentFormFieldNames.CREATE_NEW_PASSWORD]
                }
                disabled={!editMode && id}
              />
            </div>
          )}
          {!id && (
            <div className="mb-3 col-md-6">
              <InputLabel
                name={addStudentFormFieldNames.CONFIRM_PASSWORD}
                label={intl.formatMessage({id: 'label.confirm_password'})}
                placeholder={intl.formatMessage({
                  id: 'label.re-enter_password',
                })}
                value={values[addStudentFormFieldNames.CONFIRM_PASSWORD]}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                error={
                  touched[addStudentFormFieldNames.CONFIRM_PASSWORD] &&
                  errors[addStudentFormFieldNames.CONFIRM_PASSWORD]
                }
              />
            </div>
          )}

          <div className="mb-3 col-md-6">
            <SelectDropdown
              options={getCounsellors(counsellorListData)}
              label={intl.formatMessage({id: 'label.assign_counselors'})}
              placeholder={intl.formatMessage({id: 'label.select_counsellor'})}
              required
              name={addStudentFormFieldNames.COUNSELLOR_ID}
              value={values[addStudentFormFieldNames.COUNSELLOR_ID]}
              onChange={e => onCounsellorChange(e)}
              error={
                touched[addStudentFormFieldNames.COUNSELLOR_ID] &&
                errors[addStudentFormFieldNames.COUNSELLOR_ID]
              }
              disabled={isCounsellor || (!editMode && id)}
            />
          </div>
          <div className="mb-3 col-md-6">
            <SelectDropdown
              options={getTeachers(
                isTeacherRole ? [teacherLoginData] : teacherListData,
              )}
              label={intl.formatMessage({id: 'label.assign_teachers'})}
              placeholder={intl.formatMessage({id: 'label.select_teachers'})}
              required
              name={addStudentFormFieldNames.TEACHER_ID}
              value={values[addStudentFormFieldNames.TEACHER_ID]}
              onChange={e => onTeacherChange(e)}
              error={
                touched[addStudentFormFieldNames.TEACHER_ID] &&
                errors[addStudentFormFieldNames.TEACHER_ID]
              }
              disabled={isTeacherRole || (!editMode && id)}
            />
          </div>

          <div className="mb-3 col-md-6">
            <SelectDropdown
              options={gradeList}
              label={intl.formatMessage({id: 'label.select_grade'})}
              placeholder={intl.formatMessage({id: 'label.select_grade'})}
              required
              name={addStudentFormFieldNames.GRADE}
              value={values[addStudentFormFieldNames.GRADE]}
              onChange={e => onGradeChange(e)}
              error={
                touched[addStudentFormFieldNames.GRADE] &&
                errors[addStudentFormFieldNames.GRADE]
              }
              disabled={!editMode && id}
            />
          </div>
          <div className="mb-3 col-md-6">
            <SelectDropdown
              options={sectionList}
              label={intl.formatMessage({id: 'label.assign_group'})}
              placeholder={intl.formatMessage({id: 'label.assign_group'})}
              required
              name={addStudentFormFieldNames.SECTION}
              value={values[addStudentFormFieldNames.SECTION]}
              onChange={e => onSectionChangeSingleSelect(e)}
              error={
                touched[addStudentFormFieldNames.SECTION] &&
                errors[addStudentFormFieldNames.SECTION]
              }
              disabled={!editMode && id}
            />
          </div>
        </div>
        {!(!editMode && id) && (
          <div className="schoolFormBtn">
            <CustomButton
              labelText={intl.formatMessage({id: 'label.cancel'})}
              handleClick={() => navigate(-1)}
              variant="white"
              align="right"
            />
            <CustomButton
              labelText={
                id
                  ? intl.formatMessage({id: 'label.save_changes'})
                  : intl.formatMessage({id: 'label.register'})
              }
              handleClick={handleSubmit}
              variant="blue"
              align="right"
              disabled={!editMode && id}
            />
          </div>
        )}
      </div>
    </div>
  );
}
