import React from 'react';
import './StarProgressButton.scss';

function StarProgressButton({ progress, className, value, showProgress = true }) {
  return (
    <div className={`star-progress-btn-outer ${className}`}>
      {showProgress && (
        <div className="star-progress-btn-wrapper">
          <div className="star-progress-bg"></div>
          <div className="starProgress" style={{ width: `${progress}%` }}></div>
        </div>
      )}
      <div className="button-count-wrapper">
        <img
          className="active-button"
          src={"/images/yellowbtn.svg"}
          alt={value ? "yellow btn" : "play btn"}
        />
        <div className="button-count">{value || <img src="/images/play.svg" />}</div>
      </div>
    </div>
  );
}

export default StarProgressButton;
