import React, {useEffect} from 'react';
import CustomDialogue from '../../components/CustomDialogue/CustomDialogue';
import InputLabel from '../../components/InputLabel/InputLabel';
import {useIntl} from 'react-intl';
import {addSuperAdminFieldName} from '../../validationSchema/addSuperAdminSchema.js';
const AddSuperAdmin = ({
  open,
  handleClose,
  handleSubmit,
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  helpData,
  editMode,
}) => {
  const intl = useIntl();

  useEffect(() => {
    if (!editMode) {
      setFieldValue(addSuperAdminFieldName.FIRST_NAME, '');
      setFieldValue(addSuperAdminFieldName.LAST_NAME, '');
      setFieldValue(addSuperAdminFieldName.EMAIL, '');
    }
  }, [editMode, setFieldValue]);
  return (
    <CustomDialogue
      open={open}
      handleClose={handleClose}
      cancelText={intl.formatMessage({
        id: 'label.cancel',
      })}
      confirmText={intl.formatMessage({
        id: 'label.save',
      })}
      onCancel={handleClose}
      hideTitle={false}
      onSubmit={handleSubmit}>
      <div className="container">
        <div className="row mt-4">
          <div className="mb-3 col-md-6">
            <InputLabel
              name={addSuperAdminFieldName.FIRST_NAME}
              label={intl.formatMessage({
                id: 'label.first_name',
              })}
              placeholder={intl.formatMessage({
                id: 'label.enter_first_name',
              })}
              required
              value={values[addSuperAdminFieldName.FIRST_NAME]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[addSuperAdminFieldName.FIRST_NAME] &&
                errors[addSuperAdminFieldName.FIRST_NAME]
              }
            />
          </div>
          <div className="mb-3 col-md-6">
            <InputLabel
              name={addSuperAdminFieldName.LAST_NAME}
              label={intl.formatMessage({
                id: 'label.last_name',
              })}
              placeholder={intl.formatMessage({
                id: 'label.enter_last_name',
              })}
              required
              value={values[addSuperAdminFieldName.LAST_NAME]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[addSuperAdminFieldName.LAST_NAME] &&
                errors[addSuperAdminFieldName.LAST_NAME]
              }
            />
          </div>
          <div className="mb-3 col-md-6">
            <InputLabel
              name={addSuperAdminFieldName.EMAIL}
              label={intl.formatMessage({
                id: 'label.email_address',
              })}
              placeholder={intl.formatMessage({
                id: 'label.enter_email_address',
              })}
              required
              value={values[addSuperAdminFieldName.EMAIL]}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[addSuperAdminFieldName.EMAIL] &&
                errors[addSuperAdminFieldName.EMAIL]
              }
              disabled={editMode}
            />
          </div>
        </div>
      </div>
    </CustomDialogue>
  );
};

export default AddSuperAdmin;
