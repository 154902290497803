import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {showToast} from '../../components/Toast/Toast';
import { setLoading } from '../../globalContext/globalLoader/globalLoaderAction';
import {MANAGE_STAFF_PATH} from '../../constants/routePaths';

export const fetchStaffList = createAsyncThunk(
  'staff/fetchStaffList',
  async (
    {searchTerm, currentPage, sortOrder, selectedRole, schoolId,loaderDispatch},
    thunkAPI,
  ) => {
    loaderDispatch(setLoading(true));
    try {
      const params = {
        per_page: 10,
        page: currentPage,
        sort_order: sortOrder,
        ...(searchTerm && {search: searchTerm}),
        ...(selectedRole && {role: selectedRole}),
        ...(schoolId && {school_id: schoolId}),
      };

      const response = await axiosPrivateInstance.get('/staffs', {params});
      loaderDispatch(setLoading(false));
      return response?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      throw error.response.data.message;
    }
  },
);

export const updateStaffStatus = createAsyncThunk(
  'staff/updateStaffStatus',
  async ({id, intl}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.patch(`/staffs/status/${id}`);
      showToast(getLocalizedMessage(intl, 'success.updated'), 'success');
      return response.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const addStaff = createAsyncThunk(
  'staff/addStaff',
  async ({staffData, intl, navigate}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.post('/staffs', staffData);
      showToast(getLocalizedMessage(intl, 'staff.added'), 'success');
      navigate(MANAGE_STAFF_PATH);
      return response.data;
    } catch (error) {
      if (error.response.data.code === "USER_ALREADY_EXIST")
        showToast(getLocalizedMessage(intl, 'label.email_exists'), error);
      else
        showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), error);
      throw error.response.data.message;
    }
  },
);

export const updateStaff = createAsyncThunk(
  'staff/updateStaff',
  async ({id, staffData, intl, navigate}, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.put(
        `/staffs/${id}`,
        staffData,
      );
      showToast(getLocalizedMessage(intl, 'success.updated'), 'success');
      navigate(MANAGE_STAFF_PATH);
      return response.data;
    } catch (error) {
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      console.log('Errorstaff', error);

      throw error.response.data.message;
    }
  },
);

export const fetchStaffById = createAsyncThunk(
  'staff/fetchStaffById',
  async (id, thunkAPI) => {
    try {
      const response = await axiosPrivateInstance.get(`staffs/${id}`);
      return response.data;
    } catch (error) {
      throw error.response.data.message;
    }
  },
);

export const fetchRole = createAsyncThunk('staff/fetchRole', async () => {
  try {
    const response = await axiosPrivateInstance.get('/roles');

    return response.data.data;
  } catch (error) {
    throw error.response.data.message;
  }
});
