import {createSlice} from '@reduxjs/toolkit';
import {addNote, getNotes} from '../actions/notesActions';

const initialState = {
  lessonNotes: [],
};

const lessonNotesSlice = createSlice({
  name: 'lessonNotes',
  initialState,
  reducers: {
    // for reducers
  },
  extraReducers: builder => {
    builder
      .addCase(getNotes.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getNotes.fulfilled, (state, action) => {
        state.loading = false;
        state.lessonNotes = action.payload;
        state.error = null;
      })
      .addCase(getNotes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default lessonNotesSlice.reducer;
