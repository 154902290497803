import {createSlice} from '@reduxjs/toolkit';
import {fetchStaffList, fetchRole} from '../actions/staffActions';

const initialState = {
  staffsList: [],
  roleList:[],
};

const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    // for reducers
  },
  extraReducers: builder => {
    builder
      .addCase(fetchStaffList.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStaffList.fulfilled, (state, action) => {
        state.loading = false;
        state.staffsList = action.payload;
        state.error = null;
      })
      .addCase(fetchStaffList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchRole.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRole.fulfilled, (state, action) => {
        state.loading = false;
        state.roleList = action.payload;
        state.error = null;
      })
      .addCase(fetchRole.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default staffSlice.reducer;
