import * as Yup from 'yup';
import {EMAIL_REGEX, NAME_REGEX, DIGITS_REGEX} from '../core/validation';
export const addStaffFormFieldNames = {
  ID: 'employee_id',
  MEDIA_ID: 'media_id',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email_address',
  DESIGNATION: 'role',
};
export const addStaffFormInitialValues = {
  [addStaffFormFieldNames.ID]: '',
  [addStaffFormFieldNames.MEDIA_ID]: '',
  [addStaffFormFieldNames.FIRST_NAME]: '',
  [addStaffFormFieldNames.LAST_NAME]: '',
  [addStaffFormFieldNames.EMAIL]: '',
  [addStaffFormFieldNames.DESIGNATION]: '',
};
export const validationSchema = intl => {
  return Yup.object().shape({
    [addStaffFormFieldNames.ID]: Yup.string()
      .notRequired()
      .min(2, intl.formatMessage({id: 'label.fieldError.id.minLength'}))
      .max(10, intl.formatMessage({id: 'label.fieldError.id.maxLength'}))
      .matches(
        DIGITS_REGEX,
        intl.formatMessage({id: 'label.fieldError.invalid.id'}),
      ),
    [addStaffFormFieldNames.FIRST_NAME]: Yup.string()
      .required(intl.formatMessage({id: 'label.first_name_required'}))
      .max(
        150,
        intl.formatMessage({id: 'label.fieldError.first_name.maxLength'}),
      )
      .matches(
        NAME_REGEX,
        intl.formatMessage({id: 'label.fieldError.whitespace'}),
      ),
    [addStaffFormFieldNames.LAST_NAME]: Yup.string()
      .required(intl.formatMessage({id: 'label.last_name_required'}))
      .max(
        150,
        intl.formatMessage({id: 'label.fieldError.first_name.maxLength'}),
      )
      .matches(
        NAME_REGEX,
        intl.formatMessage({id: 'label.fieldError.whitespace'}),
      ),
    [addStaffFormFieldNames.EMAIL]: Yup.string()
      .required(intl.formatMessage({id: 'label.email_required'}))
      .matches(EMAIL_REGEX, intl.formatMessage({id: 'label.fieldError.email'})),
    [addStaffFormFieldNames.DESIGNATION]: Yup.string().required(
      intl.formatMessage({id: 'label.designation_required'}),
    ),
  });
};
