import React, {useContext, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import './StaffStudentAttachments.scss';
import {getAllAttachments} from '../../store/actions/fileActions';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';
import TabsFilterSection from '../../components/TabsFilterSection/TabsFilterSection';

export default function StaffStudentAttachments({lesson}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc'); // or 'desc' for descending order
  const [selectedLesson, setSelectedLesson] = useState(''); // New state for selected lesson
  const state = useSelector(state => state);

  useEffect(() => {
    const fetchAttachments = async () => {
      try {
        const attachments = await dispatch(
          getAllAttachments({
            intl,
            loaderDispatch,
          }),
        ).unwrap();
        setUploadedFiles(attachments);
      } catch (error) {
        console.error('Failed to fetch attachments', error);
      }
    };

    fetchAttachments();
  }, [dispatch, lesson, intl, loaderDispatch, state]);

  const extractAttachment = url => {
    if (!url) return '';
    const match = url.match(/\/attachment\/(.+)/);
    return match ? match[1] : '';
  };

  const onSearch = term => {
    setSearchTerm(term);
  };

  const onSortChange = () => {
    setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const onLessonChange = e => {
    setSelectedLesson(e.target.value);
  };

  const filteredAndSortedFiles = uploadedFiles
    .filter(file => {
      const attachment = extractAttachment(file.attachment_path).toLowerCase();
      const student = file.student ? file.student.toLowerCase() : '';
      const lessonName = file.lesson ? file.lesson.toLowerCase() : '';
      return (
        (attachment.includes(searchTerm.toLowerCase()) ||
          student.includes(searchTerm.toLowerCase()) ||
          lessonName.includes(searchTerm.toLowerCase())) &&
        (selectedLesson === '' || file.lesson === selectedLesson)
      );
    })
    .sort((a, b) => {
      const fileA = extractAttachment(a.attachment_path).toLowerCase();
      const fileB = extractAttachment(b.attachment_path).toLowerCase();
      if (sortOrder === 'asc') {
        return fileA > fileB ? 1 : -1;
      } else {
        return fileA < fileB ? 1 : -1;
      }
    });

  return (
    <>
      <div>
        <div className="d-flex student-detail-wrapper">
          <div className="school-label">
            {intl.formatMessage({id: 'label.list-of-attachments'})}
          </div>
        </div>

        <div className="d-flex align-items-center gap-2">
          <TabsFilterSection
            onSearchChange={e => onSearch(e.target.value)}
            handleSort={onSortChange}
            sortOrder={sortOrder}
            searchPlaceholder={intl.formatMessage({
              id: 'label.search_name_class',
            })}
          />

          {/* Dropdown for selecting lesson */}
          <div>
            <select
              id="lessonFilter"
              className="module-dropdown form-select"
              value={selectedLesson}
              onChange={onLessonChange}>
              <option value="">
                {intl.formatMessage({id: 'label.allLessons'})}
              </option>
              {Array.from(new Set(uploadedFiles.map(file => file.lesson)))
                .filter(Boolean)
                .map(lesson => (
                  <option key={lesson} value={lesson}>
                    {lesson}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="student-attachments-wrapper">
          <div className="fw-bold mb-2">
            {intl.formatMessage({id: 'label.attachmentsUploadedByStudents'})}:
          </div>
          {filteredAndSortedFiles.length > 0 ? (
            <ol className="ps-3 attachment-container">
              {filteredAndSortedFiles.map(file => (
                <li key={file.attachment_id} className="mb-3">
                  <div className="attachment-title mb-1">
                    <a
                      href={file.attachment_path}
                      className="link-primary attachment-link fw-bold"
                      target="_blank"
                      rel="noopener noreferrer"
                      download={extractAttachment(file.attachment_path)}>
                      {extractAttachment(file.attachment_path)}
                    </a>
                  </div>
                  <div className="attachment-footer d-flex gap-2">
                    <div>
                      <span className="fw-bold">
                        {intl.formatMessage({id: 'label.uploadedBy'})}
                      </span>
                      : {file.student ? file.student : 'N/A'}
                    </div>
                    <div className="separator"></div>
                    <div>
                      <span className="fw-bold">
                        {intl.formatMessage({id: 'label.module'})}
                      </span>
                      : {file.lesson}
                    </div>
                  </div>
                </li>
              ))}
            </ol>
          ) : (
            <div>{intl.formatMessage({id: 'label.noUploadedFiles'})}</div>
          )}
        </div>
      </div>
    </>
  );
}
