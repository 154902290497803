import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {showToast} from '../../components/Toast/Toast';
import {setLoading} from '../../globalContext/globalLoader/globalLoaderAction';
import {STUDENT_PROGRESS_REPORT_PATH} from '../../constants/routePaths';

export const exportStudentProgressReport = createAsyncThunk(
  'reports/exportStudentProgressReport',
  async ({schoolId, intl, navigate, loaderDispatch}, thunkAPI) => {
    try {
      loaderDispatch(setLoading(true));
      const response = await axiosPrivateInstance.get(
        `/student-progress-report/${schoolId}`,
      );
      loaderDispatch(setLoading(false));
      return response.data.data;
    } catch (error) {
      if (error.response.data.code === 'USER_ALREADY_EXIST')
        showToast(getLocalizedMessage(intl, 'label.email_exists'), error);
      else
        showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), error);
      throw error.response.data.message;
    }
  },
);
