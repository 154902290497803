import axios from 'axios';
import { refreshToken } from '../services/token-service';
import {
  getLocalStorageItem,
} from '../services/local-storage';


import authSlice, { resetState } from '../store/reducers/authReducers.js';
import {LOGIN_PATH} from '../constants/routePaths';


const axiosPrivateInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosPrivateInstance.defaults.headers.common['Content-Type'] = 'application/json';

//will remove later on,used this to run local server
axiosPrivateInstance.defaults.headers.common['ngrok-skip-browser-warning'] = 'true';

axiosPrivateInstance.interceptors.request.use(async config => {
  const accessToken = await getLocalStorageItem('accessToken');
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }
  return config;
});

let isRefreshing = false;
let failedRequestsQueue = [];

const processQueue = (error, token = null) => {
  failedRequestsQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedRequestsQueue = [];
};

axiosPrivateInstance.interceptors.response.use(
  async response => {
    const accessToken = await getLocalStorageItem('accessToken');
    if (accessToken) {
      response.config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return response;
  },
  async error => {
    const originalRequest = error.config;
    
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        try {
          const token = await new Promise((resolve, reject) => {
            failedRequestsQueue.push({ resolve, reject });
          });
          originalRequest.headers['Authorization'] = `Bearer ${token}`;
          return axiosPrivateInstance(originalRequest);
        } catch (err) {
          return Promise.reject(err);
        }
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const access_token = await refreshToken();
        await getLocalStorageItem('accessToken');
        originalRequest.headers['Authorization'] = `Bearer ${access_token}`;
        processQueue(null, access_token);
        return axiosPrivateInstance(originalRequest);
      } catch (err) {
        window.localStorage.clear();
        processQueue(err, null);
        resetState();
        window.location.reload();
        window.location.replace(LOGIN_PATH);
        return Promise.reject(err);
      } finally {
        isRefreshing = false;
      }
    } else if (error.response.status === 500) {
      const errorMessage = error.message ? error.message : "Something went wrong on the server.";
    } else if (error.response.status === 422) {
      throw new Error("Invalid request: " + JSON.stringify(error.response.data));
    }
    return Promise.reject(error);
  },
);

export default axiosPrivateInstance;
