import React from 'react';
import {useIntl} from 'react-intl';
import {getLocalizedMessage} from '../../../../../utilityFunction/helper';
import './NoContentViewStyle.scss';

export default function NoContentView() {
  const intl = useIntl();
  const contentItems = [
    {
      image: '/images/blueText.svg',
      text: getLocalizedMessage(intl, 'label.content.text'),
    },
    {
      image: '/images/blueAttachment.svg',
      text: getLocalizedMessage(intl, 'label.content.attachment'),
    },
    {
      image: '/images/blueVideo.svg',
      text: getLocalizedMessage(intl, 'label.content.image'),
    },
    {
      image: '/images/blueArticles.svg',
      text: getLocalizedMessage(intl, 'label.content.articles'),
    },
    {
      image: '/images/blueAlerts.svg',
      text: getLocalizedMessage(intl, 'label.content.alert'),
    },
    {
      image: '/images/blueNote.svg',
      text: getLocalizedMessage(intl, 'label.content.note'),
    },
    {
      image: '/images/blueRemember.svg',
      text: getLocalizedMessage(intl, 'label.content.remember'),
    },
    {
      image: '/images/blueAdvice.svg',
      text: getLocalizedMessage(intl, 'label.content.bestAdvice'),
    },
  ];

  return (
    <div className="no-content-container">
      <div className="title-row">
        <div className="text">
          {getLocalizedMessage(intl, 'label.startBuildingContent')}
        </div>
      </div>

      <div className="image-grid">
        {contentItems.map((item, index) => (
          <div key={index} className="content-item">
            <img src={item.image} alt={item.text} />
            <div className="content-text">{item.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
