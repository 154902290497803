import React from 'react';
import './DashboardStatisticsCardView.scss';

export default function DashboardStatisticsCardView({title, count, icon}) {
  return (
    <div className="details-cardView">
      <div className="details-cardTitle">{title}</div>
      <div className="cardIcon-wrapper">
        <div className="details-cardCount">{count}</div>
        <img className="details-cardIcon" src={icon} />
      </div>
    </div>
  );
}
