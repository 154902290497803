import React from 'react';
import {Dialog} from '@mui/material';
import {commonStyle} from '../../Style/commonStyle';
import './CustomDialogue.scss';

export default function CustomDialogue({
  open,
  handleClose,
  cancelText,
  confirmText,
  dialogueTitle,
  onSubmit,
  onCancel,
  children,
  className,
  isDisableModal,
  hideTitle = false,
  hideActionsButtons = false,
  isLessonStyleModal,
  modalWrapperClass,
  isStudentDashboard,
}) {
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      className={modalWrapperClass}
      sx={
        isDisableModal
          ? commonStyle.disableModalStyle
          : isLessonStyleModal
          ? commonStyle.lessonStyleModal
          : isStudentDashboard
          ? commonStyle.studentDialogStyle
          : commonStyle.dialogueStyle
      }>
      <div className="modal-header-content">
        {!hideTitle && (
          <div className="dialogueTitleStyle">{dialogueTitle}</div>
        )}
        <div className="col align-self-end text-end">
          <img
            src="/images/close.svg"
            className="close-icon"
            alt="close"
            onClick={onCancel}
          />
        </div>
      </div>

      <div>{children}</div>
      {!hideActionsButtons && (
        <div className="container">
          <div
            className={
              className
                ? `actions-button mt-4 ${className}`
                : 'actions-button mt-4'
            }>
            <div className="mb-3 col-md-6">
              <button onClick={onCancel} style={commonStyle.cancelBtn}>
                {cancelText}
              </button>
            </div>

            <div className="mb-3 col-md-6">
              <button onClick={onSubmit} style={commonStyle.confirmBtn} type='submit'>
                {confirmText}
              </button>
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
}
