import React, {useContext} from 'react';
import {MediaQueryContext, useComponentTheme} from '@unthinkable/react-theme';

import {TwoRow} from '../../core/layouts';

function StudentLayout({header, content}) {
  const theme = useComponentTheme('Auth');

  const {current: currentBreakpoint} = useContext(MediaQueryContext);

  let layout = (
    <TwoRow
      style={theme.mainContainerStyle}
      topSection={header}
      bottomSection={content}
      isTopFillSpace={true}
      isBottomFillSpace={false}
    />
  );

  if (currentBreakpoint === 'md') {
    layout = (
      <TwoRow
        topSection={header}
        bottomSection={content}
        isBottomFillSpace={true}
      />
    );
  }

  return layout;
}

export default StudentLayout;
