import React from 'react';
import SectionWrapper from '../SectionWrapper.js';
import './RuralSection.scss';

function RuralSection() {
  return (
    <SectionWrapper
      backgroundImg="/images/ruralBg.png"
      sectionWrapperClassName="student-rural-section-wrapper"
      helpBackgroundImg="/images/ruralHelp.svg"
      helpBackgroundImgClass="ruralHelp"
    />
  );
}

export default RuralSection;
