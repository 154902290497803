import React from 'react';
import {useIntl} from 'react-intl';
import CustomTabs from '../../components/CustomTabs/CustomTabs';
import StaffQuestionAnswerTabView from './StaffQuestionAnswerTabView/StaffQuestionAnswerTabView';
import StaffStudentAttachmentsTabView from './StaffStudentAttachmentsTabView/StaffStudentAttachmentsTabView';

export default function StaffResourcesTabs({lesson, chosenQuestion}) {
  const intl = useIntl();
  const tabLabels = [
    intl.formatMessage({id: 'label.tabs.QuesAns'}),
    intl.formatMessage({id: 'label.tabs.attachments'}),
  ];

  const tabComponents = [
    {
      component: StaffQuestionAnswerTabView,
      props: {lesson: lesson, chosenQuestion: chosenQuestion},
    },
    {
      component: StaffStudentAttachmentsTabView,
      props: {lesson: lesson},
    },
  ];

  return (
    <div>
      <CustomTabs tabLabels={tabLabels} tabComponents={tabComponents} />
    </div>
  );
}
