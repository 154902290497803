import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import { showToast } from '../../components/Toast/Toast';
import { setLoading } from '../../globalContext/globalLoader/globalLoaderAction';

export const fetchStudentDashboard = createAsyncThunk(
  'userData/fetchUserProfile',
  async ({ loaderDispatch }, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.get(`/student-dashboard`);
      loaderDispatch(setLoading(false));
      return response.data.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(error.message, 'error');
      throw error;
    }
  },
);