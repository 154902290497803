import React from 'react';
import SectionWrapper from '../SectionWrapper.js';
import './FantasySection.scss';

function FantasySection() {
  return (
    <SectionWrapper
      backgroundImg="/images/fantasyBg.png"
      sectionWrapperClassName="student-fantasy-section-wrapper"
      helpBackgroundImg="/images/fantasyHelp.png"
      helpBackgroundImgClass="fantasyHelp"
    />
  );
}

export default FantasySection;
