import React from 'react';
import './StudentProfileRightBar.scss';
import { resetState } from '../../../../store/reducers/authReducers';
import { LOGIN_PATH } from '../../../../constants/routePaths';
import { useNavigate } from '../../../../routes';
import { useDispatch } from 'react-redux';
import { getLocalStorageItem, removeLocalStorageItem } from '../../../../services/local-storage';
import {useIntl} from 'react-intl';

function StudentProfileRightBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const myProfile = getLocalStorageItem('myProfile');
  const userProfileImg= myProfile?.media?.path;
  const handleLogout = () => {
    try {
      dispatch(resetState());
      removeLocalStorageItem('userRole');
      navigate(LOGIN_PATH);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="student-profile-right">
        <div className="student-profile-wrapper">
          <img src={userProfileImg || '/images/user.png'} alt="student profile" />
          <a>
            <img src="/images/setting.png" alt="setting" />
            {intl.formatMessage({id: 'label.settings'})}
            
          </a>
          <a className="active">
            <img src="/images/profile-white.png" alt="profile" />
            {intl.formatMessage({id: 'label.my_profile'})}
          </a>
        </div>
      </div>
      <div className="student-profile-right px-3 py-0 mt-4">
        <div className="student-profile-wrapper">
          <a onClick={handleLogout}>
            <img src="/images/logout.png" alt="logout" />
            {intl.formatMessage({id: 'label.logout'})}
          </a>
        </div>
      </div>
    </div>
  );
}


export default StudentProfileRightBar;
