import React, {useEffect, useState, useContext} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';
import {
  getSectionsLesson,
  markLessonCompleted,
} from '../../../store/actions/lessonActions';
import ThreeDButton from '../../../components/ThreeDButton/ThreeDButton';
import CustomModalStudent from '../../../components/CustomModalStudent/CustomModalStudent';
import LessonUncompleteModal from '../StudentLessonStatusModal/LessonUncompleteModal/LessonUncompleteModal';
import LessonCompleteModal from '../StudentLessonStatusModal/LessonCompleteModal/LessonCompleteModal';
import {GlobalLoaderContext} from '../../../globalContext/globalLoader/globalLoaderProvider';
import {commonStyle} from '../../../Style/commonStyle';
import './LessonContentActionButtons.scss';

export default function LessonContentActionButtons({
  setShowLessonContentModal,
  setCurrentLesson,
  nextLessonClicked,
  previousLessonClicked,
  sectionId,
  myLessonInformation,
  setMyLessonInformation,
  setIsSpeaking,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(
    myLessonInformation?.is_completed,
  );
  const [showLessonBackModal, setShowLessonBackModal] = useState(false);
  const [showLessonCompleteModal, setShowLessonCompleteModal] = useState(false);
  const [readTime, setReadTime] = useState(new Date());

  useEffect(() => {
    setReadTime(new Date()); // Used to track how long a student has been reading the lesson.
    let timer;
    // If the currentLesson lesson is not completed, disable the next button and set a timer
    if (!myLessonInformation?.is_completed) {
      setIsNextButtonEnabled(false);
      timer = setTimeout(() => {
        // Enable the next button after the specified minimum read time in minutes
        setIsNextButtonEnabled(true);
      }, myLessonInformation?.minimum_read_time * 60 * 1000); // Convert minutes to milliseconds
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [myLessonInformation]);

  const handleNextLessonClick = () => {
    const isCurrentLessonCompleted = myLessonInformation?.is_completed;
    if (!isCurrentLessonCompleted) {
      const currentTime = new Date();
      const timeSpentInSeconds = Math.floor((currentTime - readTime) / 1000);
      const payload = {
        lesson_id: myLessonInformation?.id,
        read_time: timeSpentInSeconds,
      };
      dispatch(markLessonCompleted({payload, intl, loaderDispatch}))
        .then(res => {
          dispatch(
            getSectionsLesson({
              sectionId: sectionId,
              loaderDispatch,
              isPaginated: false,
            }),
          ).then(() => {
            setReadTime(timeSpentInSeconds);
            setShowLessonCompleteModal(true);
          });
        })
        .catch(error => {
          console.error('Lesson Not Marked Completed', error);
        });
    } else {
      nextLessonClicked();
    }
  };

  const lessonBackHandler = () => {
    setShowLessonBackModal(true);
  };

  const onExitLessonClick = () => {
    setShowLessonBackModal(false);
    setShowLessonContentModal(false);
    setReadTime(null); // On exit of a lesson set the read time to null
    setCurrentLesson(null);
    setMyLessonInformation(null);
    if ('speechSynthesis' in window) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
    }
  };

  const onKeepLearningClick = () => {
    setShowLessonBackModal(false);
  };

  const onCloseLessonCompleteHandler = () => {
    setShowLessonCompleteModal(false);
    setReadTime(null);
    setShowLessonContentModal(false);
    setCurrentLesson(null);
    setMyLessonInformation(null);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
        className="studentIslandModalButtons">
        <div className="d-flex justify-content-between align-items-center gap-2">
          <ThreeDButton
            handleClick={lessonBackHandler}
            labelText={intl.formatMessage({id: 'label.exit-lesson'})}
            buttonColorClass="blueBtn"
            innerColorClass="blueInner"
          />

          <ThreeDButton
            handleClick={previousLessonClicked}
            labelText={intl.formatMessage({id: 'label.previous-lesson'})}
            buttonColorClass="blueBtn"
            innerColorClass="blueInner"
          />
        </div>

        <ThreeDButton
          handleClick={handleNextLessonClick}
          disabled={!isNextButtonEnabled}
          labelText={intl.formatMessage({id: 'label.next-lesson'})}
        />
      </div>

      {showLessonBackModal && (
        <CustomModalStudent
          open={showLessonBackModal}
          modalWidth={'40%'}
          contentStyle={commonStyle.blueModalContentStyle}>
          <LessonUncompleteModal
            exitLessonHandler={onExitLessonClick}
            keepLearningHandler={onKeepLearningClick}
          />
        </CustomModalStudent>
      )}

      {showLessonCompleteModal && (
        <CustomModalStudent
          open={showLessonCompleteModal}
          modalWidth={'40%'}
          contentStyle={commonStyle.blueModalContentStyle}
          showHeader
          dialogHeader={
            <LessonCompleteHeader onClose={onCloseLessonCompleteHandler} />
          }>
          <LessonCompleteModal
            readTime={readTime}
            handleStartNewLesson={() => {
              setShowLessonCompleteModal(false);
              setReadTime(null);
              setMyLessonInformation(null);
              nextLessonClicked();
            }}
          />
        </CustomModalStudent>
      )}
    </>
  );
}

const LessonCompleteHeader = ({onClose}) => {
  return (
    <div className="lesson-complete-header">
      <img
        src="/images/closeIcon.svg"
        className="complete-close-icon"
        onClick={onClose}
      />
    </div>
  );
};
