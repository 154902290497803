export const validatePassword = value => {
  const passwordRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?=.*[a-zA-Z]).{8,}$/;
  return passwordRegex.test(value);
};

export const isEmailValid = value => {
  if (value === '') {
    return true;
  }
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(value);
};

export const EMAIL_REGEX =
  /^([0-9a-zA-Z]+['\-._+]?)+[0-9a-zA-Z]+[@]([0-9a-zA-Z]+[-]?){2,}[.](([a-zA-Z]+[-]?){2,}[.]){0,2}[a-zA-Z]{2,}$/;

export const numberRegex = /^\d*(\.\d*)?$/;
export const WHITE_SPACES_REGEX = /^(\S+$)/g;
export const MOBILE_NUMBER_REGEX = /^\d{6,14}$/;
export const NAME_REGEX = /^[A-Za-z]+(?:[ ][A-Za-z]+)*$/;
export const DIGITS_REGEX = /^\d+$/;
export const USERNAME_REGEX = /^(?!.\.\.)(?!.\.$)@?[^\W][\w.@]{0,29}$/;
export const URL_REGEX = /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/; //NOSONAR

export const PASSWORD_REGEX_WITH_SPECIAL_CHARACTERS =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])(?=.{8,}$)/;
export const LESSON_NAME_REGEX = /^[A-Za-z\d?@&!#$%^&*()_+\-=\[\]{};':"\\|,.<>\/]+(?:[ ][A-Za-z\d?@&!#$%^&*()_+\-=\[\]{};':"\\|,.<>\/]+)*$/;
export const ALPHANUMERIC_REGEX = /^[a-zA-Z0-9]+$/;

