import React from 'react';
import { View } from '@unthinkable/react-core-components';
import './Tooltip.scss';

const Tooltip = ({ labelText, className, labelClassName }) => {

  return (
    <View>
      <button className={`tooltip-wrapper ${className}`}>
        <span className="tooltip-inner-wrapper">
          <span className={`tooltip-text ${labelClassName}`}>{labelText}</span>
        </span>
      </button>
    </View>
  );
};

export default Tooltip;
