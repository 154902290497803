import React, {useEffect} from 'react';
import {useIntl} from 'react-intl';
import './AvatarCardFrame.scss';

export default function AvatarCardFrame({
  avatarOptions,
  onAvatarClick,
  selectedAvatarId,
}) {
  useEffect(() => {
    if (
      (selectedAvatarId === null || selectedAvatarId === undefined) &&
      avatarOptions.length > 0
    ) {
      onAvatarClick(avatarOptions[0]?.id);
    }
  }, [avatarOptions, onAvatarClick, selectedAvatarId]);

  return (
    <div className="container">
      <div className="avatarsContainer row">
        {avatarOptions?.map((avatar, index) => (
          <div className="avatarCardDetailsWrapper col-md-4" key={avatar?.id}>
            <div
              className={`detailsContainer ${
                avatar.id === selectedAvatarId ? 'selected' : ''
              }`}>
              <div
                className="detailsWrapper cursor"
                onClick={() => onAvatarClick(avatar?.id)}>
                {avatar && (
                  <img
                    className="fix-frame"
                    src={
                      avatar.id === selectedAvatarId
                        ? '/images/selectedFrame.svg'
                        : '/images/avatarFrame.svg'
                    }
                  />
                )}
                {avatar && (
                  <img
                    className="avatarIcon"
                    src={avatar?.media?.path}
                    alt="Avatar icon"
                  />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
