import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import {useIntl} from 'react-intl';
import CustomModalStudent from '../../../../components/CustomModalStudent/CustomModalStudent';
import {GlobalLoaderContext} from '../../../../globalContext/globalLoader/globalLoaderProvider';
import {commonStyle} from '../../../../Style/commonStyle';
import './StudentNotesView.scss';
import ContentWrapper from '../../StudentViewLessonContent/ContentWrapper';
import ThreeDButton from '../../../../components/ThreeDButton/ThreeDButton';
import {
  addNotesFormFieldNames,
  addNotesFormInitialValues,
  validationSchema,
} from '../../../../validationSchema/addNotesSchema';
import {
  addNote,
  getNotes,
  updateNote,
  deleteNote,
} from '../../../../store/actions/notesActions';

export default function StudentNotesView({currentLesson}) {
  const CHAR_LIMIT = 500;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const [remainingChars, setRemainingChars] = useState(CHAR_LIMIT);
  const [isTyping, setIsTyping] = useState(false);
  const [editingNote, setEditingNote] = useState(null);
  const lessonId = currentLesson?.id;

  const {values, handleChange, errors, handleSubmit, handleBlur, setValues} =
    useFormik({
      initialValues: addNotesFormInitialValues,
      onSubmit: async values => {
        const payload = {
          ...values,
          lesson_id: lessonId,
        };
        if (editingNote) {
          payload.note_id = editingNote.id;
          await dispatch(updateNote({payload, intl, loaderDispatch})).then(
            () => {
              setEditingNote(null);
            },
          );
          values[addNotesFormFieldNames.NOTE] = '';
        } else {
          await dispatch(addNote({payload, intl, loaderDispatch})).then(() => {
            values[addNotesFormFieldNames.NOTE] = '';
            setRemainingChars(CHAR_LIMIT);
          });
        }
        await dispatch(getNotes({lessonId}));
      },
      validationSchema: validationSchema(intl),
    });

  const handleCharCount = e => {
    handleChange(e);
    const chars = e.target.value.length;
    setRemainingChars(CHAR_LIMIT - chars);
    setIsTyping(e.target.value.length > 0);
  };

  const isSubmitDisabled =
    Object.keys(errors).length > 0 || !values[addNotesFormFieldNames.NOTE];

  const lessonNotes = useSelector(state => state?.notes?.lessonNotes);
  useEffect(() => {
    dispatch(getNotes({lessonId}));
  }, [dispatch, lessonId]);

  const handleEdit = note => {
    setEditingNote(note);
    setValues({
      [addNotesFormFieldNames.NOTE]: note.note,
    });
    setRemainingChars(CHAR_LIMIT - note.note.length);
  };

  const handleDelete = async noteId => {
    await dispatch(
      deleteNote({
        noteId,
        intl,
        loaderDispatch,
      }),
    );
    dispatch(getNotes({lessonId}));
  };

  return (
    <>
      <div className="mb-4">
        {lessonNotes.length > 0 ? <h4 className="mb-4">My Notes:</h4> : null}
        {lessonNotes?.map((note, index) => (
          <div key={index} className="note-item mb-3">
            <div className="d-flex gap-2 align-items-center">
              <span>
                {index + 1}. {note.note}
              </span>
              <button
                className="btn btn-outline-secondary btn-sm"
                onClick={() => handleEdit(note)}>
                Edit
              </button>
              <button
                className="btn btn-outline-danger btn-sm"
                onClick={() => handleDelete(note.id)}>
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="searchQuestions-wrapper">
        <div style={{flex: 1}}>
          <ContentWrapper
            outerClassName="outer-searchdoc-container"
            innerClassName="inner-searchdoc-container">
            <div className="searchQues-contentWrapper">
              <textarea
                className="quesSearchInputField"
                type="text"
                placeholder={intl.formatMessage({
                  id: 'placeholder.add_notes_here',
                })}
                name={addNotesFormFieldNames.NOTE}
                value={values[addNotesFormFieldNames.NOTE]}
                onChange={handleCharCount}
                onBlur={handleBlur}
                maxLength={CHAR_LIMIT}
              />
            </div>
          </ContentWrapper>
          <div className="quesTitle-wrapper">
            <div className="quesWordLimit fs-6">
              {isTyping
                ? intl.formatMessage(
                    {id: 'label.charactersLeft'},
                    {count: remainingChars},
                  )
                : intl.formatMessage({id: 'label.notesLimit'})}
            </div>
          </div>
        </div>
        <div className="searchQues-btn">
          <ThreeDButton
            className="border-0"
            labelText={intl.formatMessage({
              id: editingNote ? 'label.button.update' : 'label.save',
            })}
            buttonColorClass="blueBtn"
            labelClassName="search-btn-label"
            innerColorClass="blueInner"
            handleClick={handleSubmit}
            disabled={isSubmitDisabled}
            type="submit"
          />
        </div>
      </div>
    </>
  );
}
