import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import NotificationCardView from '../../NotificationCardView/NotificationCardView';
import {
  getNotificationDesc,
  isToday,
  sortNotificationsByDate,
  timeAgo,
} from '../../../utilityFunction/helper';
import {
  getUserNotifications,
  markNotificationRead,
} from '../../../store/actions/notificationActions';
import {GlobalLoaderContext} from '../../../globalContext/globalLoader/globalLoaderProvider';
import './NotificationsLayoutList.scss';

export default function NotificationsLayoutList({
  NotifyClassName,
  desc,
  btnText,
  notifyIcon,
  redirectHandler,
  isPagination,
  showGoToBtn,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const {userNotifications} = useSelector(state => state?.notifications);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchUserNotifications();
  }, [currentPage]);

  function fetchUserNotifications() {
    dispatch(
      getUserNotifications({
        loaderDispatch,
        isPagination: isPagination,
        currentPage: currentPage,
      }),
    );
  }

  function onNotificationClick(notification) {
    const idNotification = notification?.id;
    if (!notification?.read_at) {
      dispatch(markNotificationRead({idNotification, loaderDispatch, intl}))
        .unwrap()
        .then(response => {
          fetchUserNotifications();
        })
        .catch(error => {
          console.error('Notification not marked as read', error);
          return;
        });
    }
    redirectHandler(notification);
  }

  function getTodaysNotifications() {
    if (userNotifications?.data && !isPagination) {
      const sortedNotifications = sortNotificationsByDate(
        userNotifications?.data,
      );
      const todaysNotifications = sortedNotifications?.filter(notification =>
        isToday(notification.created_at),
      );
      return todaysNotifications;
    }
  }

  function getPastNotifications() {
    if (userNotifications?.data && !isPagination) {
      const sortedNotifications = sortNotificationsByDate(
        userNotifications?.data,
      );
      const pastNotifications = sortedNotifications?.filter(
        notification => !isToday(notification.created_at),
      );
      return pastNotifications;
    }
  }

  function goToPage(page) {
    if (page > 0 && page <= userNotifications?.last_page) {
      setCurrentPage(page);
    }
  }

  return (
    <div>
      {isPagination ? (
        <>
          <div className="dashboardNotifications-wrapper">
            {userNotifications?.data?.map((notify, index) => (
              <NotificationCardView
                key={notify?.id}
                NotifyClassName={NotifyClassName}
                title={notify?.data?.message}
                desc={desc ? getNotificationDesc(notify) : null}
                time={timeAgo(notify?.created_at)}
                onItemClick={() => onNotificationClick(notify)}
                btnText={btnText}
                notifyIcon={notifyIcon}
                showGoToBtn={showGoToBtn}
              />
            ))}
          </div>
          {isPagination && userNotifications?.last_page && (
            <div className="pagination-wrapper">
              <div>
                <span>{`Page ${currentPage} of ${userNotifications?.last_page}`}</span>
              </div>
              <div className="pagination-btn">
                <button
                  disabled={currentPage === 1}
                  onClick={() => goToPage(currentPage - 1)}>
                  Previous
                </button>

                <button
                  disabled={currentPage === userNotifications?.last_page}
                  onClick={() => goToPage(currentPage + 1)}>
                  Next
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {getTodaysNotifications()?.length > 0 && (
            <>
              <div className="notifyTime-info">
                {intl.formatMessage({id: 'label.today'})}
              </div>
              <div className="dashboardNotifications-wrapper">
                {getTodaysNotifications()?.map((notify, index) => (
                  <NotificationCardView
                    key={notify?.id}
                    NotifyClassName={NotifyClassName}
                    title={notify?.data?.message}
                    desc={desc ? getNotificationDesc(notify) : null}
                    time={timeAgo(notify?.created_at)}
                    onItemClick={() => onNotificationClick(notify)}
                    btnText={btnText}
                    notifyIcon={notifyIcon}
                    showGoToBtn={showGoToBtn}
                  />
                ))}
              </div>
            </>
          )}
          {getPastNotifications()?.length > 0 && (
            <>
              <div className="notifyTime-info">
                {intl.formatMessage({id: 'label.earlier'})}
              </div>
              <div className="dashboardNotifications-wrapper">
                {getPastNotifications()?.map((notify, index) => (
                  <NotificationCardView
                    key={notify?.id}
                    NotifyClassName={NotifyClassName}
                    title={notify?.data?.message}
                    desc={desc ? getNotificationDesc(notify) : null}
                    time={timeAgo(notify?.created_at)}
                    onItemClick={() => onNotificationClick(notify)}
                    btnText={btnText}
                    notifyIcon={notifyIcon}
                    showGoToBtn={showGoToBtn}
                  />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
