import React from 'react';
import {useDropzone} from 'react-dropzone';
import {useIntl} from 'react-intl';
import CustomButton from '../../components/CustomButton/CustomButton';
import {Dialog} from '@mui/material';
import {commonStyle} from '../../Style/commonStyle';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {showToast} from '../Toast/Toast';
import './ImportBulkModal.scss';

export default function ImportBulkModal({
  open,
  handleClose,
  modalTitle,
  onDownloadTemplate,
  onImportFile,
}) {
  const intl = useIntl();
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xlsx',
      ],
    },
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections?.length > 0) {
        showToast(
          getLocalizedMessage(intl, 'error.unsupportedFileType'),
          'error',
        );
        return;
      }

      const file = acceptedFiles[0];
      if (file) {
        onImportFile(file);
      }
    },
    multiple: false,
  });

  return (
    <Dialog onClose={handleClose} open={open} sx={commonStyle.dialogueStyle}>
      <div className="bulk-modal-header">
        <div className="bulkTitleStyle">{modalTitle}</div>
        <img
          src="/images/close.svg"
          className="close-icon"
          alt="close"
          onClick={handleClose}
        />
      </div>
      <div className="bulk-modal-content">
        <div className="step-wrapper">
          <div className="upload-step-label">
            <span>{getLocalizedMessage(intl, 'step.downloadTemplate')}</span>
          </div>
          <CustomButton
            handleClick={onDownloadTemplate}
            variant="blue"
            labelText={'Download'}
            align="center"
          />
        </div>

        <div className="step-wrapper">
          <div className="upload-step-label">
            <span>{getLocalizedMessage(intl, 'step.uploadTemplate')}</span>
          </div>
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <div>{getLocalizedMessage(intl, 'label.dropFile.text')}</div>
            ) : (
              <div>{getLocalizedMessage(intl, 'label.dragFile.text')}</div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
}
