import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {Button} from '@unthinkable/react-button';
import {View, Text, TextInput} from '@unthinkable/react-core-components';
import {useDispatch} from 'react-redux';
import {forgotPassword} from '../../store/actions/authActions';
import styles from './ForgotPassword.style';
import {useTheme} from '@unthinkable/react-theme';
import {useNavigate} from '../../routes';
import {Link} from 'react-router-dom';
import {IoMdArrowBack} from 'react-icons/io';
import {isEmailValid} from '../../core/validation';

function ForgotPassword(props) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const icons = useTheme('icons');
  const {logo} = icons;

  const onforgotPassword = async () => {
    setIsProcessing(true);
    try {
      await dispatch(forgotPassword(email));
      setIsProcessing(false);
      setEmailSent(true);
    } catch (error) {
      setIsProcessing(false);
      setError('Failed to forgot password. Please try again.');
    }
  };

  const isforgotPasswordActionDisabled =
    !email || !isEmailValid(email) || isProcessing || emailSent;
  const resetState = () => {
    setEmail('');
    setIsProcessing(false);
    setError('');
    setEmailSent(false);
  };

  return (
    <View className="forgot-password-wrapper login-wrapper">
      <View className="forgot-password-container login-container">
        {emailSent && (
          <div className="back-btn" onClick={resetState}>
            {' '}
            <IoMdArrowBack />{' '}
          </div>
        )}

        <img style={styles.img} src={logo} alt="Gradrock Logo"/>
        <Text style={styles.header}>
          <h3 style={styles.title}>
            {intl.formatMessage({id: 'label.forgot-password'})}
          </h3>
          {emailSent ? (
            <p className="subtitle">
              {intl.formatMessage({id: 'label.forgot-password-email-message-one'})}
               ({email}). {intl.formatMessage({id: 'label.forgot-password-email-message-two'})}.
            </p>
          ) : (
            <p className="subtitle">
              {intl.formatMessage({id: 'label.forgot-password-subtitle'})}
            </p>
          )}
        </Text>
        <form onSubmit={onforgotPassword}>
          {!emailSent && (
            <>
              <div className="mb-3">
                <TextInput
                  placeholder={intl.formatMessage({id: 'label.email'})}
                  onChangeText={setEmail}
                  value={email}
                  className={
                    error ? 'input-error form-control' : 'form-control'
                  }
                />
              </div>

              <div className="error">
                {error && <Text style={styles.error}>{error}</Text>}
                {!isEmailValid(email) && (
                  <Text style={styles.error}>Invalid email format</Text>
                )}
              </div>
              <Button
                text={intl.formatMessage({id: 'label.forgot-password-button'})}
                onPress={onforgotPassword}
                disabled={isforgotPasswordActionDisabled}
                disabledContainerStyle={{opacity: 0.5}}
                containerStyle={styles.button}
                className="button-text"
              />

              <Link to="/login" className="center block">
                {intl.formatMessage({id: 'label.back-to-login'})}
              </Link>
            </>
          )}
          {emailSent && (
            <Button
              text="Resend Email"
              onPress={onforgotPassword}
              containerStyle={styles.lightButton}
              className="button-text-set"
            />
          )}
        </form>
      </View>
    </View>
  );
}

export default ForgotPassword;
