import React from 'react';
import {useIntl} from 'react-intl';
import {View, Text} from '@unthinkable/react-core-components';
import SchoolAdminAndStaffDashboardView from '../SchoolAdminAndStaffDashboardView/SchoolAdminAndStaffDashboardView';
import SuperAdminDashboardView from '../SuperAdminDashboardView/SuperAdminDashboardView';
import useUserRole from '../../hooks/useUserRole';
import {
  checkIsSuperAdmin,
  isStaffOrSchoolAdminRole,
} from '../../utilityFunction/helper';
import styles from './dashboard.style';

function DashboardView() {
  const intl = useIntl();
  const userRole = useUserRole();
  const isUserStaffOrSchoolAdminRole = isStaffOrSchoolAdminRole(userRole);
  const isSuperAdminRole = checkIsSuperAdmin(userRole);

  return (
    <>
      {isUserStaffOrSchoolAdminRole ? (
        <SchoolAdminAndStaffDashboardView />
      ) : isSuperAdminRole ? (
        <SuperAdminDashboardView />
      ) : (
        <View style={styles.container}>
          <Text style={styles.header}>
            {intl.formatMessage({id: 'label.dashboard'})}
          </Text>
        </View>
      )}
    </>
  );
}

export default DashboardView;
