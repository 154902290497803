import React from 'react';
import {useIntl} from 'react-intl';
import CustomTabs from '../../../components/CustomTabs/CustomTabs';
import StudentQuestionAnswerView from './StudentQuestionAnswerView/StudentQuestionAnswerView';
import './StudentResourcesTabs.scss';
import StudentNotesView from './StudentNotesView/StudentNotesView';
import StudentAttachmentsView from './StudentAttachmentsView/StudentAttachmentsView';

export default function StudentResourcesTabs({currentLesson}) {
  const intl = useIntl();

  const tabLabels = [
    intl.formatMessage({id: 'label.tabs.QuesAns'}),
    intl.formatMessage({id: 'label.tabs.notes'}),
    intl.formatMessage({id: 'label.tabs.attachments'}),
    // intl.formatMessage({id: 'label.tabs.notify'}),
    // intl.formatMessage({id: 'label.tabs.learnTools'}),
  ];

  const tabComponents = [
    {
      component: StudentQuestionAnswerView,
      props: {currentLesson: currentLesson},
    },
    {
      component: StudentNotesView,
      props: {currentLesson},
    },
    {
      component: StudentAttachmentsView,
      props: {currentLesson},
    },
  ];

  return (
    <div className="studentResourcesTabs-wrapper">
      <CustomTabs tabLabels={tabLabels} tabComponents={tabComponents} />
    </div>
  );
}
