import {createAsyncThunk} from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import {setLoading} from '../../globalContext/globalLoader/globalLoaderAction';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {showToast} from '../../components/Toast/Toast';

export const handleUploadFile = createAsyncThunk(
  'upload/file',
  async ({file, intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const formData = new FormData();
      formData.append('file', file);
      const response = await axiosPrivateInstance.post('/media', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      loaderDispatch(setLoading(false));
      return response?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const downloadFileAttachments = createAsyncThunk(
  'download/attachmentFile',
  async ({idMedia, intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.get(
        `/download-media?id=${idMedia}`,
        {responseType: 'blob'},
      );
      const blob = new Blob([response.data], {
        type: response.headers['content-type'] || 'application/octet-stream',
      });

      // Create an object URL for the Blob
      const downloadUrl = window.URL.createObjectURL(blob);
      const fileName = `attachment-${idMedia}`;
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(downloadUrl);
      link.remove();
      loaderDispatch(setLoading(false));
      showToast(
        getLocalizedMessage(intl, 'success.download.fileAttachment'),
        'success',
      );
      return;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const handleStoreAttachment = createAsyncThunk(
  'attachment/store',
  async ({lesson_id, attachment, intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const formData = new FormData();

      formData.append('lesson_id', lesson_id);
      formData.append('file', attachment);

      const response = await axiosPrivateInstance.post(
        '/attachment/store',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      );

      showToast(
        getLocalizedMessage(intl, 'success.download.fileAttachment'),
        'success',
      );

      loaderDispatch(setLoading(false));
      return response?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));

      const errorMessage =
        error.response?.data?.message ||
        getLocalizedMessage(intl, 'error.somethingWentWrong');

      showToast(errorMessage, 'error');
      throw thunkAPI.rejectWithValue(errorMessage);
    }
  },
);

export const getAttachmentById = createAsyncThunk(
  'attachment/getById',
  async ({lessonId, intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.get(
        `/attachments/${lessonId}`,
      );
      loaderDispatch(setLoading(false));
      return response?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);

export const getAllAttachments = createAsyncThunk(
  'attachments/getAll',
  async ({intl, loaderDispatch}, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.get(`/student-attachments`);
      loaderDispatch(setLoading(false));
      return response?.data?.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'error.somethingWentWrong'), 'error');
      throw error.response.data.message;
    }
  },
);
