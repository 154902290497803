import * as Yup from 'yup';
export const  resetPasswordFieldName = {
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirm_password',
};
export const resetPasswordInitialValues = {
  [resetPasswordFieldName.PASSWORD]: '',
  [resetPasswordFieldName.CONFIRM_PASSWORD]: '',
};
export const validationSchema = intl => {
  return Yup.object().shape({
    [resetPasswordFieldName.PASSWORD]: Yup.string().required(
      intl.formatMessage({id: 'label.password_required'}),
    ),
    [resetPasswordFieldName.CONFIRM_PASSWORD]: Yup.string()
      .required(intl.formatMessage({id: 'label.confirm_password_required'}))
      .oneOf(
        [Yup.ref(resetPasswordFieldName.PASSWORD)],
        intl.formatMessage({id: 'label.password_not_matched'}),
      ),
  });
};
