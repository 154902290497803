import { createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivateInstance from '../../api/axiosPrivateInstance';
import { showToast } from '../../components/Toast/Toast';
import { setLoading } from '../../globalContext/globalLoader/globalLoaderAction';
import {setLocalStorageItem} from '../../services/local-storage';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {resetState} from '../../store/reducers/authReducers';
import { LOGIN_PATH } from '../../constants/routePaths';

export const fetchUserProfile = createAsyncThunk(
  'userData/fetchUserProfile',
  async ({ id, loaderDispatch }, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.get(`/users/${id}`);
      loaderDispatch(setLoading(false));
      const userProfileData = response?.data?.data;
      const myProfile = {
        ...response.data?.data,
        myImage: userProfileData?.media?.path
      }
      setLocalStorageItem('myProfile', myProfile);
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(error.message, 'error');
      throw error;
    }
  },
);

export const updateUserProfile = createAsyncThunk(
  'userData/updateUserProfile',
  async ({ id, userData, loaderDispatch, intl }, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.patch(`/users/${id}`, userData);
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'label.profile_updated'), 'success');
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(error.message, 'error');
      throw error;
    }
  },
);

export const changePassword = createAsyncThunk(
  'userData/changePassword',
  async ({ password, loaderDispatch, intl, dispatch,navigate }, thunkAPI) => {
    loaderDispatch(setLoading(true));
    try {
      const response = await axiosPrivateInstance.post('/change-password', { password });
      loaderDispatch(setLoading(false));
      showToast(getLocalizedMessage(intl, 'label.password_changed_success'), 'success');
      dispatch(resetState());
      navigate(LOGIN_PATH);
      return response.data;
    } catch (error) {
      loaderDispatch(setLoading(false));
      showToast(error.message, 'error');
      throw error;
    }
  },
);
