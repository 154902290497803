import React, {useState, useEffect, useContext} from 'react';
import {useSelector} from 'react-redux';
import SelectDropdown from '../../components/SelectDropdown/SelectDropdown';
import InputLabel from '../../components/InputLabel/InputLabel.js';
import {useIntl} from 'react-intl';
import CustomButton from '../../components/CustomButton/CustomButton.js';
import {fetchDesignationsData} from '../../services/auth-service';
import {
  fetchUserProfile,
  updateUserProfile,
} from '../../store/actions/userProfileActions';
import {GlobalLoaderContext} from '../../globalContext/globalLoader/globalLoaderProvider';
import {useDispatch} from 'react-redux';
import {useFormik} from 'formik';
import {useNavigate} from 'react-router-dom';
import {
  addUserProfileFormFieldNames,
  addUserProfileInitialValues,
  validationSchema,
} from '../../validationSchema/userProfileSchema.js';
import UploadFileImage from '../../components/UploadFileImage/UploadFileImage.js';
import useUserRole from '../../hooks/useUserRole';
import UserRoles from '../../constants/userRoles';
import './UserProfile.scss';
import SetPassword from '../SetPassword/SetPassword';

function UserProfile({id}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const [fileId, setFileId] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [imageName, setImageName] = useState('');
  const [designations, setDesignations] = useState([]);
  const userRole = useUserRole();
  const userId = useSelector(state => state?.auth?.data?.data?.data?.user?.id);
  const [openSetPasswordModal, setOpenSetPasswordModal] = useState(false);

  useEffect(() => {
    fetchDesignationsData(dispatch, setDesignations);
  }, []);

  const {
    values,
    handleChange,
    errors,
    touched,
    handleSubmit,
    setFieldValue,
    handleBlur,
    setValues,
  } = useFormik({
    initialValues: addUserProfileInitialValues,
    onSubmit: async values => {
      const payload = {
        [addUserProfileFormFieldNames.FIRST_NAME]: values.first_name,
        [addUserProfileFormFieldNames.LAST_NAME]: values.last_name,
        [addUserProfileFormFieldNames.EMAIL]: values.email,
        [addUserProfileFormFieldNames.DESIGNATION]: parseInt(
          values.designation_id,
        ),
        [addUserProfileFormFieldNames.MEDIA_ID]: fileId,
        [addUserProfileFormFieldNames.PHONE]: values.phone_no,
        [addUserProfileFormFieldNames.ALTERNATE_PHONE]:
          values?.alternate_phone_no,
      };

      if (userId) {
        await dispatch(
          updateUserProfile({id: userId, userData: payload, loaderDispatch, intl}),
        ).then(() => {
          fetchData();
        });
      }
    },
    validationSchema: validationSchema(intl),
  });

  const fetchData = async () => {
    if (userId) {
      try {
        const response = await dispatch(
          fetchUserProfile({id: userId, loaderDispatch}),
        );
        const userProfileData = response.payload.data;
        setPreviewUrl(userProfileData?.media?.path);
        setImageName(userProfileData?.media?.name);
        setFileId(userProfileData.media?.id);
        setValues(prev => ({
          ...prev,

          [addUserProfileFormFieldNames.FIRST_NAME]: userProfileData.first_name,
          [addUserProfileFormFieldNames.LAST_NAME]: userProfileData.last_name,
          [addUserProfileFormFieldNames.EMAIL]: userProfileData.email,
          [addUserProfileFormFieldNames.DESIGNATION]: parseInt(
            userProfileData?.designation?.id,
          ),
          [addUserProfileFormFieldNames.PHONE]: userProfileData?.phone_no,
          [addUserProfileFormFieldNames.ALTERNATE_PHONE]:
            userProfileData?.alternate_phone_no || '',
        }));
      } catch (error) {
        console.log('Error fetching student data:', error);
      }
    }
  };

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const handleCancel = () => {
    navigate(-1);
  };

  const onDesignationChange = e => {
    const selectedDesignationId = e.target.value;
    setFieldValue(
      addUserProfileFormFieldNames.DESIGNATION,
      selectedDesignationId,
    );
  };

  const handleChangePassword = () => {
    setOpenSetPasswordModal(true);
  };

  return (
    <div className="container add-school-wrapper admin-form-wrapper">
      <div className="container">
        <div className="add-student-wrapper">
          <div className='text-right'>
        <button className="btn btn-primary w-30" onClick={handleChangePassword}>Change password</button>
        </div>
          <div className="row">
            
            {userRole !== UserRoles.SUPER_ADMIN &&
              userRole !== UserRoles.SCHOOL_ADMIN && (
                <div className="mb-3 col-md-6">
                  <div className="user-profile-image">
                    <UploadFileImage
                      setFileId={setFileId}
                      previewUrl={previewUrl}
                      setPreviewUrl={setPreviewUrl}
                      acceptType={'image/*'}
                      hideImageTag
                      imageName={imageName}
                      setImageName={setImageName}
                      userProfile={true}
                      userProfileClass={'user-profile-wrapper'}
                      profileTitle="Profile Image (Maximum Image size is 2 MB)"
                    />
                  </div>
                </div>
              )}
            <div className="mb-3 col-md-6">
              <InputLabel
                name={addUserProfileFormFieldNames.FIRST_NAME}
                label={intl.formatMessage({id: 'label.first_name'})}
                placeholder={intl.formatMessage({id: 'label.enter_first_name'})}
                value={values[addUserProfileFormFieldNames.FIRST_NAME]}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched[addUserProfileFormFieldNames.FIRST_NAME] &&
                  errors[addUserProfileFormFieldNames.FIRST_NAME]
                }
              />
            </div>
            <div className="mb-3 col-md-6">
              <InputLabel
                name={addUserProfileFormFieldNames.LAST_NAME}
                label={intl.formatMessage({id: 'label.last_name'})}
                placeholder={intl.formatMessage({id: 'label.enter_last_name'})}
                value={values[addUserProfileFormFieldNames.LAST_NAME]}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched[addUserProfileFormFieldNames.LAST_NAME] &&
                  errors[addUserProfileFormFieldNames.LAST_NAME]
                }
              />
            </div>

            <div className="mb-3 col-md-6">
              <InputLabel
                name={addUserProfileFormFieldNames.EMAIL}
                label={intl.formatMessage({id: 'label.email_address'})}
                placeholder={intl.formatMessage({
                  id: 'label.enter_email_address',
                })}
                value={values[addUserProfileFormFieldNames.EMAIL]}
                required
                // onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched[addUserProfileFormFieldNames.EMAIL] &&
                  errors[addUserProfileFormFieldNames.EMAIL]
                }
                disabled={userId}
              />
            </div>

            {userRole === UserRoles.SUPER_ADMIN ||
            userRole === UserRoles.STUDENT ? null : (
              <div className="mb-3 col-md-6">
                <SelectDropdown
                  options={designations}
                  label={intl.formatMessage({id: 'label.designation'})}
                  placeholder={intl.formatMessage({
                    id: 'label.select_designation',
                  })}
                  required
                  value={values[addUserProfileFormFieldNames.DESIGNATION]}
                  onChange={e => onDesignationChange(e)}
                  onBlur={handleBlur}
                  error={
                    touched[addUserProfileFormFieldNames.DESIGNATION] &&
                    errors[addUserProfileFormFieldNames.DESIGNATION]
                  }
                />
              </div>
            )}

            {userRole !== UserRoles.STUDENT && (
              <div className="mb-3 col-md-6">
                <InputLabel
                  name={addUserProfileFormFieldNames.PHONE}
                  label={intl.formatMessage({id: 'label.contact_number'})}
                  placeholder={intl.formatMessage({
                    id: 'label.enter_contact_number',
                  })}
                  value={values[addUserProfileFormFieldNames.PHONE]}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched[addUserProfileFormFieldNames.PHONE] &&
                    errors[addUserProfileFormFieldNames.PHONE]
                  }
                />
              </div>
            )}
            {userRole !== UserRoles.STUDENT && (
              <div className="mb-3 col-md-6">
                <InputLabel
                  name={addUserProfileFormFieldNames.ALTERNATE_PHONE}
                  label={intl.formatMessage({
                    id: 'label.alternate_contact_number',
                  })}
                  required={false}
                  placeholder={intl.formatMessage({
                    id: 'label.enter_contact_number',
                  })}
                  value={values[addUserProfileFormFieldNames.ALTERNATE_PHONE]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched[addUserProfileFormFieldNames.ALTERNATE_PHONE] &&
                    errors[addUserProfileFormFieldNames.ALTERNATE_PHONE]
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <SetPassword
        open={openSetPasswordModal}
        onClose={() => setOpenSetPasswordModal(false)}
      />
      <div className="schoolFormBtn">
        <CustomButton
          labelText={intl.formatMessage({id: 'label.cancel'})}
          handleClick={handleCancel}
          variant="white"
          align="right"
        />
        <CustomButton
          labelText={intl.formatMessage({id: 'label.save_changes'})}
          handleClick={handleSubmit}
          variant="blue"
          align="right"
          type="submit"
        />
      </div>
    </div>
  );
}

export default UserProfile;
