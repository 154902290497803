import React from 'react';
import './NotificationCardView.scss';
import ThreeDButton from '../ThreeDButton/ThreeDButton';

export default function NotificationCardView({
  title,
  desc = true,
  time,
  onItemClick,
  NotifyClassName,
  btnText,
  notifyIcon,
  showGoToBtn,
}) {
  return (
    <div
      className={`notification-item ${NotifyClassName}`}
      onClick={onItemClick}>
      <div className="img-message">
        <img src={notifyIcon} alt="notify read icon" />
        <div className="notify-item-wrapper">
          <div className="notify-popup-title">{title}</div>
          {desc && <div className="notification-desc">{desc}</div>}
          {!desc && <div className="notification-time">{time}</div>}
        </div>
      </div>
      {desc && <div className="notification-time">{time}</div>}
      {!desc && showGoToBtn && (
        <ThreeDButton labelText={btnText} className="border-0" />
      )}
    </div>
  );
}
