import React from 'react';
import {v4 as uuidv4} from 'uuid';
import UserRoles from '../constants/userRoles';
import moment from 'moment';
import {LESSON_CONTENT_OBJECT_TYPE} from './constants';

export function getLocalizedMessage(intl, id, params) {
  return intl.formatMessage({id}, params);
}

export function getCounsellors(counsellorsList) {
  return counsellorsList?.map(counsellor => ({
    name: `${counsellor?.first_name} ${counsellor?.last_name || ''}`,
    id: counsellor?.id,
  }));
}

export function getTeachers(teacherList) {
  return teacherList?.map(teacher => ({
    name: `${teacher?.first_name} ${teacher?.last_name || ''}`,
    id: teacher?.id,
  }));
}

export function concatenateStrings(stringsArray) {
  return stringsArray?.join(', ');
}

export function getStaffTabsOptions(intl) {
  return [
    {
      id: '',
      label: getLocalizedMessage(intl, 'label.viewAll'),
      value: 'View All',
    },
    {
      id: 'counsellor',
      label: getLocalizedMessage(intl, 'label.counsellors'),
      value: 'counsellor',
    },
    {
      id: 'teacher',
      label: getLocalizedMessage(intl, 'label.teachers'),
      value: 'teacher',
    },
  ];
}

export function getStatusText(isActive, intl) {
  return isActive
    ? getLocalizedMessage(intl, 'label.deactivate')
    : getLocalizedMessage(intl, 'label.activate');
}

export function getIslandStatusText(isActive, intl) {
  return isActive
    ? getLocalizedMessage(intl, 'label.disable')
    : getLocalizedMessage(intl, 'label.enable');
}

export function isTeachers(role) {
  return UserRoles.SCHOOL_TEACHER === role;
}

export function isCounsellors(role) {
  return UserRoles.COUNSELLOR === role;
}

export function getLessonStylePayload(arr) {
  const payload = arr?.map(item => ({
    type: item.type,
    media_id: item.mediaId,
    background_color: item.bgColor,
    label: item.labelName,
  }));
  return payload;
}

export function chunkItems(items, itemsPerRow) {
  const rows = [];
  for (let i = 0; i < items.length; i += itemsPerRow) {
    rows.push(items.slice(i, i + itemsPerRow));
  }
  return rows;
}

export function getLessonStatus(status, intl) {
  return status === 'draft'
    ? getLocalizedMessage(intl, 'label.draft')
    : getLocalizedMessage(intl, 'label.published');
}

export function getBorderColorByBgColor(colorItems, bgColor) {
  const item = colorItems.find(item => item.bgColor === bgColor);
  return item ? item.borderColor : null;
}

export function getLessonContentPayload(arr) {
  return arr?.map((item, index) => {
    const {contentId, ...rest} = item;
    const content_priority = index + 1;
    if (item?.type === LESSON_CONTENT_OBJECT_TYPE.ARTICLE) {
      const {article_title, article_link, type, media_id} = rest;
      return {article_title, article_link, type, media_id, content_priority};
    }
    if (item?.type === LESSON_CONTENT_OBJECT_TYPE.DOCUMENT) {
      const {type, media_id} = item;
      return {type, media_id, content_priority};
    }
    if (item?.type === LESSON_CONTENT_OBJECT_TYPE.IMAGE_VIDEO) {
      const {media_id, type_file} = item;
      return {type: type_file, media_id, content_priority};
    }
    if (
      item?.type === LESSON_CONTENT_OBJECT_TYPE.TEXT &&
      item?.editorDescription
    ) {
      return {
        type: item.type,
        description: item?.editorDescription,
        content_priority,
      };
    }
    return {...rest, content_priority};
  });
}

export function prefillLessonContentData(formData) {
  return formData
    ?.map(item => {
      if (
        [
          LESSON_CONTENT_OBJECT_TYPE.ALERT,
          LESSON_CONTENT_OBJECT_TYPE.NOTE,
          LESSON_CONTENT_OBJECT_TYPE.REMEMBER,
          LESSON_CONTENT_OBJECT_TYPE.BEST_ADVICE,
        ].includes(item.type)
      ) {
        return {
          contentId: uuidv4(),
          type: item.type,
          description: item.description,
        };
      } else if (item.type === LESSON_CONTENT_OBJECT_TYPE.ARTICLE) {
        return {
          contentId: uuidv4(),
          type: item.type,
          media_id: item.media?.id,
          article_preview_url: item.media?.path,
          article_title: item.article_title,
          article_link: item.article_link,
        };
      } else if (item.type === LESSON_CONTENT_OBJECT_TYPE.DOCUMENT) {
        return {
          contentId: uuidv4(),
          type: item.type,
          media_id: item.media?.id,
          file_preview_name: item.media?.path,
          file_preview_url: item.media?.path,
        };
      } else if (item.type === 'image' || item.type === 'video') {
        return {
          contentId: uuidv4(),
          type: 'image/video',
          media_id: item.media?.id,
          type_file: item.type,
          mediaInfo: item.media,
          file_preview_url: item?.media?.path,
        };
      } else if (item.type === LESSON_CONTENT_OBJECT_TYPE.TEXT) {
        return {
          contentId: uuidv4(),
          type: item.type,
          editorDescription: item?.description,
        };
      } else {
        return null;
      }
    })
    .filter(Boolean);
}

export function getEditorDescription() {
  return JSON.stringify({
    blocks: [
      {
        key: 'initial',
        text: '',
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: [],
        data: {},
      },
    ],
    entityMap: {},
  });
}

export function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  const mm = minutes.toString().padStart(2, '0');
  const ss = remainingSeconds.toString().padStart(2, '0');

  if (hours > 0) {
    const hh = hours.toString().padStart(2, '0');
    return `${hh}:${mm}:${ss}`;
  } else {
    return `${mm}:${ss}`;
  }
}

export function formatDate(dateString) {
  return moment(dateString).format('DD/MM/YYYY');
}

export function isAttachmentAnImage(urlString) {
  let parts = urlString?.split('.');
  let fileExtension = parts[parts?.length - 1]?.toLowerCase();
  const imageFileTypes = ['jpg', 'jpeg', 'png'];
  if (imageFileTypes.includes(fileExtension)) return true;
  else return false;
}

export function getDocumentIcon(fileName) {
  const parts = fileName?.split('.');
  const extension = parts?.length > 1 ? parts[parts?.length - 1] : undefined;
  switch (extension) {
    case 'pdf':
      return '/images/pdfIcon.svg';
    case 'xlsx':
      return '/images/xlsIcon.svg';
    case 'ppt':
      return '/images/pptIcon.svg';
    case 'jpg':
    case 'jpeg':
      return '/images/jpgIcon.svg';
    case 'docx':
      return '/images/docsIcon.svg';
    case 'png':
      return '/images/pngIcon.svg';
    default:
      break;
  }
}

export function getAttachmentFileName(attachFileName) {
  if (typeof attachFileName === 'string' && attachFileName?.trim().length > 0) {
    const parts = attachFileName?.split('_');
    parts.shift();
    const fileNameAfterFirstUnderscore = parts?.join('_');
    return fileNameAfterFirstUnderscore;
  }
}

export function getAttachmentFileNameWithoutFileExtension(attachFileName) {
  if (typeof attachFileName === 'string' && attachFileName?.trim().length > 0) {
    const fileNameAfterFirstUnderscore = getAttachmentFileName(attachFileName);
    const fileNameParts = fileNameAfterFirstUnderscore.split('.');
    fileNameParts?.pop();
    return fileNameParts?.join('.');
  }
}

export function isAttachmentAnPdf(urlString) {
  let parts = urlString?.split('.');
  let fileExtension = parts[parts?.length - 1]?.toLowerCase();
  const pdfFileType = ['pdf'];
  if (pdfFileType.includes(fileExtension)) return true;
  else return false;
}

export function getMimeTypeFromFileExtension(urlString) {
  const parts = urlString?.split('.');
  const fileExtension = parts[parts.length - 1]?.toLowerCase();
  switch (fileExtension) {
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'xls':
      return 'application/vnd.ms-excel';
    case 'docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case 'doc':
      return 'application/msword';
    case 'ppt':
      return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    case 'pdf':
      return 'application/pdf';
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
  }
}

export function getPreviewFormData(formValues) {
  return formValues?.map(item => {
    if (
      [
        LESSON_CONTENT_OBJECT_TYPE.ALERT,
        LESSON_CONTENT_OBJECT_TYPE.NOTE,
        LESSON_CONTENT_OBJECT_TYPE.REMEMBER,
        LESSON_CONTENT_OBJECT_TYPE.BEST_ADVICE,
      ].includes(item.type)
    ) {
      return item;
    } else if (
      item.type === LESSON_CONTENT_OBJECT_TYPE.TEXT &&
      item.editorDescription
    ) {
      return {
        ...item,
        description: item.editorDescription,
      };
    } else if (item.type === LESSON_CONTENT_OBJECT_TYPE.ARTICLE) {
      return {
        ...item,
        media: {
          path: item?.article_preview_url,
        },
      };
    } else if (item.type === 'image/video') {
      return {
        ...item,
        type: item?.type_file,
        media: {
          path: item?.file_preview_url,
          id: item?.media_id,
        },
      };
    } else if (item.type === LESSON_CONTENT_OBJECT_TYPE.DOCUMENT) {
      return {
        ...item,
        media: {
          name: item?.file_preview_name,
          path: item?.file_preview_url,
          id: item?.media_id,
        },
      };
    }
    return item;
  });
}

export function getPreviewDocFileName(url) {
  const parts = url.split('media/');
  if (parts.length > 1) {
    const mediaPart = parts[1];
    const underscoreParts = mediaPart.split('_');
    if (underscoreParts.length > 1) {
      return underscoreParts.slice(1).join('_');
    } else {
      return '';
    }
  } else {
    return '';
  }
}

export function checkIsSuperAdmin(userRole) {
  if (userRole === UserRoles.SUPER_ADMIN) {
    return true;
  } else return false;
}

export function getTimeAgo(dateString) {
  const givenDate = new Date(dateString);
  const now = new Date();
  const timeDiff = now - givenDate;

  // Calculate difference in days
  const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  if (daysDiff === 0) {
    return 'Today';
  }
  if (daysDiff <= 30) {
    return `${daysDiff} day${daysDiff !== 1 ? 's' : ''} ago`;
  }

  // Calculate difference in months
  let monthsDiff =
    now.getMonth() -
    givenDate.getMonth() +
    12 * (now.getFullYear() - givenDate.getFullYear());
  if (givenDate.getDate() > now.getDate()) {
    monthsDiff--;
  }
  if (monthsDiff < 12) {
    return `${monthsDiff} month${monthsDiff !== 1 ? 's' : ''} ago`;
  }

  // Calculate difference in years
  let yearsDiff = now.getFullYear() - givenDate.getFullYear();
  if (
    now.getMonth() < givenDate.getMonth() ||
    (now.getMonth() === givenDate.getMonth() &&
      now.getDate() < givenDate.getDate())
  ) {
    yearsDiff--;
  }
  return `${yearsDiff} year${yearsDiff !== 1 ? 's' : ''} ago`;
}

export function getQuestionsPinned(questionData) {
  return questionData?.filter(item => item?.is_pinned);
}

export function getQuestionsNotPinned(questionData) {
  return questionData?.filter(item => !item?.is_pinned);
}

export function editContentButtonsAvailable(userRole) {
  const editActionsRoles = [UserRoles.SUPER_ADMIN];
  if (editActionsRoles?.includes(userRole)) return true;
  else return false;
}

export function getStaffLoginId(loginData, userRole) {
  if (loginData) {
    if (userRole === UserRoles.SCHOOL_TEACHER) {
      const teacherData = loginData?.data?.data?.data?.user?.teacher_data;
      return teacherData?.id;
    } else if (userRole === UserRoles.COUNSELLOR) {
      const counsellorData = loginData?.data?.data?.data?.user?.counsellor_data;
      return counsellorData?.id;
    } else {
      return null;
    }
  } else {
    console.error('Login user data not found');
    return null;
  }
}

export function checkSchoolAdmin(userRole) {
  if (userRole === UserRoles.SCHOOL_ADMIN) return true;
  else return false;
}

export function isToday(dateString) {
  const today = new Date();
  const dateToCheck = new Date(dateString);
  return (
    dateToCheck.getDate() === today.getDate() &&
    dateToCheck.getMonth() === today.getMonth() &&
    dateToCheck.getFullYear() === today.getFullYear()
  );
}

export function sortNotificationsByDate(notificationsArray) {
  return [...notificationsArray]?.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at),
  );
}

export function timeAgo(dateString) {
  const date = new Date(dateString + 'Z'); // The 'Z' indicates UTC time zone

  // Get the current date in UTC
  const now = new Date(new Date().toISOString());

  const seconds = Math.floor((now - date) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  function monthDiff(d1, d2) {
    let months = (d2.getUTCFullYear() - d1.getUTCFullYear()) * 12;
    months -= d1.getUTCMonth();
    months += d2.getUTCMonth();
    return months <= 0 ? 0 : months;
  }

  function yearDiff(d1, d2) {
    return d2.getUTCFullYear() - d1.getUTCFullYear();
  }

  if (minutes < 2) {
    return 'just now';
  } else if (minutes < 60) {
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else if (hours < 24) {
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else if (days < 30) {
    return `${days} day${days > 1 ? 's' : ''} ago`;
  } else {
    const months = monthDiff(date, now);
    if (months < 12) {
      return `${months} month${months > 1 ? 's' : ''} ago`;
    } else {
      const years = yearDiff(date, now);
      return `${years} year${years > 1 ? 's' : ''} ago`;
    }
  }
}

export function getNotificationDesc(notifyObj) {
  const typeToMessage = {
    'App\\Notifications\\Staff\\StudentAddedNotification': 'New student added',
    'App\\Notifications\\Staff\\AddQuestionNotification': 'Posted a question',
    'App\\Notifications\\Staff\\AnswerAddedNotification':
      'Posted answer to the question',
    'App\\Notifications\\Staff\\AddLessonNotification': 'Added a new lesson',
    'App\\Notifications\\Staff\\SectionCompleteNotification':
      'Section completed',
    'App\\Notifications\\Staff\\StudentStatusChangedNotification':
      'Student status changed',
    'App\\Notifications\\SchoolAdmin\\SectionTokenUpdatedNotification':
      'Section token updated',
    'App\\Notifications\\SchoolAdmin\\LicenseRenewedNotification':
      'School license renewed',
    'App\\Notifications\\SuperAdmin\\SchoolLicenseExpiryNotification':
      'School license expired',
    'App\\Notifications\\SuperAdmin\\AddSchoolNotification': 'New school added',
    'App\\Notifications\\SuperAdmin\\AddDistrictNotification':
      'New school district added',
    'App\\Notifications\\SuperAdmin\\SchoolStatusChangeNotification':
      'School status updated',
  };

  return typeToMessage[notifyObj?.type] || 'Unknown notification type';
}

export function isStaffOrSchoolAdminRole(userRole) {
  const staffRoles = [
    UserRoles.SCHOOL_ADMIN,
    UserRoles.SCHOOL_TEACHER,
    UserRoles.COUNSELLOR,
  ];
  if (staffRoles.includes(userRole)) return true;
  else return false;
}
