import React from 'react';
import {useIntl} from 'react-intl';
import {Text, View} from '@unthinkable/react-core-components';

import Styles from './HeaderName.style';
import useUserRole from '../../hooks/useUserRole';
import {getLocalizedMessage} from '../../utilityFunction/helper';
import {
  DASHBOARD,
  ISLANDS_CONTENT,
  MANAGE_QUESTIONS,
  MANAGE_SCHOOLS,
  MANAGE_STAFF,
  MANAGE_STUDENTS,
  MY_PROFILE,
  SYSTEM_SETTINGS,
  ANNOUNCEMENTS,
  NOTIFY_ANNOUNCEMENTS,
  SUPER_ADMIN_MGMT,
  REPORTS,
  STAFF_STUDENT_ATTACHMENTS,
} from '../../utilityFunction/constants';
import UserRoles from '../../constants/userRoles';

const HeaderName = ({headerLabel}) => {
  const intl = useIntl();
  const userRole = useUserRole();

  const getHeaderLabel = label => {
    switch (label) {
      case MANAGE_SCHOOLS:
        return getLocalizedMessage(intl, 'label.manage-schools');
      case MANAGE_STUDENTS:
        return getLocalizedMessage(intl, 'label.manage-students');
      case MANAGE_STAFF:
        return getLocalizedMessage(intl, 'label.manage-staff');
      case SYSTEM_SETTINGS:
        return getLocalizedMessage(intl, 'label.system-settings');
      case ISLANDS_CONTENT:
        return getLocalizedMessage(intl, 'label.islandsContent');
      case MANAGE_QUESTIONS:
        return getLocalizedMessage(intl, 'label.manage-questions');
      case DASHBOARD:
        return getLocalizedMessage(intl, 'label.dashboard');
      case MY_PROFILE:
        return getLocalizedMessage(intl, 'label.myProfile');
      case ANNOUNCEMENTS:
        return getLocalizedMessage(intl, 'label.tabs.Announcements');
      case SUPER_ADMIN_MGMT:
        return getLocalizedMessage(intl, 'label.super_admin_management');
      case NOTIFY_ANNOUNCEMENTS:
        return getLocalizedMessage(
          intl,
          UserRoles.SUPER_ADMIN === userRole
            ? 'label.subHead.notify'
            : 'label.tabs.notifyAndAnnouncements',
        );
      case REPORTS:
        return getLocalizedMessage(intl, 'label.reports');
      case STAFF_STUDENT_ATTACHMENTS:
        return getLocalizedMessage(intl, 'label.view-student-attachments');
      default:
        return null;
    }
  };

  return (
    <View>
      <Text style={Styles.heading} className="header-name">
        {getHeaderLabel(headerLabel)}
      </Text>
    </View>
  );
};

export default HeaderName;
