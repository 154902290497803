import AsyncStorage from '@react-native-async-storage/async-storage';
import CryptoJS from 'crypto-js';

import { v4 as uuidv4 } from 'uuid';

const SECRET_KEY = uuidv4();

function formatKey(name) {
  return `react_app_${name}`;
}

class Storage {
  static async encryptData(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  }

  static async decryptData(encryptedData) {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  static async set(key, value) {
    if (!value) return;
    try {
      const encryptedValue = await this.encryptData(value);
      await AsyncStorage.setItem(formatKey(key), encryptedValue);
    } catch (error) {
      console.error(error);
    }
  }

  static async get(key) {
    if (!key) return;
    try {
      const encryptedItem = await AsyncStorage.getItem(formatKey(key));
      if (!encryptedItem) return;
      return await this.decryptData(encryptedItem);
    } catch (error) {
      console.error(error);
    }
  }

  static async remove(key) {
    if (!key) return;
    try {
      await AsyncStorage.removeItem(formatKey(key));
    } catch (error) {
      console.error(error);
    }
  }

  static async removeAll() {
    try {
      const keys = await AsyncStorage.getAllKeys();
      const formattedKeys = keys.filter(key => key.startsWith('react_app_'));
      await AsyncStorage.multiRemove(formattedKeys);
    } catch (error) {
      console.error(error);
    }
  }
}

export default Storage;
