import React, {useContext, useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import NotificationCardView from '../../NotificationCardView/NotificationCardView';
import {
  isToday,
  sortNotificationsByDate,
  timeAgo,
} from '../../../utilityFunction/helper';
import {GlobalLoaderContext} from '../../../globalContext/globalLoader/globalLoaderProvider';
import {getUserAnnouncements} from '../../../store/actions/notificationActions';
import './AnnouncementLayoutList.scss';

export default function AnnouncementLayoutList({
  NotifyClassName,
  notifyIcon,
  btnText,
  isPagination,
  desc,
  showGoToBtn,
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [_, loaderDispatch] = useContext(GlobalLoaderContext);
  const {userAnnouncements} = useSelector(state => state?.notifications);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchUserAnnouncements();
  }, [currentPage]);

  function fetchUserAnnouncements() {
    dispatch(
      getUserAnnouncements({
        loaderDispatch,
        isPagination: isPagination,
        currentPage: currentPage,
      }),
    );
  }

  function getTodaysAnnouncements() {
    if (userAnnouncements?.data && !isPagination) {
      const sortedAnnouncements = sortNotificationsByDate(
        userAnnouncements?.data,
      );
      const todaysAnnouncements = sortedAnnouncements?.filter(announcement =>
        isToday(announcement?.created_at),
      );
      return todaysAnnouncements;
    }
  }

  function getPastAnnouncements() {
    if (userAnnouncements?.data && !isPagination) {
      const sortedAnnouncements = sortNotificationsByDate(
        userAnnouncements?.data,
      );
      const pastAnnouncements = sortedAnnouncements?.filter(
        announcement => !isToday(announcement?.created_at),
      );
      return pastAnnouncements;
    }
  }

  function goToPage(page) {
    if (page > 0 && page <= userAnnouncements?.last_page) {
      setCurrentPage(page);
    }
  }

  return (
    <div>
      {isPagination ? (
        <>
          <div className="dashboardNotifications-wrapper announcement-width">
            {userAnnouncements?.data?.map((announcement, index) => (
              <NotificationCardView
                key={announcement?.id}
                title={announcement?.data?.description}
                time={timeAgo(announcement?.created_at)}
                btnText={btnText}
                notifyIcon={notifyIcon}
                NotifyClassName={NotifyClassName}
                desc={desc}
                showGoToBtn={showGoToBtn}
              />
            ))}
          </div>
          {isPagination && userAnnouncements?.last_page && (
            <div className="pagination-wrapper">
              <div>
                <span>{`Page ${currentPage} of ${userAnnouncements?.last_page}`}</span>
              </div>
              <div className="pagination-btn">
                <button
                  disabled={currentPage === 1}
                  onClick={() => goToPage(currentPage - 1)}>
                  Previous
                </button>

                <button
                  disabled={currentPage === userAnnouncements?.last_page}
                  onClick={() => goToPage(currentPage + 1)}>
                  Next
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {getTodaysAnnouncements()?.length > 0 && (
            <>
              <div className="notifyTime-info">
                {intl.formatMessage({id: 'label.today'})}
              </div>
              <div className="dashboardNotifications-wrapper announcement-width">
                {getTodaysAnnouncements()?.map((announcement, index) => (
                  <NotificationCardView
                    key={announcement?.id}
                    title={announcement?.data?.description}
                    time={timeAgo(announcement?.created_at)}
                    btnText={btnText}
                    desc={desc}
                    notifyIcon={notifyIcon}
                    NotifyClassName={NotifyClassName}
                    showGoToBtn={showGoToBtn}
                  />
                ))}
              </div>
            </>
          )}
          {getPastAnnouncements()?.length > 0 && (
            <>
              <div className="notifyTime-info">
                {intl.formatMessage({id: 'label.earlier'})}
              </div>
              <div className="dashboardNotifications-wrapper announcement-width">
                {getPastAnnouncements()?.map((announcement, index) => (
                  <NotificationCardView
                    key={announcement?.id}
                    title={announcement?.data?.description}
                    time={timeAgo(announcement?.created_at)}
                    btnText={btnText}
                    desc={desc}
                    NotifyClassName={NotifyClassName}
                    notifyIcon={notifyIcon}
                    showGoToBtn={showGoToBtn}
                  />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
