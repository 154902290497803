import {createSlice} from '@reduxjs/toolkit';
import {getUserAnnouncements} from '../actions/announcementActions';

const initialState = {
  userAnnouncements: [],
};

const announcementSlice = createSlice({
  name: 'announcements',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getUserAnnouncements.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUserAnnouncements.fulfilled, (state, action) => {
        state.loading = false;
        state.userAnnouncements = action.payload;
        state.error = null;
      })
      .addCase(getUserAnnouncements.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default announcementSlice.reducer;
